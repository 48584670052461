var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

!function (t) {
  var e = {};

  function n(r) {
    if (e[r]) return e[r].exports;
    var i = e[r] = {
      i: r,
      l: !1,
      exports: {}
    };
    return t[r].call(i.exports, i, i.exports, n), i.l = !0, i.exports;
  }

  n.m = t, n.c = e, n.d = function (t, e, r) {
    n.o(t, e) || Object.defineProperty(t, e, {
      configurable: !1,
      enumerable: !0,
      get: r
    });
  }, n.n = function (t) {
    var e = t && t.__esModule ? function () {
      return t.default;
    } : function () {
      return t;
    };
    return n.d(e, "a", e), e;
  }, n.o = function (t, e) {
    return Object.prototype.hasOwnProperty.call(t, e);
  }, n.p = "", n(n.s = 126);
}([function (t, e, n) {
  var r = n(2),
      i = n(21),
      o = n(12),
      s = n(13),
      u = n(18),
      a = function (t, e, n) {
    var l,
        f,
        c,
        h,
        p = t & a.F,
        d = t & a.G,
        v = t & a.S,
        y = t & a.P,
        m = t & a.B,
        P = d ? r : v ? r[e] || (r[e] = {}) : (r[e] || {}).prototype,
        g = d ? i : i[e] || (i[e] = {}),
        _ = g.prototype || (g.prototype = {});

    for (l in d && (n = e), n) c = ((f = !p && P && void 0 !== P[l]) ? P : n)[l], h = m && f ? u(c, r) : y && "function" == typeof c ? u(Function.call, c) : c, P && s(P, l, c, t & a.U), g[l] != c && o(g, l, h), y && _[l] != c && (_[l] = c);
  };

  r.core = i, a.F = 1, a.G = 2, a.S = 4, a.P = 8, a.B = 16, a.W = 32, a.U = 64, a.R = 128, t.exports = a;
}, function (t, e, n) {
  var r = n(4);

  t.exports = function (t) {
    if (!r(t)) throw TypeError(t + " is not an object!");
    return t;
  };
}, function (t, e) {
  var n = t.exports = "undefined" != typeof window && window.Math == Math ? window : "undefined" != typeof self && self.Math == Math ? self : Function("return this")();
  "number" == typeof __g && (_global.__g = n);
}, function (t, e) {
  t.exports = function (t) {
    try {
      return !!t();
    } catch (t) {
      return !0;
    }
  };
}, function (t, e) {
  t.exports = function (t) {
    return "object" == typeof t ? null !== t : "function" == typeof t;
  };
}, function (t, e, n) {
  var r = n(49)("wks"),
      i = n(33),
      o = n(2).Symbol,
      s = "function" == typeof o;
  (t.exports = function (t) {
    return r[t] || (r[t] = s && o[t] || (s ? o : i)("Symbol." + t));
  }).store = r;
}, function (t, e, n) {
  t.exports = !n(3)(function () {
    return 7 != Object.defineProperty({}, "a", {
      get: function () {
        return 7;
      }
    }).a;
  });
}, function (t, e, n) {
  var r = n(1),
      i = n(91),
      o = n(22),
      s = Object.defineProperty;
  e.f = n(6) ? Object.defineProperty : function (t, e, n) {
    if (r(t), e = o(e, !0), r(n), i) try {
      return s(t, e, n);
    } catch (t) {}
    if ("get" in n || "set" in n) throw TypeError("Accessors not supported!");
    return "value" in n && (t[e] = n.value), t;
  };
}, function (t, e, n) {
  var r = n(24),
      i = Math.min;

  t.exports = function (t) {
    return t > 0 ? i(r(t), 9007199254740991) : 0;
  };
}, function (t, e, n) {
  var r = n(23);

  t.exports = function (t) {
    return Object(r(t));
  };
}, function (t, e) {
  t.exports = function (t) {
    if ("function" != typeof t) throw TypeError(t + " is not a function!");
    return t;
  };
}, function (t, e) {
  var n = {}.hasOwnProperty;

  t.exports = function (t, e) {
    return n.call(t, e);
  };
}, function (t, e, n) {
  var r = n(7),
      i = n(32);
  t.exports = n(6) ? function (t, e, n) {
    return r.f(t, e, i(1, n));
  } : function (t, e, n) {
    return t[e] = n, t;
  };
}, function (t, e, n) {
  var r = n(2),
      i = n(12),
      o = n(11),
      s = n(33)("src"),
      u = Function.toString,
      a = ("" + u).split("toString");
  n(21).inspectSource = function (t) {
    return u.call(t);
  }, (t.exports = function (t, e, n, u) {
    var l = "function" == typeof n;
    l && (o(n, "name") || i(n, "name", e)), t[e] !== n && (l && (o(n, s) || i(n, s, t[e] ? "" + t[e] : a.join(String(e)))), t === r ? t[e] = n : u ? t[e] ? t[e] = n : i(t, e, n) : (delete t[e], i(t, e, n)));
  })(Function.prototype, "toString", function () {
    return "function" == typeof (this || _global) && (this || _global)[s] || u.call(this || _global);
  });
}, function (t, e, n) {
  var r = n(46),
      i = n(23);

  t.exports = function (t) {
    return r(i(t));
  };
}, function (t, e, n) {
  var r = n(0),
      i = n(3),
      o = n(23),
      s = /"/g,
      u = function (t, e, n, r) {
    var i = String(o(t)),
        u = "<" + e;
    return "" !== n && (u += " " + n + "=\"" + String(r).replace(s, "&quot;") + "\""), u + ">" + i + "</" + e + ">";
  };

  t.exports = function (t, e) {
    var n = {};
    n[t] = e(u), r(r.P + r.F * i(function () {
      var e = ""[t]("\"");
      return e !== e.toLowerCase() || e.split("\"").length > 3;
    }), "String", n);
  };
}, function (t, e, n) {
  var r = n(47),
      i = n(32),
      o = n(14),
      s = n(22),
      u = n(11),
      a = n(91),
      l = Object.getOwnPropertyDescriptor;
  e.f = n(6) ? l : function (t, e) {
    if (t = o(t), e = s(e, !0), a) try {
      return l(t, e);
    } catch (t) {}
    if (u(t, e)) return i(!r.f.call(t, e), t[e]);
  };
}, function (t, e, n) {
  var r = n(11),
      i = n(9),
      o = n(66)("IE_PROTO"),
      s = Object.prototype;

  t.exports = Object.getPrototypeOf || function (t) {
    return t = i(t), r(t, o) ? t[o] : "function" == typeof t.constructor && t instanceof t.constructor ? t.constructor.prototype : t instanceof Object ? s : null;
  };
}, function (t, e, n) {
  var r = n(10);

  t.exports = function (t, e, n) {
    if (r(t), void 0 === e) return t;

    switch (n) {
      case 1:
        return function (n) {
          return t.call(e, n);
        };

      case 2:
        return function (n, r) {
          return t.call(e, n, r);
        };

      case 3:
        return function (n, r, i) {
          return t.call(e, n, r, i);
        };
    }

    return function () {
      return t.apply(e, arguments);
    };
  };
}, function (t, e) {
  var n = {}.toString;

  t.exports = function (t) {
    return n.call(t).slice(8, -1);
  };
}, function (t, e, n) {
  "use strict";

  var r = n(3);

  t.exports = function (t, e) {
    return !!t && r(function () {
      e ? t.call(null, function () {}, 1) : t.call(null);
    });
  };
}, function (t, e) {
  var n = t.exports = {
    version: "2.5.0"
  };
  "number" == typeof __e && (_global.__e = n);
}, function (t, e, n) {
  var r = n(4);

  t.exports = function (t, e) {
    if (!r(t)) return t;
    var n, i;
    if (e && "function" == typeof (n = t.toString) && !r(i = n.call(t))) return i;
    if ("function" == typeof (n = t.valueOf) && !r(i = n.call(t))) return i;
    if (!e && "function" == typeof (n = t.toString) && !r(i = n.call(t))) return i;
    throw TypeError("Can't convert object to primitive value");
  };
}, function (t, e) {
  t.exports = function (t) {
    if (void 0 == t) throw TypeError("Can't call method on  " + t);
    return t;
  };
}, function (t, e) {
  var n = Math.ceil,
      r = Math.floor;

  t.exports = function (t) {
    return isNaN(t = +t) ? 0 : (t > 0 ? r : n)(t);
  };
}, function (t, e, n) {
  var r = n(0),
      i = n(21),
      o = n(3);

  t.exports = function (t, e) {
    var n = (i.Object || {})[t] || Object[t],
        s = {};
    s[t] = e(n), r(r.S + r.F * o(function () {
      n(1);
    }), "Object", s);
  };
}, function (t, e, n) {
  var r = n(18),
      i = n(46),
      o = n(9),
      s = n(8),
      u = n(83);

  t.exports = function (t, e) {
    var n = 1 == t,
        a = 2 == t,
        l = 3 == t,
        f = 4 == t,
        c = 6 == t,
        h = 5 == t || c,
        p = e || u;
    return function (e, u, d) {
      for (var v, y, m = o(e), P = i(m), g = r(u, d, 3), _ = s(P.length), x = 0, I = n ? p(e, _) : a ? p(e, 0) : void 0; _ > x; x++) if ((h || x in P) && (y = g(v = P[x], x, m), t)) if (n) I[x] = y;else if (y) switch (t) {
        case 3:
          return !0;

        case 5:
          return v;

        case 6:
          return x;

        case 2:
          I.push(v);
      } else if (f) return !1;

      return c ? -1 : l || f ? f : I;
    };
  };
}, function (t, e, n) {
  "use strict";

  if (n(6)) {
    var r = n(34),
        i = n(2),
        o = n(3),
        s = n(0),
        u = n(60),
        a = n(89),
        l = n(18),
        f = n(39),
        c = n(32),
        h = n(12),
        p = n(41),
        d = n(24),
        v = n(8),
        y = n(116),
        m = n(35),
        P = n(22),
        g = n(11),
        _ = n(48),
        x = n(4),
        I = n(9),
        C = n(80),
        E = n(36),
        L = n(17),
        S = n(37).f,
        b = n(82),
        O = n(33),
        T = n(5),
        N = n(26),
        w = n(50),
        A = n(58),
        X = n(85),
        Y = n(44),
        D = n(55),
        B = n(38),
        M = n(84),
        R = n(106),
        F = n(7),
        W = n(16),
        k = F.f,
        q = W.f,
        j = i.RangeError,
        U = i.TypeError,
        G = i.Uint8Array,
        z = Array.prototype,
        H = a.ArrayBuffer,
        J = a.DataView,
        V = N(0),
        Z = N(2),
        K = N(3),
        Q = N(4),
        $ = N(5),
        tt = N(6),
        et = w(!0),
        nt = w(!1),
        rt = X.values,
        it = X.keys,
        ot = X.entries,
        st = z.lastIndexOf,
        ut = z.reduce,
        at = z.reduceRight,
        lt = z.join,
        ft = z.sort,
        ct = z.slice,
        ht = z.toString,
        pt = z.toLocaleString,
        dt = T("iterator"),
        vt = T("toStringTag"),
        yt = O("typed_constructor"),
        mt = O("def_constructor"),
        Pt = u.CONSTR,
        gt = u.TYPED,
        _t = u.VIEW,
        xt = N(1, function (t, e) {
      return St(A(t, t[mt]), e);
    }),
        It = o(function () {
      return 1 === new G(new Uint16Array([1]).buffer)[0];
    }),
        Ct = !!G && !!G.prototype.set && o(function () {
      new G(1).set({});
    }),
        Et = function (t, e) {
      var n = d(t);
      if (n < 0 || n % e) throw j("Wrong offset!");
      return n;
    },
        Lt = function (t) {
      if (x(t) && gt in t) return t;
      throw U(t + " is not a typed array!");
    },
        St = function (t, e) {
      if (!(x(t) && yt in t)) throw U("It is not a typed array constructor!");
      return new t(e);
    },
        bt = function (t, e) {
      return Ot(A(t, t[mt]), e);
    },
        Ot = function (t, e) {
      for (var n = 0, r = e.length, i = St(t, r); r > n;) i[n] = e[n++];

      return i;
    },
        Tt = function (t, e, n) {
      k(t, e, {
        get: function () {
          return (this || _global)._d[n];
        }
      });
    },
        Nt = function (t) {
      var e,
          n,
          r,
          i,
          o,
          s,
          u = I(t),
          a = arguments.length,
          f = a > 1 ? arguments[1] : void 0,
          c = void 0 !== f,
          h = b(u);

      if (void 0 != h && !C(h)) {
        for (s = h.call(u), r = [], e = 0; !(o = s.next()).done; e++) r.push(o.value);

        u = r;
      }

      for (c && a > 2 && (f = l(f, arguments[2], 2)), e = 0, n = v(u.length), i = St(this || _global, n); n > e; e++) i[e] = c ? f(u[e], e) : u[e];

      return i;
    },
        wt = function () {
      for (var t = 0, e = arguments.length, n = St(this || _global, e); e > t;) n[t] = arguments[t++];

      return n;
    },
        At = !!G && o(function () {
      pt.call(new G(1));
    }),
        Xt = function () {
      return pt.apply(At ? ct.call(Lt(this || _global)) : Lt(this || _global), arguments);
    },
        Yt = {
      copyWithin: function (t, e) {
        return R.call(Lt(this || _global), t, e, arguments.length > 2 ? arguments[2] : void 0);
      },
      every: function (t) {
        return Q(Lt(this || _global), t, arguments.length > 1 ? arguments[1] : void 0);
      },
      fill: function (t) {
        return M.apply(Lt(this || _global), arguments);
      },
      filter: function (t) {
        return bt(this || _global, Z(Lt(this || _global), t, arguments.length > 1 ? arguments[1] : void 0));
      },
      find: function (t) {
        return $(Lt(this || _global), t, arguments.length > 1 ? arguments[1] : void 0);
      },
      findIndex: function (t) {
        return tt(Lt(this || _global), t, arguments.length > 1 ? arguments[1] : void 0);
      },
      forEach: function (t) {
        V(Lt(this || _global), t, arguments.length > 1 ? arguments[1] : void 0);
      },
      indexOf: function (t) {
        return nt(Lt(this || _global), t, arguments.length > 1 ? arguments[1] : void 0);
      },
      includes: function (t) {
        return et(Lt(this || _global), t, arguments.length > 1 ? arguments[1] : void 0);
      },
      join: function (t) {
        return lt.apply(Lt(this || _global), arguments);
      },
      lastIndexOf: function (t) {
        return st.apply(Lt(this || _global), arguments);
      },
      map: function (t) {
        return xt(Lt(this || _global), t, arguments.length > 1 ? arguments[1] : void 0);
      },
      reduce: function (t) {
        return ut.apply(Lt(this || _global), arguments);
      },
      reduceRight: function (t) {
        return at.apply(Lt(this || _global), arguments);
      },
      reverse: function () {
        for (var t, e = Lt(this || _global).length, n = Math.floor(e / 2), r = 0; r < n;) t = (this || _global)[r], (this || _global)[r++] = (this || _global)[--e], (this || _global)[e] = t;

        return this || _global;
      },
      some: function (t) {
        return K(Lt(this || _global), t, arguments.length > 1 ? arguments[1] : void 0);
      },
      sort: function (t) {
        return ft.call(Lt(this || _global), t);
      },
      subarray: function (t, e) {
        var n = Lt(this || _global),
            r = n.length,
            i = m(t, r);
        return new (A(n, n[mt]))(n.buffer, n.byteOffset + i * n.BYTES_PER_ELEMENT, v((void 0 === e ? r : m(e, r)) - i));
      }
    },
        Dt = function (t, e) {
      return bt(this || _global, ct.call(Lt(this || _global), t, e));
    },
        Bt = function (t) {
      Lt(this || _global);
      var e = Et(arguments[1], 1),
          n = (this || _global).length,
          r = I(t),
          i = v(r.length),
          o = 0;
      if (i + e > n) throw j("Wrong length!");

      for (; o < i;) (this || _global)[e + o] = r[o++];
    },
        Mt = {
      entries: function () {
        return ot.call(Lt(this || _global));
      },
      keys: function () {
        return it.call(Lt(this || _global));
      },
      values: function () {
        return rt.call(Lt(this || _global));
      }
    },
        Rt = function (t, e) {
      return x(t) && t[gt] && "symbol" != typeof e && e in t && String(+e) == String(e);
    },
        Ft = function (t, e) {
      return Rt(t, e = P(e, !0)) ? c(2, t[e]) : q(t, e);
    },
        Wt = function (t, e, n) {
      return !(Rt(t, e = P(e, !0)) && x(n) && g(n, "value")) || g(n, "get") || g(n, "set") || n.configurable || g(n, "writable") && !n.writable || g(n, "enumerable") && !n.enumerable ? k(t, e, n) : (t[e] = n.value, t);
    };

    Pt || (W.f = Ft, F.f = Wt), s(s.S + s.F * !Pt, "Object", {
      getOwnPropertyDescriptor: Ft,
      defineProperty: Wt
    }), o(function () {
      ht.call({});
    }) && (ht = pt = function () {
      return lt.call(this || _global);
    });
    var kt = p({}, Yt);
    p(kt, Mt), h(kt, dt, Mt.values), p(kt, {
      slice: Dt,
      set: Bt,
      constructor: function () {},
      toString: ht,
      toLocaleString: Xt
    }), Tt(kt, "buffer", "b"), Tt(kt, "byteOffset", "o"), Tt(kt, "byteLength", "l"), Tt(kt, "length", "e"), k(kt, vt, {
      get: function () {
        return (this || _global)[gt];
      }
    }), t.exports = function (t, e, n, a) {
      var l = t + ((a = !!a) ? "Clamped" : "") + "Array",
          c = "get" + t,
          p = "set" + t,
          d = i[l],
          m = d || {},
          P = d && L(d),
          g = !d || !u.ABV,
          I = {},
          C = d && d.prototype,
          b = function (t, n) {
        k(t, n, {
          get: function () {
            return function (t, n) {
              var r = t._d;
              return r.v[c](n * e + r.o, It);
            }(this || _global, n);
          },
          set: function (t) {
            return function (t, n, r) {
              var i = t._d;
              a && (r = (r = Math.round(r)) < 0 ? 0 : r > 255 ? 255 : 255 & r), i.v[p](n * e + i.o, r, It);
            }(this || _global, n, t);
          },
          enumerable: !0
        });
      };

      g ? (d = n(function (t, n, r, i) {
        f(t, d, l, "_d");
        var o,
            s,
            u,
            a,
            c = 0,
            p = 0;

        if (x(n)) {
          if (!(n instanceof H || "ArrayBuffer" == (a = _(n)) || "SharedArrayBuffer" == a)) return gt in n ? Ot(d, n) : Nt.call(d, n);
          o = n, p = Et(r, e);
          var m = n.byteLength;

          if (void 0 === i) {
            if (m % e) throw j("Wrong length!");
            if ((s = m - p) < 0) throw j("Wrong length!");
          } else if ((s = v(i) * e) + p > m) throw j("Wrong length!");

          u = s / e;
        } else u = y(n), o = new H(s = u * e);

        for (h(t, "_d", {
          b: o,
          o: p,
          l: s,
          e: u,
          v: new J(o)
        }); c < u;) b(t, c++);
      }), C = d.prototype = E(kt), h(C, "constructor", d)) : o(function () {
        d(1);
      }) && o(function () {
        new d(-1);
      }) && D(function (t) {
        new d(), new d(null), new d(1.5), new d(t);
      }, !0) || (d = n(function (t, n, r, i) {
        var o;
        return f(t, d, l), x(n) ? n instanceof H || "ArrayBuffer" == (o = _(n)) || "SharedArrayBuffer" == o ? void 0 !== i ? new m(n, Et(r, e), i) : void 0 !== r ? new m(n, Et(r, e)) : new m(n) : gt in n ? Ot(d, n) : Nt.call(d, n) : new m(y(n));
      }), V(P !== Function.prototype ? S(m).concat(S(P)) : S(m), function (t) {
        t in d || h(d, t, m[t]);
      }), d.prototype = C, r || (C.constructor = d));
      var O = C[dt],
          T = !!O && ("values" == O.name || void 0 == O.name),
          N = Mt.values;
      h(d, yt, !0), h(C, gt, l), h(C, _t, !0), h(C, mt, d), (a ? new d(1)[vt] == l : vt in C) || k(C, vt, {
        get: function () {
          return l;
        }
      }), I[l] = d, s(s.G + s.W + s.F * (d != m), I), s(s.S, l, {
        BYTES_PER_ELEMENT: e
      }), s(s.S + s.F * o(function () {
        m.of.call(d, 1);
      }), l, {
        from: Nt,
        of: wt
      }), "BYTES_PER_ELEMENT" in C || h(C, "BYTES_PER_ELEMENT", e), s(s.P, l, Yt), B(l), s(s.P + s.F * Ct, l, {
        set: Bt
      }), s(s.P + s.F * !T, l, Mt), r || C.toString == ht || (C.toString = ht), s(s.P + s.F * o(function () {
        new d(1).slice();
      }), l, {
        slice: Dt
      }), s(s.P + s.F * (o(function () {
        return [1, 2].toLocaleString() != new d([1, 2]).toLocaleString();
      }) || !o(function () {
        C.toLocaleString.call([1, 2]);
      })), l, {
        toLocaleString: Xt
      }), Y[l] = T ? O : N, r || T || h(C, dt, N);
    };
  } else t.exports = function () {};
}, function (t, e, n) {
  var r = n(111),
      i = n(0),
      o = n(49)("metadata"),
      s = o.store || (o.store = new (n(114))()),
      u = function (t, e, n) {
    var i = s.get(t);

    if (!i) {
      if (!n) return;
      s.set(t, i = new r());
    }

    var o = i.get(e);

    if (!o) {
      if (!n) return;
      i.set(e, o = new r());
    }

    return o;
  };

  t.exports = {
    store: s,
    map: u,
    has: function (t, e, n) {
      var r = u(e, n, !1);
      return void 0 !== r && r.has(t);
    },
    get: function (t, e, n) {
      var r = u(e, n, !1);
      return void 0 === r ? void 0 : r.get(t);
    },
    set: function (t, e, n, r) {
      u(n, r, !0).set(t, e);
    },
    keys: function (t, e) {
      var n = u(t, e, !1),
          r = [];
      return n && n.forEach(function (t, e) {
        r.push(e);
      }), r;
    },
    key: function (t) {
      return void 0 === t || "symbol" == typeof t ? t : String(t);
    },
    exp: function (t) {
      i(i.S, "Reflect", t);
    }
  };
}, function (t, e, n) {
  var r = n(33)("meta"),
      i = n(4),
      o = n(11),
      s = n(7).f,
      u = 0,
      a = Object.isExtensible || function () {
    return !0;
  },
      l = !n(3)(function () {
    return a(Object.preventExtensions({}));
  }),
      f = function (t) {
    s(t, r, {
      value: {
        i: "O" + ++u,
        w: {}
      }
    });
  },
      c = t.exports = {
    KEY: r,
    NEED: !1,
    fastKey: function (t, e) {
      if (!i(t)) return "symbol" == typeof t ? t : ("string" == typeof t ? "S" : "P") + t;

      if (!o(t, r)) {
        if (!a(t)) return "F";
        if (!e) return "E";
        f(t);
      }

      return t[r].i;
    },
    getWeak: function (t, e) {
      if (!o(t, r)) {
        if (!a(t)) return !0;
        if (!e) return !1;
        f(t);
      }

      return t[r].w;
    },
    onFreeze: function (t) {
      return l && c.NEED && a(t) && !o(t, r) && f(t), t;
    }
  };
}, function (t, e, n) {
  var r = n(93),
      i = n(67);

  t.exports = Object.keys || function (t) {
    return r(t, i);
  };
}, function (t, e, n) {
  var r = n(5)("unscopables"),
      i = Array.prototype;
  void 0 == i[r] && n(12)(i, r, {}), t.exports = function (t) {
    i[r][t] = !0;
  };
}, function (t, e) {
  t.exports = function (t, e) {
    return {
      enumerable: !(1 & t),
      configurable: !(2 & t),
      writable: !(4 & t),
      value: e
    };
  };
}, function (t, e) {
  var n = 0,
      r = Math.random();

  t.exports = function (t) {
    return "Symbol(".concat(void 0 === t ? "" : t, ")_", (++n + r).toString(36));
  };
}, function (t, e) {
  t.exports = !1;
}, function (t, e, n) {
  var r = n(24),
      i = Math.max,
      o = Math.min;

  t.exports = function (t, e) {
    return (t = r(t)) < 0 ? i(t + e, 0) : o(t, e);
  };
}, function (t, e, n) {
  var r = n(1),
      i = n(94),
      o = n(67),
      s = n(66)("IE_PROTO"),
      u = function () {},
      a = function () {
    var t,
        e = n(64)("iframe"),
        r = o.length;

    for (e.style.display = "none", n(68).appendChild(e), e.src = "javascript:", (t = e.contentWindow.document).open(), t.write("<script>document.F=Object</script>"), t.close(), a = t.F; r--;) delete a.prototype[o[r]];

    return a();
  };

  t.exports = Object.create || function (t, e) {
    var n;
    return null !== t ? (u.prototype = r(t), n = new u(), u.prototype = null, n[s] = t) : n = a(), void 0 === e ? n : i(n, e);
  };
}, function (t, e, n) {
  var r = n(93),
      i = n(67).concat("length", "prototype");

  e.f = Object.getOwnPropertyNames || function (t) {
    return r(t, i);
  };
}, function (t, e, n) {
  "use strict";

  var r = n(2),
      i = n(7),
      o = n(6),
      s = n(5)("species");

  t.exports = function (t) {
    var e = r[t];
    o && e && !e[s] && i.f(e, s, {
      configurable: !0,
      get: function () {
        return this || _global;
      }
    });
  };
}, function (t, e) {
  t.exports = function (t, e, n, r) {
    if (!(t instanceof e) || void 0 !== r && r in t) throw TypeError(n + ": incorrect invocation!");
    return t;
  };
}, function (t, e, n) {
  var r = n(18),
      i = n(104),
      o = n(80),
      s = n(1),
      u = n(8),
      a = n(82),
      l = {},
      f = {};
  (e = t.exports = function (t, e, n, c, h) {
    var p,
        d,
        v,
        y,
        m = h ? function () {
      return t;
    } : a(t),
        P = r(n, c, e ? 2 : 1),
        g = 0;
    if ("function" != typeof m) throw TypeError(t + " is not iterable!");

    if (o(m)) {
      for (p = u(t.length); p > g; g++) if ((y = e ? P(s(d = t[g])[0], d[1]) : P(t[g])) === l || y === f) return y;
    } else for (v = m.call(t); !(d = v.next()).done;) if ((y = i(v, P, d.value, e)) === l || y === f) return y;
  }).BREAK = l, e.RETURN = f;
}, function (t, e, n) {
  var r = n(13);

  t.exports = function (t, e, n) {
    for (var i in e) r(t, i, e[i], n);

    return t;
  };
}, function (t, e, n) {
  var r = n(7).f,
      i = n(11),
      o = n(5)("toStringTag");

  t.exports = function (t, e, n) {
    t && !i(t = n ? t : t.prototype, o) && r(t, o, {
      configurable: !0,
      value: e
    });
  };
}, function (t, e, n) {
  var r = n(0),
      i = n(23),
      o = n(3),
      s = n(70),
      u = "[" + s + "]",
      a = RegExp("^" + u + u + "*"),
      l = RegExp(u + u + "*$"),
      f = function (t, e, n) {
    var i = {},
        u = o(function () {
      return !!s[t]() || "\u200B\x85" != "\u200B\x85"[t]();
    }),
        a = i[t] = u ? e(c) : s[t];
    n && (i[n] = a), r(r.P + r.F * u, "String", i);
  },
      c = f.trim = function (t, e) {
    return t = String(i(t)), 1 & e && (t = t.replace(a, "")), 2 & e && (t = t.replace(l, "")), t;
  };

  t.exports = f;
}, function (t, e) {
  t.exports = {};
}, function (t, e, n) {
  var r = n(4);

  t.exports = function (t, e) {
    if (!r(t) || t._t !== e) throw TypeError("Incompatible receiver, " + e + " required!");
    return t;
  };
}, function (t, e, n) {
  var r = n(19);
  t.exports = Object("z").propertyIsEnumerable(0) ? Object : function (t) {
    return "String" == r(t) ? t.split("") : Object(t);
  };
}, function (t, e) {
  e.f = {}.propertyIsEnumerable;
}, function (t, e, n) {
  var r = n(19),
      i = n(5)("toStringTag"),
      o = "Arguments" == r(function () {
    return arguments;
  }());

  t.exports = function (t) {
    var e, n, s;
    return void 0 === t ? "Undefined" : null === t ? "Null" : "string" == typeof (n = function (t, e) {
      try {
        return t[e];
      } catch (t) {}
    }(e = Object(t), i)) ? n : o ? r(e) : "Object" == (s = r(e)) && "function" == typeof e.callee ? "Arguments" : s;
  };
}, function (t, e, n) {
  var r = n(2),
      i = r["__core-js_shared__"] || (r["__core-js_shared__"] = {});

  t.exports = function (t) {
    return i[t] || (i[t] = {});
  };
}, function (t, e, n) {
  var r = n(14),
      i = n(8),
      o = n(35);

  t.exports = function (t) {
    return function (e, n, s) {
      var u,
          a = r(e),
          l = i(a.length),
          f = o(s, l);

      if (t && n != n) {
        for (; l > f;) if ((u = a[f++]) != u) return !0;
      } else for (; l > f; f++) if ((t || f in a) && a[f] === n) return t || f || 0;

      return !t && -1;
    };
  };
}, function (t, e) {
  e.f = Object.getOwnPropertySymbols;
}, function (t, e, n) {
  var r = n(19);

  t.exports = Array.isArray || function (t) {
    return "Array" == r(t);
  };
}, function (t, e) {
  t.exports = function (t, e, n) {
    var r = void 0 === n;

    switch (e.length) {
      case 0:
        return r ? t() : t.call(n);

      case 1:
        return r ? t(e[0]) : t.call(n, e[0]);

      case 2:
        return r ? t(e[0], e[1]) : t.call(n, e[0], e[1]);

      case 3:
        return r ? t(e[0], e[1], e[2]) : t.call(n, e[0], e[1], e[2]);

      case 4:
        return r ? t(e[0], e[1], e[2], e[3]) : t.call(n, e[0], e[1], e[2], e[3]);
    }

    return t.apply(n, e);
  };
}, function (t, e, n) {
  var r = n(4),
      i = n(19),
      o = n(5)("match");

  t.exports = function (t) {
    var e;
    return r(t) && (void 0 !== (e = t[o]) ? !!e : "RegExp" == i(t));
  };
}, function (t, e, n) {
  var r = n(5)("iterator"),
      i = !1;

  try {
    var o = [7][r]();
    o.return = function () {
      i = !0;
    }, Array.from(o, function () {
      throw 2;
    });
  } catch (t) {}

  t.exports = function (t, e) {
    if (!e && !i) return !1;
    var n = !1;

    try {
      var o = [7],
          s = o[r]();
      s.next = function () {
        return {
          done: n = !0
        };
      }, o[r] = function () {
        return s;
      }, t(o);
    } catch (t) {}

    return n;
  };
}, function (t, e, n) {
  "use strict";

  var r = n(1);

  t.exports = function () {
    var t = r(this || _global),
        e = "";
    return t.global && (e += "g"), t.ignoreCase && (e += "i"), t.multiline && (e += "m"), t.unicode && (e += "u"), t.sticky && (e += "y"), e;
  };
}, function (t, e, n) {
  "use strict";

  var r = n(12),
      i = n(13),
      o = n(3),
      s = n(23),
      u = n(5);

  t.exports = function (t, e, n) {
    var a = u(t),
        l = n(s, a, ""[t]),
        f = l[0],
        c = l[1];
    o(function () {
      var e = {};
      return e[a] = function () {
        return 7;
      }, 7 != ""[t](e);
    }) && (i(String.prototype, t, f), r(RegExp.prototype, a, 2 == e ? function (t, e) {
      return c.call(t, this || _global, e);
    } : function (t) {
      return c.call(t, this || _global);
    }));
  };
}, function (t, e, n) {
  var r = n(1),
      i = n(10),
      o = n(5)("species");

  t.exports = function (t, e) {
    var n,
        s = r(t).constructor;
    return void 0 === s || void 0 == (n = r(s)[o]) ? e : i(n);
  };
}, function (t, e, n) {
  "use strict";

  var r = n(2),
      i = n(0),
      o = n(13),
      s = n(41),
      u = n(29),
      a = n(40),
      l = n(39),
      f = n(4),
      c = n(3),
      h = n(55),
      p = n(42),
      d = n(71);

  t.exports = function (t, e, n, v, y, m) {
    var P = r[t],
        g = P,
        _ = y ? "set" : "add",
        x = g && g.prototype,
        I = {},
        C = function (t) {
      var e = x[t];
      o(x, t, "delete" == t ? function (t) {
        return !(m && !f(t)) && e.call(this || _global, 0 === t ? 0 : t);
      } : "has" == t ? function (t) {
        return !(m && !f(t)) && e.call(this || _global, 0 === t ? 0 : t);
      } : "get" == t ? function (t) {
        return m && !f(t) ? void 0 : e.call(this || _global, 0 === t ? 0 : t);
      } : "add" == t ? function (t) {
        return e.call(this || _global, 0 === t ? 0 : t), this || _global;
      } : function (t, n) {
        return e.call(this || _global, 0 === t ? 0 : t, n), this || _global;
      });
    };

    if ("function" == typeof g && (m || x.forEach && !c(function () {
      new g().entries().next();
    }))) {
      var E = new g(),
          L = E[_](m ? {} : -0, 1) != E,
          S = c(function () {
        E.has(1);
      }),
          b = h(function (t) {
        new g(t);
      }),
          O = !m && c(function () {
        for (var t = new g(), e = 5; e--;) t[_](e, e);

        return !t.has(-0);
      });
      b || ((g = e(function (e, n) {
        l(e, g, t);
        var r = d(new P(), e, g);
        return void 0 != n && a(n, y, r[_], r), r;
      })).prototype = x, x.constructor = g), (S || O) && (C("delete"), C("has"), y && C("get")), (O || L) && C(_), m && x.clear && delete x.clear;
    } else g = v.getConstructor(e, t, y, _), s(g.prototype, n), u.NEED = !0;

    return p(g, t), I[t] = g, i(i.G + i.W + i.F * (g != P), I), m || v.setStrong(g, t, y), g;
  };
}, function (t, e, n) {
  for (var r, i = n(2), o = n(12), s = n(33), u = s("typed_array"), a = s("view"), l = !(!i.ArrayBuffer || !i.DataView), f = l, c = 0, h = "Int8Array,Uint8Array,Uint8ClampedArray,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array".split(","); c < 9;) (r = i[h[c++]]) ? (o(r.prototype, u, !0), o(r.prototype, a, !0)) : f = !1;

  t.exports = {
    ABV: l,
    CONSTR: f,
    TYPED: u,
    VIEW: a
  };
}, function (t, e, n) {
  "use strict";

  t.exports = n(34) || !n(3)(function () {
    var t = Math.random();
    __defineSetter__.call(null, t, function () {}), delete n(2)[t];
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0);

  t.exports = function (t) {
    r(r.S, t, {
      of: function () {
        for (var t = arguments.length, e = Array(t); t--;) e[t] = arguments[t];

        return new (this || _global)(e);
      }
    });
  };
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(10),
      o = n(18),
      s = n(40);

  t.exports = function (t) {
    r(r.S, t, {
      from: function (t) {
        var e,
            n,
            r,
            u,
            a = arguments[1];
        return i(this || _global), (e = void 0 !== a) && i(a), void 0 == t ? new (this || _global)() : (n = [], e ? (r = 0, u = o(a, arguments[2], 2), s(t, !1, function (t) {
          n.push(u(t, r++));
        })) : s(t, !1, n.push, n), new (this || _global)(n));
      }
    });
  };
}, function (t, e, n) {
  var r = n(4),
      i = n(2).document,
      o = r(i) && r(i.createElement);

  t.exports = function (t) {
    return o ? i.createElement(t) : {};
  };
}, function (t, e, n) {
  var r = n(2),
      i = n(21),
      o = n(34),
      s = n(92),
      u = n(7).f;

  t.exports = function (t) {
    var e = i.Symbol || (i.Symbol = o ? {} : r.Symbol || {});
    "_" == t.charAt(0) || t in e || u(e, t, {
      value: s.f(t)
    });
  };
}, function (t, e, n) {
  var r = n(49)("keys"),
      i = n(33);

  t.exports = function (t) {
    return r[t] || (r[t] = i(t));
  };
}, function (t, e) {
  t.exports = "constructor,hasOwnProperty,isPrototypeOf,propertyIsEnumerable,toLocaleString,toString,valueOf".split(",");
}, function (t, e, n) {
  var r = n(2).document;
  t.exports = r && r.documentElement;
}, function (t, e, n) {
  var r = n(4),
      i = n(1),
      o = function (t, e) {
    if (i(t), !r(e) && null !== e) throw TypeError(e + ": can't set as prototype!");
  };

  t.exports = {
    set: Object.setPrototypeOf || ("__proto__" in {} ? function (t, e, r) {
      try {
        (r = n(18)(Function.call, n(16).f(Object.prototype, "__proto__").set, 2))(t, []), e = !(t instanceof Array);
      } catch (t) {
        e = !0;
      }

      return function (t, n) {
        return o(t, n), e ? t.__proto__ = n : r(t, n), t;
      };
    }({}, !1) : void 0),
    check: o
  };
}, function (t, e) {
  t.exports = "\t\n\x0B\f\r \xA0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000\u2028\u2029\uFEFF";
}, function (t, e, n) {
  var r = n(4),
      i = n(69).set;

  t.exports = function (t, e, n) {
    var o,
        s = e.constructor;
    return s !== n && "function" == typeof s && (o = s.prototype) !== n.prototype && r(o) && i && i(t, o), t;
  };
}, function (t, e, n) {
  "use strict";

  var r = n(24),
      i = n(23);

  t.exports = function (t) {
    var e = String(i(this || _global)),
        n = "",
        o = r(t);
    if (o < 0 || o == 1 / 0) throw RangeError("Count can't be negative");

    for (; o > 0; (o >>>= 1) && (e += e)) 1 & o && (n += e);

    return n;
  };
}, function (t, e) {
  t.exports = Math.sign || function (t) {
    return 0 == (t = +t) || t != t ? t : t < 0 ? -1 : 1;
  };
}, function (t, e) {
  var n = Math.expm1;
  t.exports = !n || n(10) > 22025.465794806718 || n(10) < 22025.465794806718 || -2e-17 != n(-2e-17) ? function (t) {
    return 0 == (t = +t) ? t : t > -0.000001 && t < 0.000001 ? t + t * t / 2 : Math.exp(t) - 1;
  } : n;
}, function (t, e, n) {
  var r = n(24),
      i = n(23);

  t.exports = function (t) {
    return function (e, n) {
      var o,
          s,
          u = String(i(e)),
          a = r(n),
          l = u.length;
      return a < 0 || a >= l ? t ? "" : void 0 : (o = u.charCodeAt(a)) < 55296 || o > 56319 || a + 1 === l || (s = u.charCodeAt(a + 1)) < 56320 || s > 57343 ? t ? u.charAt(a) : o : t ? u.slice(a, a + 2) : s - 56320 + (o - 55296 << 10) + 65536;
    };
  };
}, function (t, e, n) {
  "use strict";

  var r = n(34),
      i = n(0),
      o = n(13),
      s = n(12),
      u = n(11),
      a = n(44),
      l = n(77),
      f = n(42),
      c = n(17),
      h = n(5)("iterator"),
      p = !([].keys && "next" in [].keys()),
      d = function () {
    return this || _global;
  };

  t.exports = function (t, e, n, v, y, m, P) {
    l(n, e, v);

    var g,
        _,
        x,
        I = function (t) {
      if (!p && t in S) return S[t];

      switch (t) {
        case "keys":
        case "values":
          return function () {
            return new n(this || _global, t);
          };
      }

      return function () {
        return new n(this || _global, t);
      };
    },
        C = e + " Iterator",
        E = "values" == y,
        L = !1,
        S = t.prototype,
        b = S[h] || S["@@iterator"] || y && S[y],
        O = b || I(y),
        T = y ? E ? I("entries") : O : void 0,
        N = "Array" == e && S.entries || b;

    if (N && (x = c(N.call(new t()))) !== Object.prototype && x.next && (f(x, C, !0), r || u(x, h) || s(x, h, d)), E && b && "values" !== b.name && (L = !0, O = function () {
      return b.call(this || _global);
    }), r && !P || !p && !L && S[h] || s(S, h, O), a[e] = O, a[C] = d, y) if (g = {
      values: E ? O : I("values"),
      keys: m ? O : I("keys"),
      entries: T
    }, P) for (_ in g) _ in S || o(S, _, g[_]);else i(i.P + i.F * (p || L), e, g);
    return g;
  };
}, function (t, e, n) {
  "use strict";

  var r = n(36),
      i = n(32),
      o = n(42),
      s = {};
  n(12)(s, n(5)("iterator"), function () {
    return this || _global;
  }), t.exports = function (t, e, n) {
    t.prototype = r(s, {
      next: i(1, n)
    }), o(t, e + " Iterator");
  };
}, function (t, e, n) {
  var r = n(54),
      i = n(23);

  t.exports = function (t, e, n) {
    if (r(e)) throw TypeError("String#" + n + " doesn't accept regex!");
    return String(i(t));
  };
}, function (t, e, n) {
  var r = n(5)("match");

  t.exports = function (t) {
    var e = /./;

    try {
      "/./"[t](e);
    } catch (n) {
      try {
        return e[r] = !1, !"/./"[t](e);
      } catch (t) {}
    }

    return !0;
  };
}, function (t, e, n) {
  var r = n(44),
      i = n(5)("iterator"),
      o = Array.prototype;

  t.exports = function (t) {
    return void 0 !== t && (r.Array === t || o[i] === t);
  };
}, function (t, e, n) {
  "use strict";

  var r = n(7),
      i = n(32);

  t.exports = function (t, e, n) {
    e in t ? r.f(t, e, i(0, n)) : t[e] = n;
  };
}, function (t, e, n) {
  var r = n(48),
      i = n(5)("iterator"),
      o = n(44);

  t.exports = n(21).getIteratorMethod = function (t) {
    if (void 0 != t) return t[i] || t["@@iterator"] || o[r(t)];
  };
}, function (t, e, n) {
  var r = n(220);

  t.exports = function (t, e) {
    return new (r(t))(e);
  };
}, function (t, e, n) {
  "use strict";

  var r = n(9),
      i = n(35),
      o = n(8);

  t.exports = function (t) {
    for (var e = r(this || _global), n = o(e.length), s = arguments.length, u = i(s > 1 ? arguments[1] : void 0, n), a = s > 2 ? arguments[2] : void 0, l = void 0 === a ? n : i(a, n); l > u;) e[u++] = t;

    return e;
  };
}, function (t, e, n) {
  "use strict";

  var r = n(31),
      i = n(107),
      o = n(44),
      s = n(14);
  t.exports = n(76)(Array, "Array", function (t, e) {
    (this || _global)._t = s(t), (this || _global)._i = 0, (this || _global)._k = e;
  }, function () {
    var t = (this || _global)._t,
        e = (this || _global)._k,
        n = (this || _global)._i++;
    return !t || n >= t.length ? ((this || _global)._t = void 0, i(1)) : i(0, "keys" == e ? n : "values" == e ? t[n] : [n, t[n]]);
  }, "values"), o.Arguments = o.Array, r("keys"), r("values"), r("entries");
}, function (t, e, n) {
  var r,
      i,
      o,
      s = n(18),
      u = n(53),
      a = n(68),
      l = n(64),
      f = n(2),
      c = f.process,
      h = f.setImmediate,
      p = f.clearImmediate,
      d = f.MessageChannel,
      v = f.Dispatch,
      y = 0,
      m = {},
      P = function () {
    var t = +(this || _global);

    if (m.hasOwnProperty(t)) {
      var e = m[t];
      delete m[t], e();
    }
  },
      g = function (t) {
    P.call(t.data);
  };

  h && p || (h = function (t) {
    for (var e = [], n = 1; arguments.length > n;) e.push(arguments[n++]);

    return m[++y] = function () {
      u("function" == typeof t ? t : Function(t), e);
    }, r(y), y;
  }, p = function (t) {
    delete m[t];
  }, "process" == n(19)(c) ? r = function (t) {
    c.nextTick(s(P, t, 1));
  } : v && v.now ? r = function (t) {
    v.now(s(P, t, 1));
  } : d ? (o = (i = new d()).port2, i.port1.onmessage = g, r = s(o.postMessage, o, 1)) : f.addEventListener && "function" == typeof postMessage && !f.importScripts ? (r = function (t) {
    f.postMessage(t + "", "*");
  }, f.addEventListener("message", g, !1)) : r = "onreadystatechange" in l("script") ? function (t) {
    a.appendChild(l("script")).onreadystatechange = function () {
      a.removeChild(this || _global), P.call(t);
    };
  } : function (t) {
    setTimeout(s(P, t, 1), 0);
  }), t.exports = {
    set: h,
    clear: p
  };
}, function (t, e, n) {
  var r = n(2),
      i = n(86).set,
      o = r.MutationObserver || r.WebKitMutationObserver,
      s = r.process,
      u = r.Promise,
      a = "process" == n(19)(s);

  t.exports = function () {
    var t,
        e,
        n,
        l = function () {
      var r, i;

      for (a && (r = s.domain) && r.exit(); t;) {
        i = t.fn, t = t.next;

        try {
          i();
        } catch (r) {
          throw t ? n() : e = void 0, r;
        }
      }

      e = void 0, r && r.enter();
    };

    if (a) n = function () {
      s.nextTick(l);
    };else if (o) {
      var f = !0,
          c = document.createTextNode("");
      new o(l).observe(c, {
        characterData: !0
      }), n = function () {
        c.data = f = !f;
      };
    } else if (u && u.resolve) {
      var h = u.resolve();

      n = function () {
        h.then(l);
      };
    } else n = function () {
      i.call(r, l);
    };
    return function (r) {
      var i = {
        fn: r,
        next: void 0
      };
      e && (e.next = i), t || (t = i, n()), e = i;
    };
  };
}, function (t, e, n) {
  "use strict";

  var r = n(10);

  t.exports.f = function (t) {
    return new function (t) {
      var e, n;
      (this || _global).promise = new t(function (t, r) {
        if (void 0 !== e || void 0 !== n) throw TypeError("Bad Promise constructor");
        e = t, n = r;
      }), (this || _global).resolve = r(e), (this || _global).reject = r(n);
    }(t);
  };
}, function (t, e, n) {
  "use strict";

  var r = n(2),
      i = n(6),
      o = n(34),
      s = n(60),
      u = n(12),
      a = n(41),
      l = n(3),
      f = n(39),
      c = n(24),
      h = n(8),
      p = n(116),
      d = n(37).f,
      v = n(7).f,
      y = n(84),
      m = n(42),
      P = "prototype",
      g = "Wrong index!",
      _ = r.ArrayBuffer,
      x = r.DataView,
      I = r.Math,
      C = r.RangeError,
      E = r.Infinity,
      L = _,
      S = I.abs,
      b = I.pow,
      O = I.floor,
      T = I.log,
      N = I.LN2,
      w = i ? "_b" : "buffer",
      A = i ? "_l" : "byteLength",
      X = i ? "_o" : "byteOffset";

  function Y(t, e, n) {
    var r,
        i,
        o,
        s = Array(n),
        u = 8 * n - e - 1,
        a = (1 << u) - 1,
        l = a >> 1,
        f = 23 === e ? b(2, -24) - b(2, -77) : 0,
        c = 0,
        h = t < 0 || 0 === t && 1 / t < 0 ? 1 : 0;

    for ((t = S(t)) != t || t === E ? (i = t != t ? 1 : 0, r = a) : (r = O(T(t) / N), t * (o = b(2, -r)) < 1 && (r--, o *= 2), (t += r + l >= 1 ? f / o : f * b(2, 1 - l)) * o >= 2 && (r++, o /= 2), r + l >= a ? (i = 0, r = a) : r + l >= 1 ? (i = (t * o - 1) * b(2, e), r += l) : (i = t * b(2, l - 1) * b(2, e), r = 0)); e >= 8; s[c++] = 255 & i, i /= 256, e -= 8);

    for (r = r << e | i, u += e; u > 0; s[c++] = 255 & r, r /= 256, u -= 8);

    return s[--c] |= 128 * h, s;
  }

  function D(t, e, n) {
    var r,
        i = 8 * n - e - 1,
        o = (1 << i) - 1,
        s = o >> 1,
        u = i - 7,
        a = n - 1,
        l = t[a--],
        f = 127 & l;

    for (l >>= 7; u > 0; f = 256 * f + t[a], a--, u -= 8);

    for (r = f & (1 << -u) - 1, f >>= -u, u += e; u > 0; r = 256 * r + t[a], a--, u -= 8);

    if (0 === f) f = 1 - s;else {
      if (f === o) return r ? NaN : l ? -E : E;
      r += b(2, e), f -= s;
    }
    return (l ? -1 : 1) * r * b(2, f - e);
  }

  function B(t) {
    return t[3] << 24 | t[2] << 16 | t[1] << 8 | t[0];
  }

  function M(t) {
    return [255 & t];
  }

  function R(t) {
    return [255 & t, t >> 8 & 255];
  }

  function F(t) {
    return [255 & t, t >> 8 & 255, t >> 16 & 255, t >> 24 & 255];
  }

  function W(t) {
    return Y(t, 52, 8);
  }

  function k(t) {
    return Y(t, 23, 4);
  }

  function q(t, e, n) {
    v(t[P], e, {
      get: function () {
        return (this || _global)[n];
      }
    });
  }

  function j(t, e, n, r) {
    var i = p(+n);
    if (i + e > t[A]) throw C(g);
    var o = t[w]._b,
        s = i + t[X],
        u = o.slice(s, s + e);
    return r ? u : u.reverse();
  }

  function U(t, e, n, r, i, o) {
    var s = p(+n);
    if (s + e > t[A]) throw C(g);

    for (var u = t[w]._b, a = s + t[X], l = r(+i), f = 0; f < e; f++) u[a + f] = l[o ? f : e - f - 1];
  }

  if (s.ABV) {
    if (!l(function () {
      _(1);
    }) || !l(function () {
      new _(-1);
    }) || l(function () {
      return new _(), new _(1.5), new _(NaN), "ArrayBuffer" != _.name;
    })) {
      for (var G, z = (_ = function (t) {
        return f(this || _global, _), new L(p(t));
      })[P] = L[P], H = d(L), J = 0; H.length > J;) (G = H[J++]) in _ || u(_, G, L[G]);

      o || (z.constructor = _);
    }

    var V = new x(new _(2)),
        Z = x[P].setInt8;
    V.setInt8(0, 2147483648), V.setInt8(1, 2147483649), !V.getInt8(0) && V.getInt8(1) || a(x[P], {
      setInt8: function (t, e) {
        Z.call(this || _global, t, e << 24 >> 24);
      },
      setUint8: function (t, e) {
        Z.call(this || _global, t, e << 24 >> 24);
      }
    }, !0);
  } else _ = function (t) {
    f(this || _global, _, "ArrayBuffer");
    var e = p(t);
    (this || _global)._b = y.call(Array(e), 0), (this || _global)[A] = e;
  }, x = function (t, e, n) {
    f(this || _global, x, "DataView"), f(t, _, "DataView");
    var r = t[A],
        i = c(e);
    if (i < 0 || i > r) throw C("Wrong offset!");
    if (i + (n = void 0 === n ? r - i : h(n)) > r) throw C("Wrong length!");
    (this || _global)[w] = t, (this || _global)[X] = i, (this || _global)[A] = n;
  }, i && (q(_, "byteLength", "_l"), q(x, "buffer", "_b"), q(x, "byteLength", "_l"), q(x, "byteOffset", "_o")), a(x[P], {
    getInt8: function (t) {
      return j(this || _global, 1, t)[0] << 24 >> 24;
    },
    getUint8: function (t) {
      return j(this || _global, 1, t)[0];
    },
    getInt16: function (t) {
      var e = j(this || _global, 2, t, arguments[1]);
      return (e[1] << 8 | e[0]) << 16 >> 16;
    },
    getUint16: function (t) {
      var e = j(this || _global, 2, t, arguments[1]);
      return e[1] << 8 | e[0];
    },
    getInt32: function (t) {
      return B(j(this || _global, 4, t, arguments[1]));
    },
    getUint32: function (t) {
      return B(j(this || _global, 4, t, arguments[1])) >>> 0;
    },
    getFloat32: function (t) {
      return D(j(this || _global, 4, t, arguments[1]), 23, 4);
    },
    getFloat64: function (t) {
      return D(j(this || _global, 8, t, arguments[1]), 52, 8);
    },
    setInt8: function (t, e) {
      U(this || _global, 1, t, M, e);
    },
    setUint8: function (t, e) {
      U(this || _global, 1, t, M, e);
    },
    setInt16: function (t, e) {
      U(this || _global, 2, t, R, e, arguments[2]);
    },
    setUint16: function (t, e) {
      U(this || _global, 2, t, R, e, arguments[2]);
    },
    setInt32: function (t, e) {
      U(this || _global, 4, t, F, e, arguments[2]);
    },
    setUint32: function (t, e) {
      U(this || _global, 4, t, F, e, arguments[2]);
    },
    setFloat32: function (t, e) {
      U(this || _global, 4, t, k, e, arguments[2]);
    },
    setFloat64: function (t, e) {
      U(this || _global, 8, t, W, e, arguments[2]);
    }
  });

  m(_, "ArrayBuffer"), m(x, "DataView"), u(x[P], s.VIEW, !0), e.ArrayBuffer = _, e.DataView = x;
}, function (t, e) {
  var n;

  n = function () {
    return this || _global;
  }();

  try {
    n = n || Function("return this")() || (0, eval)("this");
  } catch (t) {
    "object" == typeof window && (n = window);
  }

  t.exports = n;
}, function (t, e, n) {
  t.exports = !n(6) && !n(3)(function () {
    return 7 != Object.defineProperty(n(64)("div"), "a", {
      get: function () {
        return 7;
      }
    }).a;
  });
}, function (t, e, n) {
  e.f = n(5);
}, function (t, e, n) {
  var r = n(11),
      i = n(14),
      o = n(50)(!1),
      s = n(66)("IE_PROTO");

  t.exports = function (t, e) {
    var n,
        u = i(t),
        a = 0,
        l = [];

    for (n in u) n != s && r(u, n) && l.push(n);

    for (; e.length > a;) r(u, n = e[a++]) && (~o(l, n) || l.push(n));

    return l;
  };
}, function (t, e, n) {
  var r = n(7),
      i = n(1),
      o = n(30);
  t.exports = n(6) ? Object.defineProperties : function (t, e) {
    i(t);

    for (var n, s = o(e), u = s.length, a = 0; u > a;) r.f(t, n = s[a++], e[n]);

    return t;
  };
}, function (t, e, n) {
  var r = n(14),
      i = n(37).f,
      o = {}.toString,
      s = "object" == typeof window && window && Object.getOwnPropertyNames ? Object.getOwnPropertyNames(window) : [];

  t.exports.f = function (t) {
    return s && "[object Window]" == o.call(t) ? function (t) {
      try {
        return i(t);
      } catch (t) {
        return s.slice();
      }
    }(t) : i(r(t));
  };
}, function (t, e, n) {
  "use strict";

  var r = n(30),
      i = n(51),
      o = n(47),
      s = n(9),
      u = n(46),
      a = Object.assign;
  t.exports = !a || n(3)(function () {
    var t = {},
        e = {},
        n = Symbol(),
        r = "abcdefghijklmnopqrst";
    return t[n] = 7, r.split("").forEach(function (t) {
      e[t] = t;
    }), 7 != a({}, t)[n] || Object.keys(a({}, e)).join("") != r;
  }) ? function (t, e) {
    for (var n = s(t), a = arguments.length, l = 1, f = i.f, c = o.f; a > l;) for (var h, p = u(arguments[l++]), d = f ? r(p).concat(f(p)) : r(p), v = d.length, y = 0; v > y;) c.call(p, h = d[y++]) && (n[h] = p[h]);

    return n;
  } : a;
}, function (t, e, n) {
  "use strict";

  var r = n(10),
      i = n(4),
      o = n(53),
      s = [].slice,
      u = {};

  t.exports = Function.bind || function (t) {
    var e = r(this || _global),
        n = s.call(arguments, 1),
        a = function () {
      var r = n.concat(s.call(arguments));
      return (this || _global) instanceof a ? function (t, e, n) {
        if (!(e in u)) {
          for (var r = [], i = 0; i < e; i++) r[i] = "a[" + i + "]";

          u[e] = Function("F,a", "return new F(" + r.join(",") + ")");
        }

        return u[e](t, n);
      }(e, r.length, r) : o(e, r, t);
    };

    return i(e.prototype) && (a.prototype = e.prototype), a;
  };
}, function (t, e, n) {
  var r = n(2).parseInt,
      i = n(43).trim,
      o = n(70),
      s = /^[-+]?0[xX]/;
  t.exports = 8 !== r(o + "08") || 22 !== r(o + "0x16") ? function (t, e) {
    var n = i(String(t), 3);
    return r(n, e >>> 0 || (s.test(n) ? 16 : 10));
  } : r;
}, function (t, e, n) {
  var r = n(2).parseFloat,
      i = n(43).trim;
  t.exports = 1 / r(n(70) + "-0") != -1 / 0 ? function (t) {
    var e = i(String(t), 3),
        n = r(e);
    return 0 === n && "-" == e.charAt(0) ? -0 : n;
  } : r;
}, function (t, e, n) {
  var r = n(19);

  t.exports = function (t, e) {
    if ("number" != typeof t && "Number" != r(t)) throw TypeError(e);
    return +t;
  };
}, function (t, e, n) {
  var r = n(4),
      i = Math.floor;

  t.exports = function (t) {
    return !r(t) && isFinite(t) && i(t) === t;
  };
}, function (t, e) {
  t.exports = Math.log1p || function (t) {
    return (t = +t) > -1e-8 && t < 1e-8 ? t - t * t / 2 : Math.log(1 + t);
  };
}, function (t, e, n) {
  var r = n(73),
      i = Math.pow,
      o = i(2, -52),
      s = i(2, -23),
      u = i(2, 127) * (2 - s),
      a = i(2, -126);

  t.exports = Math.fround || function (t) {
    var e,
        n,
        i = Math.abs(t),
        l = r(t);
    return i < a ? l * (i / a / s + 1 / o - 1 / o) * a * s : (n = (e = (1 + s / o) * i) - (e - i)) > u || n != n ? l * (1 / 0) : l * n;
  };
}, function (t, e, n) {
  var r = n(1);

  t.exports = function (t, e, n, i) {
    try {
      return i ? e(r(n)[0], n[1]) : e(n);
    } catch (e) {
      var o = t.return;
      throw void 0 !== o && r(o.call(t)), e;
    }
  };
}, function (t, e, n) {
  var r = n(10),
      i = n(9),
      o = n(46),
      s = n(8);

  t.exports = function (t, e, n, u, a) {
    r(e);
    var l = i(t),
        f = o(l),
        c = s(l.length),
        h = a ? c - 1 : 0,
        p = a ? -1 : 1;
    if (n < 2) for (;;) {
      if (h in f) {
        u = f[h], h += p;
        break;
      }

      if (h += p, a ? h < 0 : c <= h) throw TypeError("Reduce of empty array with no initial value");
    }

    for (; a ? h >= 0 : c > h; h += p) h in f && (u = e(u, f[h], h, l));

    return u;
  };
}, function (t, e, n) {
  "use strict";

  var r = n(9),
      i = n(35),
      o = n(8);

  t.exports = [].copyWithin || function (t, e) {
    var n = r(this || _global),
        s = o(n.length),
        u = i(t, s),
        a = i(e, s),
        l = arguments.length > 2 ? arguments[2] : void 0,
        f = Math.min((void 0 === l ? s : i(l, s)) - a, s - u),
        c = 1;

    for (a < u && u < a + f && (c = -1, a += f - 1, u += f - 1); f-- > 0;) a in n ? n[u] = n[a] : delete n[u], u += c, a += c;

    return n;
  };
}, function (t, e) {
  t.exports = function (t, e) {
    return {
      value: e,
      done: !!t
    };
  };
}, function (t, e, n) {
  n(6) && "g" != /./g.flags && n(7).f(RegExp.prototype, "flags", {
    configurable: !0,
    get: n(56)
  });
}, function (t, e) {
  t.exports = function (t) {
    try {
      return {
        e: !1,
        v: t()
      };
    } catch (t) {
      return {
        e: !0,
        v: t
      };
    }
  };
}, function (t, e, n) {
  var r = n(88);

  t.exports = function (t, e) {
    var n = r.f(t);
    return (0, n.resolve)(e), n.promise;
  };
}, function (t, e, n) {
  "use strict";

  var r = n(112),
      i = n(45);
  t.exports = n(59)("Map", function (t) {
    return function () {
      return t(this || _global, arguments.length > 0 ? arguments[0] : void 0);
    };
  }, {
    get: function (t) {
      var e = r.getEntry(i(this || _global, "Map"), t);
      return e && e.v;
    },
    set: function (t, e) {
      return r.def(i(this || _global, "Map"), 0 === t ? 0 : t, e);
    }
  }, r, !0);
}, function (t, e, n) {
  "use strict";

  var r = n(7).f,
      i = n(36),
      o = n(41),
      s = n(18),
      u = n(39),
      a = n(40),
      l = n(76),
      f = n(107),
      c = n(38),
      h = n(6),
      p = n(29).fastKey,
      d = n(45),
      v = h ? "_s" : "size",
      y = function (t, e) {
    var n,
        r = p(e);
    if ("F" !== r) return t._i[r];

    for (n = t._f; n; n = n.n) if (n.k == e) return n;
  };

  t.exports = {
    getConstructor: function (t, e, n, l) {
      var f = t(function (t, r) {
        u(t, f, e, "_i"), t._t = e, t._i = i(null), t._f = void 0, t._l = void 0, t[v] = 0, void 0 != r && a(r, n, t[l], t);
      });
      return o(f.prototype, {
        clear: function () {
          for (var t = d(this || _global, e), n = t._i, r = t._f; r; r = r.n) r.r = !0, r.p && (r.p = r.p.n = void 0), delete n[r.i];

          t._f = t._l = void 0, t[v] = 0;
        },
        delete: function (t) {
          var n = d(this || _global, e),
              r = y(n, t);

          if (r) {
            var i = r.n,
                o = r.p;
            delete n._i[r.i], r.r = !0, o && (o.n = i), i && (i.p = o), n._f == r && (n._f = i), n._l == r && (n._l = o), n[v]--;
          }

          return !!r;
        },
        forEach: function (t) {
          d(this || _global, e);

          for (var n, r = s(t, arguments.length > 1 ? arguments[1] : void 0, 3); n = n ? n.n : (this || _global)._f;) for (r(n.v, n.k, this || _global); n && n.r;) n = n.p;
        },
        has: function (t) {
          return !!y(d(this || _global, e), t);
        }
      }), h && r(f.prototype, "size", {
        get: function () {
          return d(this || _global, e)[v];
        }
      }), f;
    },
    def: function (t, e, n) {
      var r,
          i,
          o = y(t, e);
      return o ? o.v = n : (t._l = o = {
        i: i = p(e, !0),
        k: e,
        v: n,
        p: r = t._l,
        n: void 0,
        r: !1
      }, t._f || (t._f = o), r && (r.n = o), t[v]++, "F" !== i && (t._i[i] = o)), t;
    },
    getEntry: y,
    setStrong: function (t, e, n) {
      l(t, e, function (t, n) {
        (this || _global)._t = d(t, e), (this || _global)._k = n, (this || _global)._l = void 0;
      }, function () {
        for (var t = (this || _global)._k, e = (this || _global)._l; e && e.r;) e = e.p;

        return (this || _global)._t && ((this || _global)._l = e = e ? e.n : (this || _global)._t._f) ? f(0, "keys" == t ? e.k : "values" == t ? e.v : [e.k, e.v]) : ((this || _global)._t = void 0, f(1));
      }, n ? "entries" : "values", !n, !0), c(e);
    }
  };
}, function (t, e, n) {
  "use strict";

  var r = n(112),
      i = n(45);
  t.exports = n(59)("Set", function (t) {
    return function () {
      return t(this || _global, arguments.length > 0 ? arguments[0] : void 0);
    };
  }, {
    add: function (t) {
      return r.def(i(this || _global, "Set"), t = 0 === t ? 0 : t, t);
    }
  }, r);
}, function (t, e, n) {
  "use strict";

  var r,
      i = n(26)(0),
      o = n(13),
      s = n(29),
      u = n(96),
      a = n(115),
      l = n(4),
      f = n(3),
      c = n(45),
      h = s.getWeak,
      p = Object.isExtensible,
      d = a.ufstore,
      v = {},
      y = function (t) {
    return function () {
      return t(this || _global, arguments.length > 0 ? arguments[0] : void 0);
    };
  },
      m = {
    get: function (t) {
      if (l(t)) {
        var e = h(t);
        return !0 === e ? d(c(this || _global, "WeakMap")).get(t) : e ? e[(this || _global)._i] : void 0;
      }
    },
    set: function (t, e) {
      return a.def(c(this || _global, "WeakMap"), t, e);
    }
  },
      P = t.exports = n(59)("WeakMap", y, m, a, !0, !0);

  f(function () {
    return 7 != new P().set((Object.freeze || Object)(v), 7).get(v);
  }) && (u((r = a.getConstructor(y, "WeakMap")).prototype, m), s.NEED = !0, i(["delete", "has", "get", "set"], function (t) {
    var e = P.prototype,
        n = e[t];
    o(e, t, function (e, i) {
      if (l(e) && !p(e)) {
        (this || _global)._f || ((this || _global)._f = new r());

        var o = (this || _global)._f[t](e, i);

        return "set" == t ? this || _global : o;
      }

      return n.call(this || _global, e, i);
    });
  }));
}, function (t, e, n) {
  "use strict";

  var r = n(41),
      i = n(29).getWeak,
      o = n(1),
      s = n(4),
      u = n(39),
      a = n(40),
      l = n(26),
      f = n(11),
      c = n(45),
      h = l(5),
      p = l(6),
      d = 0,
      v = function (t) {
    return t._l || (t._l = new y());
  },
      y = function () {
    (this || _global).a = [];
  },
      m = function (t, e) {
    return h(t.a, function (t) {
      return t[0] === e;
    });
  };

  y.prototype = {
    get: function (t) {
      var e = m(this || _global, t);
      if (e) return e[1];
    },
    has: function (t) {
      return !!m(this || _global, t);
    },
    set: function (t, e) {
      var n = m(this || _global, t);
      n ? n[1] = e : (this || _global).a.push([t, e]);
    },
    delete: function (t) {
      var e = p((this || _global).a, function (e) {
        return e[0] === t;
      });
      return ~e && (this || _global).a.splice(e, 1), !!~e;
    }
  }, t.exports = {
    getConstructor: function (t, e, n, o) {
      var l = t(function (t, r) {
        u(t, l, e, "_i"), t._t = e, t._i = d++, t._l = void 0, void 0 != r && a(r, n, t[o], t);
      });
      return r(l.prototype, {
        delete: function (t) {
          if (!s(t)) return !1;
          var n = i(t);
          return !0 === n ? v(c(this || _global, e)).delete(t) : n && f(n, (this || _global)._i) && delete n[(this || _global)._i];
        },
        has: function (t) {
          if (!s(t)) return !1;
          var n = i(t);
          return !0 === n ? v(c(this || _global, e)).has(t) : n && f(n, (this || _global)._i);
        }
      }), l;
    },
    def: function (t, e, n) {
      var r = i(o(e), !0);
      return !0 === r ? v(t).set(e, n) : r[t._i] = n, t;
    },
    ufstore: v
  };
}, function (t, e, n) {
  var r = n(24),
      i = n(8);

  t.exports = function (t) {
    if (void 0 === t) return 0;
    var e = r(t),
        n = i(e);
    if (e !== n) throw RangeError("Wrong length!");
    return n;
  };
}, function (t, e, n) {
  var r = n(37),
      i = n(51),
      o = n(1),
      s = n(2).Reflect;

  t.exports = s && s.ownKeys || function (t) {
    var e = r.f(o(t)),
        n = i.f;
    return n ? e.concat(n(t)) : e;
  };
}, function (t, e, n) {
  "use strict";

  var r = n(52),
      i = n(4),
      o = n(8),
      s = n(18),
      u = n(5)("isConcatSpreadable");

  t.exports = function t(e, n, a, l, f, c, h, p) {
    for (var d, v, y = f, m = 0, P = !!h && s(h, p, 3); m < l;) {
      if (m in a) {
        if (d = P ? P(a[m], m, n) : a[m], v = !1, i(d) && (v = void 0 !== (v = d[u]) ? !!v : r(d)), v && c > 0) y = t(e, n, d, o(d.length), y, c - 1) - 1;else {
          if (y >= 9007199254740991) throw TypeError();
          e[y] = d;
        }
        y++;
      }

      m++;
    }

    return y;
  };
}, function (t, e, n) {
  var r = n(8),
      i = n(72),
      o = n(23);

  t.exports = function (t, e, n, s) {
    var u = String(o(t)),
        a = u.length,
        l = void 0 === n ? " " : String(n),
        f = r(e);
    if (f <= a || "" == l) return u;
    var c = f - a,
        h = i.call(l, Math.ceil(c / l.length));
    return h.length > c && (h = h.slice(0, c)), s ? h + u : u + h;
  };
}, function (t, e, n) {
  var r = n(30),
      i = n(14),
      o = n(47).f;

  t.exports = function (t) {
    return function (e) {
      for (var n, s = i(e), u = r(s), a = u.length, l = 0, f = []; a > l;) o.call(s, n = u[l++]) && f.push(t ? [n, s[n]] : s[n]);

      return f;
    };
  };
}, function (t, e, n) {
  var r = n(48),
      i = n(122);

  t.exports = function (t) {
    return function () {
      if (r(this || _global) != t) throw TypeError(t + "#toJSON isn't generic");
      return i(this || _global);
    };
  };
}, function (t, e, n) {
  var r = n(40);

  t.exports = function (t, e) {
    var n = [];
    return r(t, !1, n.push, n, e), n;
  };
}, function (t, e) {
  t.exports = Math.scale || function (t, e, n, r, i) {
    return 0 === arguments.length || t != t || e != e || n != n || r != r || i != i ? NaN : t === 1 / 0 || t === -1 / 0 ? t : (t - e) * (i - r) / (n - e) + r;
  };
}, function (t, e) {
  t.exports = L;
}, function (t, e, n) {
  "use strict";

  n.d(e, "a", function () {
    return v;
  }), n.d(e, "b", function () {
    return h;
  }), n.d(e, "c", function () {
    return y;
  });

  const r = (t, e) => e.some(e => t instanceof e);

  let i, o;
  const s = new WeakMap(),
        u = new WeakMap(),
        a = new WeakMap(),
        l = new WeakMap(),
        f = new WeakMap();
  let c = {
    get(t, e, n) {
      if (t instanceof IDBTransaction) {
        if ("done" === e) return u.get(t);
        if ("objectStoreNames" === e) return t.objectStoreNames || a.get(t);
        if ("store" === e) return n.objectStoreNames[1] ? void 0 : n.objectStore(n.objectStoreNames[0]);
      }

      return v(t[e]);
    },

    has: (t, e) => t instanceof IDBTransaction && ("done" === e || "store" === e) || e in t
  };

  function h(t) {
    c = t(c);
  }

  function p(t) {
    return t !== IDBDatabase.prototype.transaction || "objectStoreNames" in IDBTransaction.prototype ? (o || (o = [IDBCursor.prototype.advance, IDBCursor.prototype.continue, IDBCursor.prototype.continuePrimaryKey])).includes(t) ? function (...e) {
      return t.apply(y(this || _global), e), v(s.get(this || _global));
    } : function (...e) {
      return v(t.apply(y(this || _global), e));
    } : function (e, ...n) {
      const r = t.call(y(this || _global), e, ...n);
      return a.set(r, e.sort ? e.sort() : [e]), v(r);
    };
  }

  function d(t) {
    return "function" == typeof t ? p(t) : (t instanceof IDBTransaction && function (t) {
      if (u.has(t)) return;
      const e = new Promise((e, n) => {
        const r = () => {
          t.removeEventListener("complete", i), t.removeEventListener("error", o), t.removeEventListener("abort", o);
        },
              i = () => {
          e(), r();
        },
              o = () => {
          n(t.error), r();
        };

        t.addEventListener("complete", i), t.addEventListener("error", o), t.addEventListener("abort", o);
      });
      u.set(t, e);
    }(t), r(t, i || (i = [IDBDatabase, IDBObjectStore, IDBIndex, IDBCursor, IDBTransaction])) ? new Proxy(t, c) : t);
  }

  function v(t) {
    if (t instanceof IDBRequest) return function (t) {
      const e = new Promise((e, n) => {
        const r = () => {
          t.removeEventListener("success", i), t.removeEventListener("error", o);
        },
              i = () => {
          e(v(t.result)), r();
        },
              o = () => {
          n(t.error), r();
        };

        t.addEventListener("success", i), t.addEventListener("error", o);
      });
      return e.then(e => {
        e instanceof IDBCursor && s.set(e, t);
      }).catch(() => {}), f.set(e, t), e;
    }(t);
    if (l.has(t)) return l.get(t);
    const e = d(t);
    return e !== t && (l.set(t, e), f.set(e, t)), e;
  }

  const y = t => f.get(t);
}, function (t, e, n) {
  n(127), t.exports = n(332);
}, function (t, e, n) {
  "use strict";

  (function (t) {
    if (n(128), n(328), n(329), t._babelPolyfill) throw new Error("only one instance of babel-polyfill is allowed");
    t._babelPolyfill = !0;
    var e = "defineProperty";

    function r(t, n, r) {
      t[n] || Object[e](t, n, {
        writable: !0,
        configurable: !0,
        value: r
      });
    }

    r(String.prototype, "padLeft", "".padStart), r(String.prototype, "padRight", "".padEnd), "pop,reverse,shift,keys,values,entries,indexOf,every,some,forEach,map,filter,find,findIndex,includes,join,slice,concat,push,splice,unshift,sort,lastIndexOf,reduce,reduceRight,copyWithin,fill".split(",").forEach(function (t) {
      [][t] && r(Array, t, Function.call.bind([][t]));
    });
  }).call(e, n(90));
}, function (t, e, n) {
  n(129), n(132), n(133), n(134), n(135), n(136), n(137), n(138), n(139), n(140), n(141), n(142), n(143), n(144), n(145), n(146), n(148), n(149), n(150), n(151), n(152), n(153), n(154), n(155), n(156), n(157), n(158), n(159), n(160), n(161), n(162), n(163), n(164), n(165), n(166), n(167), n(168), n(169), n(170), n(171), n(172), n(173), n(174), n(175), n(176), n(177), n(178), n(179), n(180), n(181), n(182), n(183), n(184), n(185), n(186), n(187), n(188), n(189), n(190), n(191), n(192), n(193), n(194), n(195), n(196), n(197), n(198), n(199), n(200), n(201), n(202), n(203), n(204), n(205), n(206), n(207), n(208), n(210), n(211), n(213), n(214), n(215), n(216), n(217), n(218), n(219), n(221), n(222), n(223), n(224), n(225), n(226), n(227), n(228), n(229), n(230), n(231), n(232), n(233), n(85), n(234), n(235), n(108), n(236), n(237), n(238), n(239), n(240), n(111), n(113), n(114), n(241), n(242), n(243), n(244), n(245), n(246), n(247), n(248), n(249), n(250), n(251), n(252), n(253), n(254), n(255), n(256), n(257), n(258), n(259), n(260), n(261), n(262), n(263), n(264), n(265), n(266), n(267), n(268), n(269), n(270), n(271), n(272), n(273), n(274), n(275), n(276), n(277), n(278), n(279), n(280), n(281), n(282), n(283), n(284), n(285), n(286), n(287), n(288), n(289), n(290), n(291), n(292), n(293), n(294), n(295), n(296), n(297), n(298), n(299), n(300), n(301), n(302), n(303), n(304), n(305), n(306), n(307), n(308), n(309), n(310), n(311), n(312), n(313), n(314), n(315), n(316), n(317), n(318), n(319), n(320), n(321), n(322), n(323), n(326), n(327), t.exports = n(21);
}, function (t, e, n) {
  "use strict";

  var r = n(2),
      i = n(11),
      o = n(6),
      s = n(0),
      u = n(13),
      a = n(29).KEY,
      l = n(3),
      f = n(49),
      c = n(42),
      h = n(33),
      p = n(5),
      d = n(92),
      v = n(65),
      y = n(130),
      m = n(131),
      P = n(52),
      g = n(1),
      _ = n(14),
      x = n(22),
      I = n(32),
      C = n(36),
      E = n(95),
      L = n(16),
      S = n(7),
      b = n(30),
      O = L.f,
      T = S.f,
      N = E.f,
      w = r.Symbol,
      A = r.JSON,
      X = A && A.stringify,
      Y = p("_hidden"),
      D = p("toPrimitive"),
      B = {}.propertyIsEnumerable,
      M = f("symbol-registry"),
      R = f("symbols"),
      F = f("op-symbols"),
      W = Object.prototype,
      k = "function" == typeof w,
      q = r.QObject,
      j = !q || !q.prototype || !q.prototype.findChild,
      U = o && l(function () {
    return 7 != C(T({}, "a", {
      get: function () {
        return T(this || _global, "a", {
          value: 7
        }).a;
      }
    })).a;
  }) ? function (t, e, n) {
    var r = O(W, e);
    r && delete W[e], T(t, e, n), r && t !== W && T(W, e, r);
  } : T,
      G = function (t) {
    var e = R[t] = C(w.prototype);
    return e._k = t, e;
  },
      z = k && "symbol" == typeof w.iterator ? function (t) {
    return "symbol" == typeof t;
  } : function (t) {
    return t instanceof w;
  },
      H = function (t, e, n) {
    return t === W && H(F, e, n), g(t), e = x(e, !0), g(n), i(R, e) ? (n.enumerable ? (i(t, Y) && t[Y][e] && (t[Y][e] = !1), n = C(n, {
      enumerable: I(0, !1)
    })) : (i(t, Y) || T(t, Y, I(1, {})), t[Y][e] = !0), U(t, e, n)) : T(t, e, n);
  },
      J = function (t, e) {
    g(t);

    for (var n, r = m(e = _(e)), i = 0, o = r.length; o > i;) H(t, n = r[i++], e[n]);

    return t;
  },
      V = function (t) {
    var e = B.call(this || _global, t = x(t, !0));
    return !((this || _global) === W && i(R, t) && !i(F, t)) && (!(e || !i(this || _global, t) || !i(R, t) || i(this || _global, Y) && (this || _global)[Y][t]) || e);
  },
      Z = function (t, e) {
    if (t = _(t), e = x(e, !0), t !== W || !i(R, e) || i(F, e)) {
      var n = O(t, e);
      return !n || !i(R, e) || i(t, Y) && t[Y][e] || (n.enumerable = !0), n;
    }
  },
      K = function (t) {
    for (var e, n = N(_(t)), r = [], o = 0; n.length > o;) i(R, e = n[o++]) || e == Y || e == a || r.push(e);

    return r;
  },
      Q = function (t) {
    for (var e, n = t === W, r = N(n ? F : _(t)), o = [], s = 0; r.length > s;) !i(R, e = r[s++]) || n && !i(W, e) || o.push(R[e]);

    return o;
  };

  k || (u((w = function () {
    if ((this || _global) instanceof w) throw TypeError("Symbol is not a constructor!");

    var t = h(arguments.length > 0 ? arguments[0] : void 0),
        e = function (n) {
      (this || _global) === W && e.call(F, n), i(this || _global, Y) && i((this || _global)[Y], t) && ((this || _global)[Y][t] = !1), U(this || _global, t, I(1, n));
    };

    return o && j && U(W, t, {
      configurable: !0,
      set: e
    }), G(t);
  }).prototype, "toString", function () {
    return (this || _global)._k;
  }), L.f = Z, S.f = H, n(37).f = E.f = K, n(47).f = V, n(51).f = Q, o && !n(34) && u(W, "propertyIsEnumerable", V, !0), d.f = function (t) {
    return G(p(t));
  }), s(s.G + s.W + s.F * !k, {
    Symbol: w
  });

  for (var $ = "hasInstance,isConcatSpreadable,iterator,match,replace,search,species,split,toPrimitive,toStringTag,unscopables".split(","), tt = 0; $.length > tt;) p($[tt++]);

  for (var et = b(p.store), nt = 0; et.length > nt;) v(et[nt++]);

  s(s.S + s.F * !k, "Symbol", {
    for: function (t) {
      return i(M, t += "") ? M[t] : M[t] = w(t);
    },
    keyFor: function (t) {
      if (z(t)) return y(M, t);
      throw TypeError(t + " is not a symbol!");
    },
    useSetter: function () {
      j = !0;
    },
    useSimple: function () {
      j = !1;
    }
  }), s(s.S + s.F * !k, "Object", {
    create: function (t, e) {
      return void 0 === e ? C(t) : J(C(t), e);
    },
    defineProperty: H,
    defineProperties: J,
    getOwnPropertyDescriptor: Z,
    getOwnPropertyNames: K,
    getOwnPropertySymbols: Q
  }), A && s(s.S + s.F * (!k || l(function () {
    var t = w();
    return "[null]" != X([t]) || "{}" != X({
      a: t
    }) || "{}" != X(Object(t));
  })), "JSON", {
    stringify: function (t) {
      if (void 0 !== t && !z(t)) {
        for (var e, n, r = [t], i = 1; arguments.length > i;) r.push(arguments[i++]);

        return "function" == typeof (e = r[1]) && (n = e), !n && P(e) || (e = function (t, e) {
          if (n && (e = n.call(this || _global, t, e)), !z(e)) return e;
        }), r[1] = e, X.apply(A, r);
      }
    }
  }), w.prototype[D] || n(12)(w.prototype, D, w.prototype.valueOf), c(w, "Symbol"), c(Math, "Math", !0), c(r.JSON, "JSON", !0);
}, function (t, e, n) {
  var r = n(30),
      i = n(14);

  t.exports = function (t, e) {
    for (var n, o = i(t), s = r(o), u = s.length, a = 0; u > a;) if (o[n = s[a++]] === e) return n;
  };
}, function (t, e, n) {
  var r = n(30),
      i = n(51),
      o = n(47);

  t.exports = function (t) {
    var e = r(t),
        n = i.f;
    if (n) for (var s, u = n(t), a = o.f, l = 0; u.length > l;) a.call(t, s = u[l++]) && e.push(s);
    return e;
  };
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Object", {
    create: n(36)
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S + r.F * !n(6), "Object", {
    defineProperty: n(7).f
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S + r.F * !n(6), "Object", {
    defineProperties: n(94)
  });
}, function (t, e, n) {
  var r = n(14),
      i = n(16).f;
  n(25)("getOwnPropertyDescriptor", function () {
    return function (t, e) {
      return i(r(t), e);
    };
  });
}, function (t, e, n) {
  var r = n(9),
      i = n(17);
  n(25)("getPrototypeOf", function () {
    return function (t) {
      return i(r(t));
    };
  });
}, function (t, e, n) {
  var r = n(9),
      i = n(30);
  n(25)("keys", function () {
    return function (t) {
      return i(r(t));
    };
  });
}, function (t, e, n) {
  n(25)("getOwnPropertyNames", function () {
    return n(95).f;
  });
}, function (t, e, n) {
  var r = n(4),
      i = n(29).onFreeze;
  n(25)("freeze", function (t) {
    return function (e) {
      return t && r(e) ? t(i(e)) : e;
    };
  });
}, function (t, e, n) {
  var r = n(4),
      i = n(29).onFreeze;
  n(25)("seal", function (t) {
    return function (e) {
      return t && r(e) ? t(i(e)) : e;
    };
  });
}, function (t, e, n) {
  var r = n(4),
      i = n(29).onFreeze;
  n(25)("preventExtensions", function (t) {
    return function (e) {
      return t && r(e) ? t(i(e)) : e;
    };
  });
}, function (t, e, n) {
  var r = n(4);
  n(25)("isFrozen", function (t) {
    return function (e) {
      return !r(e) || !!t && t(e);
    };
  });
}, function (t, e, n) {
  var r = n(4);
  n(25)("isSealed", function (t) {
    return function (e) {
      return !r(e) || !!t && t(e);
    };
  });
}, function (t, e, n) {
  var r = n(4);
  n(25)("isExtensible", function (t) {
    return function (e) {
      return !!r(e) && (!t || t(e));
    };
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S + r.F, "Object", {
    assign: n(96)
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Object", {
    is: n(147)
  });
}, function (t, e) {
  t.exports = Object.is || function (t, e) {
    return t === e ? 0 !== t || 1 / t == 1 / e : t != t && e != e;
  };
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Object", {
    setPrototypeOf: n(69).set
  });
}, function (t, e, n) {
  "use strict";

  var r = n(48),
      i = {};
  i[n(5)("toStringTag")] = "z", i + "" != "[object z]" && n(13)(Object.prototype, "toString", function () {
    return "[object " + r(this || _global) + "]";
  }, !0);
}, function (t, e, n) {
  var r = n(0);
  r(r.P, "Function", {
    bind: n(97)
  });
}, function (t, e, n) {
  var r = n(7).f,
      i = Function.prototype,
      o = /^\s*function ([^ (]*)/;
  "name" in i || n(6) && r(i, "name", {
    configurable: !0,
    get: function () {
      try {
        return ("" + (this || _global)).match(o)[1];
      } catch (t) {
        return "";
      }
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(4),
      i = n(17),
      o = n(5)("hasInstance"),
      s = Function.prototype;
  o in s || n(7).f(s, o, {
    value: function (t) {
      if ("function" != typeof (this || _global) || !r(t)) return !1;
      if (!r((this || _global).prototype)) return t instanceof (this || _global);

      for (; t = i(t);) if ((this || _global).prototype === t) return !0;

      return !1;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(98);
  r(r.G + r.F * (parseInt != i), {
    parseInt: i
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(99);
  r(r.G + r.F * (parseFloat != i), {
    parseFloat: i
  });
}, function (t, e, n) {
  "use strict";

  var r = n(2),
      i = n(11),
      o = n(19),
      s = n(71),
      u = n(22),
      a = n(3),
      l = n(37).f,
      f = n(16).f,
      c = n(7).f,
      h = n(43).trim,
      p = r.Number,
      d = p,
      v = p.prototype,
      y = "Number" == o(n(36)(v)),
      m = ("trim" in String.prototype),
      P = function (t) {
    var e = u(t, !1);

    if ("string" == typeof e && e.length > 2) {
      var n,
          r,
          i,
          o = (e = m ? e.trim() : h(e, 3)).charCodeAt(0);

      if (43 === o || 45 === o) {
        if (88 === (n = e.charCodeAt(2)) || 120 === n) return NaN;
      } else if (48 === o) {
        switch (e.charCodeAt(1)) {
          case 66:
          case 98:
            r = 2, i = 49;
            break;

          case 79:
          case 111:
            r = 8, i = 55;
            break;

          default:
            return +e;
        }

        for (var s, a = e.slice(2), l = 0, f = a.length; l < f; l++) if ((s = a.charCodeAt(l)) < 48 || s > i) return NaN;

        return parseInt(a, r);
      }
    }

    return +e;
  };

  if (!p(" 0o1") || !p("0b1") || p("+0x1")) {
    p = function (t) {
      var e = arguments.length < 1 ? 0 : t,
          n = this || _global;
      return n instanceof p && (y ? a(function () {
        v.valueOf.call(n);
      }) : "Number" != o(n)) ? s(new d(P(e)), n, p) : P(e);
    };

    for (var g, _ = n(6) ? l(d) : "MAX_VALUE,MIN_VALUE,NaN,NEGATIVE_INFINITY,POSITIVE_INFINITY,EPSILON,isFinite,isInteger,isNaN,isSafeInteger,MAX_SAFE_INTEGER,MIN_SAFE_INTEGER,parseFloat,parseInt,isInteger".split(","), x = 0; _.length > x; x++) i(d, g = _[x]) && !i(p, g) && c(p, g, f(d, g));

    p.prototype = v, v.constructor = p, n(13)(r, "Number", p);
  }
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(24),
      o = n(100),
      s = n(72),
      u = 1 .toFixed,
      a = Math.floor,
      l = [0, 0, 0, 0, 0, 0],
      f = "Number.toFixed: incorrect invocation!",
      c = function (t, e) {
    for (var n = -1, r = e; ++n < 6;) r += t * l[n], l[n] = r % 10000000, r = a(r / 10000000);
  },
      h = function (t) {
    for (var e = 6, n = 0; --e >= 0;) n += l[e], l[e] = a(n / t), n = n % t * 10000000;
  },
      p = function () {
    for (var t = 6, e = ""; --t >= 0;) if ("" !== e || 0 === t || 0 !== l[t]) {
      var n = String(l[t]);
      e = "" === e ? n : e + s.call("0", 7 - n.length) + n;
    }

    return e;
  },
      d = function (t, e, n) {
    return 0 === e ? n : e % 2 == 1 ? d(t, e - 1, n * t) : d(t * t, e / 2, n);
  };

  r(r.P + r.F * (!!u && ("0.000" !== 0.00008.toFixed(3) || "1" !== 0.9.toFixed(0) || "1.25" !== 1.255.toFixed(2) || "1000000000000000128" !== 1000000000000000100 .toFixed(0)) || !n(3)(function () {
    u.call({});
  })), "Number", {
    toFixed: function (t) {
      var e,
          n,
          r,
          u,
          a = o(this || _global, f),
          l = i(t),
          v = "",
          y = "0";
      if (l < 0 || l > 20) throw RangeError(f);
      if (a != a) return "NaN";
      if (a <= -1e+21 || a >= 1e+21) return String(a);
      if (a < 0 && (v = "-", a = -a), a > 1e-21) if (n = (e = function (t) {
        for (var e = 0, n = t; n >= 4096;) e += 12, n /= 4096;

        for (; n >= 2;) e += 1, n /= 2;

        return e;
      }(a * d(2, 69, 1)) - 69) < 0 ? a * d(2, -e, 1) : a / d(2, e, 1), n *= 4503599627370496, (e = 52 - e) > 0) {
        for (c(0, n), r = l; r >= 7;) c(10000000, 0), r -= 7;

        for (c(d(10, r, 1), 0), r = e - 1; r >= 23;) h(1 << 23), r -= 23;

        h(1 << r), c(1, 1), h(2), y = p();
      } else c(0, n), c(1 << -e, 0), y = p() + s.call("0", l);
      return y = l > 0 ? v + ((u = y.length) <= l ? "0." + s.call("0", l - u) + y : y.slice(0, u - l) + "." + y.slice(u - l)) : v + y;
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(3),
      o = n(100),
      s = 1 .toPrecision;
  r(r.P + r.F * (i(function () {
    return "1" !== s.call(1, void 0);
  }) || !i(function () {
    s.call({});
  })), "Number", {
    toPrecision: function (t) {
      var e = o(this || _global, "Number#toPrecision: incorrect invocation!");
      return void 0 === t ? s.call(e) : s.call(e, t);
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Number", {
    EPSILON: Math.pow(2, -52)
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(2).isFinite;
  r(r.S, "Number", {
    isFinite: function (t) {
      return "number" == typeof t && i(t);
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Number", {
    isInteger: n(101)
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Number", {
    isNaN: function (t) {
      return t != t;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(101),
      o = Math.abs;
  r(r.S, "Number", {
    isSafeInteger: function (t) {
      return i(t) && o(t) <= 9007199254740991;
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Number", {
    MAX_SAFE_INTEGER: 9007199254740991
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Number", {
    MIN_SAFE_INTEGER: -9007199254740991
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(99);
  r(r.S + r.F * (Number.parseFloat != i), "Number", {
    parseFloat: i
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(98);
  r(r.S + r.F * (Number.parseInt != i), "Number", {
    parseInt: i
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(102),
      o = Math.sqrt,
      s = Math.acosh;
  r(r.S + r.F * !(s && 710 == Math.floor(s(Number.MAX_VALUE)) && s(1 / 0) == 1 / 0), "Math", {
    acosh: function (t) {
      return (t = +t) < 1 ? NaN : t > 94906265.62425156 ? Math.log(t) + Math.LN2 : i(t - 1 + o(t - 1) * o(t + 1));
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = Math.asinh;
  r(r.S + r.F * !(i && 1 / i(0) > 0), "Math", {
    asinh: function t(e) {
      return isFinite(e = +e) && 0 != e ? e < 0 ? -t(-e) : Math.log(e + Math.sqrt(e * e + 1)) : e;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = Math.atanh;
  r(r.S + r.F * !(i && 1 / i(-0) < 0), "Math", {
    atanh: function (t) {
      return 0 == (t = +t) ? t : Math.log((1 + t) / (1 - t)) / 2;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(73);
  r(r.S, "Math", {
    cbrt: function (t) {
      return i(t = +t) * Math.pow(Math.abs(t), 1 / 3);
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    clz32: function (t) {
      return (t >>>= 0) ? 31 - Math.floor(Math.log(t + 0.5) * Math.LOG2E) : 32;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = Math.exp;
  r(r.S, "Math", {
    cosh: function (t) {
      return (i(t = +t) + i(-t)) / 2;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(74);
  r(r.S + r.F * (i != Math.expm1), "Math", {
    expm1: i
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    fround: n(103)
  });
}, function (t, e, n) {
  var r = n(0),
      i = Math.abs;
  r(r.S, "Math", {
    hypot: function (t, e) {
      for (var n, r, o = 0, s = 0, u = arguments.length, a = 0; s < u;) a < (n = i(arguments[s++])) ? (o = o * (r = a / n) * r + 1, a = n) : o += n > 0 ? (r = n / a) * r : n;

      return a === 1 / 0 ? 1 / 0 : a * Math.sqrt(o);
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = Math.imul;
  r(r.S + r.F * n(3)(function () {
    return -5 != i(4294967295, 5) || 2 != i.length;
  }), "Math", {
    imul: function (t, e) {
      var n = +t,
          r = +e,
          i = 65535 & n,
          o = 65535 & r;
      return 0 | i * o + ((65535 & n >>> 16) * o + i * (65535 & r >>> 16) << 16 >>> 0);
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    log10: function (t) {
      return Math.log(t) * Math.LOG10E;
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    log1p: n(102)
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    log2: function (t) {
      return Math.log(t) / Math.LN2;
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    sign: n(73)
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(74),
      o = Math.exp;
  r(r.S + r.F * n(3)(function () {
    return -2e-17 != !Math.sinh(-2e-17);
  }), "Math", {
    sinh: function (t) {
      return Math.abs(t = +t) < 1 ? (i(t) - i(-t)) / 2 : (o(t - 1) - o(-t - 1)) * (Math.E / 2);
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(74),
      o = Math.exp;
  r(r.S, "Math", {
    tanh: function (t) {
      var e = i(t = +t),
          n = i(-t);
      return e == 1 / 0 ? 1 : n == 1 / 0 ? -1 : (e - n) / (o(t) + o(-t));
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    trunc: function (t) {
      return (t > 0 ? Math.floor : Math.ceil)(t);
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(35),
      o = String.fromCharCode,
      s = String.fromCodePoint;
  r(r.S + r.F * (!!s && 1 != s.length), "String", {
    fromCodePoint: function (t) {
      for (var e, n = [], r = arguments.length, s = 0; r > s;) {
        if (e = +arguments[s++], i(e, 1114111) !== e) throw RangeError(e + " is not a valid code point");
        n.push(e < 65536 ? o(e) : o(55296 + ((e -= 65536) >> 10), e % 1024 + 56320));
      }

      return n.join("");
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(14),
      o = n(8);
  r(r.S, "String", {
    raw: function (t) {
      for (var e = i(t.raw), n = o(e.length), r = arguments.length, s = [], u = 0; n > u;) s.push(String(e[u++])), u < r && s.push(String(arguments[u]));

      return s.join("");
    }
  });
}, function (t, e, n) {
  "use strict";

  n(43)("trim", function (t) {
    return function () {
      return t(this || _global, 3);
    };
  });
}, function (t, e, n) {
  "use strict";

  var r = n(75)(!0);
  n(76)(String, "String", function (t) {
    (this || _global)._t = String(t), (this || _global)._i = 0;
  }, function () {
    var t,
        e = (this || _global)._t,
        n = (this || _global)._i;
    return n >= e.length ? {
      value: void 0,
      done: !0
    } : (t = r(e, n), (this || _global)._i += t.length, {
      value: t,
      done: !1
    });
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(75)(!1);
  r(r.P, "String", {
    codePointAt: function (t) {
      return i(this || _global, t);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(8),
      o = n(78),
      s = "".endsWith;
  r(r.P + r.F * n(79)("endsWith"), "String", {
    endsWith: function (t) {
      var e = o(this || _global, t, "endsWith"),
          n = arguments.length > 1 ? arguments[1] : void 0,
          r = i(e.length),
          u = void 0 === n ? r : Math.min(i(n), r),
          a = String(t);
      return s ? s.call(e, a, u) : e.slice(u - a.length, u) === a;
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(78);
  r(r.P + r.F * n(79)("includes"), "String", {
    includes: function (t) {
      return !!~i(this || _global, t, "includes").indexOf(t, arguments.length > 1 ? arguments[1] : void 0);
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.P, "String", {
    repeat: n(72)
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(8),
      o = n(78),
      s = "".startsWith;
  r(r.P + r.F * n(79)("startsWith"), "String", {
    startsWith: function (t) {
      var e = o(this || _global, t, "startsWith"),
          n = i(Math.min(arguments.length > 1 ? arguments[1] : void 0, e.length)),
          r = String(t);
      return s ? s.call(e, r, n) : e.slice(n, n + r.length) === r;
    }
  });
}, function (t, e, n) {
  "use strict";

  n(15)("anchor", function (t) {
    return function (e) {
      return t(this || _global, "a", "name", e);
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("big", function (t) {
    return function () {
      return t(this || _global, "big", "", "");
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("blink", function (t) {
    return function () {
      return t(this || _global, "blink", "", "");
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("bold", function (t) {
    return function () {
      return t(this || _global, "b", "", "");
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("fixed", function (t) {
    return function () {
      return t(this || _global, "tt", "", "");
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("fontcolor", function (t) {
    return function (e) {
      return t(this || _global, "font", "color", e);
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("fontsize", function (t) {
    return function (e) {
      return t(this || _global, "font", "size", e);
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("italics", function (t) {
    return function () {
      return t(this || _global, "i", "", "");
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("link", function (t) {
    return function (e) {
      return t(this || _global, "a", "href", e);
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("small", function (t) {
    return function () {
      return t(this || _global, "small", "", "");
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("strike", function (t) {
    return function () {
      return t(this || _global, "strike", "", "");
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("sub", function (t) {
    return function () {
      return t(this || _global, "sub", "", "");
    };
  });
}, function (t, e, n) {
  "use strict";

  n(15)("sup", function (t) {
    return function () {
      return t(this || _global, "sup", "", "");
    };
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Date", {
    now: function () {
      return new Date().getTime();
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(9),
      o = n(22);
  r(r.P + r.F * n(3)(function () {
    return null !== new Date(NaN).toJSON() || 1 !== Date.prototype.toJSON.call({
      toISOString: function () {
        return 1;
      }
    });
  }), "Date", {
    toJSON: function (t) {
      var e = i(this || _global),
          n = o(e);
      return "number" != typeof n || isFinite(n) ? e.toISOString() : null;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(209);
  r(r.P + r.F * (Date.prototype.toISOString !== i), "Date", {
    toISOString: i
  });
}, function (t, e, n) {
  "use strict";

  var r = n(3),
      i = Date.prototype.getTime,
      o = Date.prototype.toISOString,
      s = function (t) {
    return t > 9 ? t : "0" + t;
  };

  t.exports = r(function () {
    return "0385-07-25T07:06:39.999Z" != o.call(new Date(-50000000000000 - 1));
  }) || !r(function () {
    o.call(new Date(NaN));
  }) ? function () {
    if (!isFinite(i.call(this || _global))) throw RangeError("Invalid time value");
    var t = this || _global,
        e = t.getUTCFullYear(),
        n = t.getUTCMilliseconds(),
        r = e < 0 ? "-" : e > 9999 ? "+" : "";
    return r + ("00000" + Math.abs(e)).slice(r ? -6 : -4) + "-" + s(t.getUTCMonth() + 1) + "-" + s(t.getUTCDate()) + "T" + s(t.getUTCHours()) + ":" + s(t.getUTCMinutes()) + ":" + s(t.getUTCSeconds()) + "." + (n > 99 ? n : "0" + s(n)) + "Z";
  } : o;
}, function (t, e, n) {
  var r = Date.prototype,
      i = r.toString,
      o = r.getTime;
  new Date(NaN) + "" != "Invalid Date" && n(13)(r, "toString", function () {
    var t = o.call(this || _global);
    return t == t ? i.call(this || _global) : "Invalid Date";
  });
}, function (t, e, n) {
  var r = n(5)("toPrimitive"),
      i = Date.prototype;
  r in i || n(12)(i, r, n(212));
}, function (t, e, n) {
  "use strict";

  var r = n(1),
      i = n(22);

  t.exports = function (t) {
    if ("string" !== t && "number" !== t && "default" !== t) throw TypeError("Incorrect hint");
    return i(r(this || _global), "number" != t);
  };
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Array", {
    isArray: n(52)
  });
}, function (t, e, n) {
  "use strict";

  var r = n(18),
      i = n(0),
      o = n(9),
      s = n(104),
      u = n(80),
      a = n(8),
      l = n(81),
      f = n(82);
  i(i.S + i.F * !n(55)(function (t) {
    Array.from(t);
  }), "Array", {
    from: function (t) {
      var e,
          n,
          i,
          c,
          h = o(t),
          p = "function" == typeof (this || _global) ? this || _global : Array,
          d = arguments.length,
          v = d > 1 ? arguments[1] : void 0,
          y = void 0 !== v,
          m = 0,
          P = f(h);
      if (y && (v = r(v, d > 2 ? arguments[2] : void 0, 2)), void 0 == P || p == Array && u(P)) for (n = new p(e = a(h.length)); e > m; m++) l(n, m, y ? v(h[m], m) : h[m]);else for (c = P.call(h), n = new p(); !(i = c.next()).done; m++) l(n, m, y ? s(c, v, [i.value, m], !0) : i.value);
      return n.length = m, n;
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(81);
  r(r.S + r.F * n(3)(function () {
    function t() {}

    return !(Array.of.call(t) instanceof t);
  }), "Array", {
    of: function () {
      for (var t = 0, e = arguments.length, n = new ("function" == typeof (this || _global) ? this || _global : Array)(e); e > t;) i(n, t, arguments[t++]);

      return n.length = e, n;
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(14),
      o = [].join;
  r(r.P + r.F * (n(46) != Object || !n(20)(o)), "Array", {
    join: function (t) {
      return o.call(i(this || _global), void 0 === t ? "," : t);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(68),
      o = n(19),
      s = n(35),
      u = n(8),
      a = [].slice;
  r(r.P + r.F * n(3)(function () {
    i && a.call(i);
  }), "Array", {
    slice: function (t, e) {
      var n = u((this || _global).length),
          r = o(this || _global);
      if (e = void 0 === e ? n : e, "Array" == r) return a.call(this || _global, t, e);

      for (var i = s(t, n), l = s(e, n), f = u(l - i), c = Array(f), h = 0; h < f; h++) c[h] = "String" == r ? this.charAt(i + h) : (this || _global)[i + h];

      return c;
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(10),
      o = n(9),
      s = n(3),
      u = [].sort,
      a = [1, 2, 3];
  r(r.P + r.F * (s(function () {
    a.sort(void 0);
  }) || !s(function () {
    a.sort(null);
  }) || !n(20)(u)), "Array", {
    sort: function (t) {
      return void 0 === t ? u.call(o(this || _global)) : u.call(o(this || _global), i(t));
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(26)(0),
      o = n(20)([].forEach, !0);
  r(r.P + r.F * !o, "Array", {
    forEach: function (t) {
      return i(this || _global, t, arguments[1]);
    }
  });
}, function (t, e, n) {
  var r = n(4),
      i = n(52),
      o = n(5)("species");

  t.exports = function (t) {
    var e;
    return i(t) && ("function" != typeof (e = t.constructor) || e !== Array && !i(e.prototype) || (e = void 0), r(e) && null === (e = e[o]) && (e = void 0)), void 0 === e ? Array : e;
  };
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(26)(1);
  r(r.P + r.F * !n(20)([].map, !0), "Array", {
    map: function (t) {
      return i(this || _global, t, arguments[1]);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(26)(2);
  r(r.P + r.F * !n(20)([].filter, !0), "Array", {
    filter: function (t) {
      return i(this || _global, t, arguments[1]);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(26)(3);
  r(r.P + r.F * !n(20)([].some, !0), "Array", {
    some: function (t) {
      return i(this || _global, t, arguments[1]);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(26)(4);
  r(r.P + r.F * !n(20)([].every, !0), "Array", {
    every: function (t) {
      return i(this || _global, t, arguments[1]);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(105);
  r(r.P + r.F * !n(20)([].reduce, !0), "Array", {
    reduce: function (t) {
      return i(this || _global, t, arguments.length, arguments[1], !1);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(105);
  r(r.P + r.F * !n(20)([].reduceRight, !0), "Array", {
    reduceRight: function (t) {
      return i(this || _global, t, arguments.length, arguments[1], !0);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(50)(!1),
      o = [].indexOf,
      s = !!o && 1 / [1].indexOf(1, -0) < 0;
  r(r.P + r.F * (s || !n(20)(o)), "Array", {
    indexOf: function (t) {
      return s ? o.apply(this || _global, arguments) || 0 : i(this || _global, t, arguments[1]);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(14),
      o = n(24),
      s = n(8),
      u = [].lastIndexOf,
      a = !!u && 1 / [1].lastIndexOf(1, -0) < 0;
  r(r.P + r.F * (a || !n(20)(u)), "Array", {
    lastIndexOf: function (t) {
      if (a) return u.apply(this || _global, arguments) || 0;
      var e = i(this || _global),
          n = s(e.length),
          r = n - 1;

      for (arguments.length > 1 && (r = Math.min(r, o(arguments[1]))), r < 0 && (r = n + r); r >= 0; r--) if (r in e && e[r] === t) return r || 0;

      return -1;
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.P, "Array", {
    copyWithin: n(106)
  }), n(31)("copyWithin");
}, function (t, e, n) {
  var r = n(0);
  r(r.P, "Array", {
    fill: n(84)
  }), n(31)("fill");
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(26)(5),
      o = !0;
  "find" in [] && Array(1).find(function () {
    o = !1;
  }), r(r.P + r.F * o, "Array", {
    find: function (t) {
      return i(this || _global, t, arguments.length > 1 ? arguments[1] : void 0);
    }
  }), n(31)("find");
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(26)(6),
      o = "findIndex",
      s = !0;
  o in [] && Array(1)[o](function () {
    s = !1;
  }), r(r.P + r.F * s, "Array", {
    findIndex: function (t) {
      return i(this || _global, t, arguments.length > 1 ? arguments[1] : void 0);
    }
  }), n(31)(o);
}, function (t, e, n) {
  n(38)("Array");
}, function (t, e, n) {
  var r = n(2),
      i = n(71),
      o = n(7).f,
      s = n(37).f,
      u = n(54),
      a = n(56),
      l = r.RegExp,
      f = l,
      c = l.prototype,
      h = /a/g,
      p = /a/g,
      d = new l(h) !== h;

  if (n(6) && (!d || n(3)(function () {
    return p[n(5)("match")] = !1, l(h) != h || l(p) == p || "/a/i" != l(h, "i");
  }))) {
    l = function (t, e) {
      var n = (this || _global) instanceof l,
          r = u(t),
          o = void 0 === e;
      return !n && r && t.constructor === l && o ? t : i(d ? new f(r && !o ? t.source : t, e) : f((r = t instanceof l) ? t.source : t, r && o ? a.call(t) : e), n ? this || _global : c, l);
    };

    for (var v = function (t) {
      (t in l) || o(l, t, {
        configurable: !0,
        get: function () {
          return f[t];
        },
        set: function (e) {
          f[t] = e;
        }
      });
    }, y = s(f), m = 0; y.length > m;) v(y[m++]);

    c.constructor = l, l.prototype = c, n(13)(r, "RegExp", l);
  }

  n(38)("RegExp");
}, function (t, e, n) {
  "use strict";

  n(108);

  var r = n(1),
      i = n(56),
      o = n(6),
      s = /./.toString,
      u = function (t) {
    n(13)(RegExp.prototype, "toString", t, !0);
  };

  n(3)(function () {
    return "/a/b" != s.call({
      source: "a",
      flags: "b"
    });
  }) ? u(function () {
    var t = r(this || _global);
    return "/".concat(t.source, "/", "flags" in t ? t.flags : !o && t instanceof RegExp ? i.call(t) : void 0);
  }) : "toString" != s.name && u(function () {
    return s.call(this || _global);
  });
}, function (t, e, n) {
  n(57)("match", 1, function (t, e, n) {
    return [function (n) {
      "use strict";

      var r = t(this || _global),
          i = void 0 == n ? void 0 : n[e];
      return void 0 !== i ? i.call(n, r) : new RegExp(n)[e](String(r));
    }, n];
  });
}, function (t, e, n) {
  n(57)("replace", 2, function (t, e, n) {
    return [function (r, i) {
      "use strict";

      var o = t(this || _global),
          s = void 0 == r ? void 0 : r[e];
      return void 0 !== s ? s.call(r, o, i) : n.call(String(o), r, i);
    }, n];
  });
}, function (t, e, n) {
  n(57)("search", 1, function (t, e, n) {
    return [function (n) {
      "use strict";

      var r = t(this || _global),
          i = void 0 == n ? void 0 : n[e];
      return void 0 !== i ? i.call(n, r) : new RegExp(n)[e](String(r));
    }, n];
  });
}, function (t, e, n) {
  n(57)("split", 2, function (t, e, r) {
    "use strict";

    var i = n(54),
        o = r,
        s = [].push;

    if ("c" == "abbc".split(/(b)*/)[1] || 4 != "test".split(/(?:)/, -1).length || 2 != "ab".split(/(?:ab)*/).length || 4 != ".".split(/(.?)(.?)/).length || ".".split(/()()/).length > 1 || "".split(/.?/).length) {
      var u = void 0 === /()??/.exec("")[1];

      r = function (t, e) {
        var n = String(this || _global);
        if (void 0 === t && 0 === e) return [];
        if (!i(t)) return o.call(n, t, e);
        var r,
            a,
            l,
            f,
            c,
            h = [],
            p = (t.ignoreCase ? "i" : "") + (t.multiline ? "m" : "") + (t.unicode ? "u" : "") + (t.sticky ? "y" : ""),
            d = 0,
            v = void 0 === e ? 4294967295 : e >>> 0,
            y = new RegExp(t.source, p + "g");

        for (u || (r = new RegExp("^" + y.source + "$(?!\\s)", p)); (a = y.exec(n)) && !((l = a.index + a[0].length) > d && (h.push(n.slice(d, a.index)), !u && a.length > 1 && a[0].replace(r, function () {
          for (c = 1; c < arguments.length - 2; c++) void 0 === arguments[c] && (a[c] = void 0);
        }), a.length > 1 && a.index < n.length && s.apply(h, a.slice(1)), f = a[0].length, d = l, h.length >= v));) y.lastIndex === a.index && y.lastIndex++;

        return d === n.length ? !f && y.test("") || h.push("") : h.push(n.slice(d)), h.length > v ? h.slice(0, v) : h;
      };
    } else "0".split(void 0, 0).length && (r = function (t, e) {
      return void 0 === t && 0 === e ? [] : o.call(this || _global, t, e);
    });

    return [function (n, i) {
      var o = t(this || _global),
          s = void 0 == n ? void 0 : n[e];
      return void 0 !== s ? s.call(n, o, i) : r.call(String(o), n, i);
    }, r];
  });
}, function (t, e, n) {
  "use strict";

  var r,
      i,
      o,
      s,
      u = n(34),
      a = n(2),
      l = n(18),
      f = n(48),
      c = n(0),
      h = n(4),
      p = n(10),
      d = n(39),
      v = n(40),
      y = n(58),
      m = n(86).set,
      P = n(87)(),
      g = n(88),
      _ = n(109),
      x = n(110),
      I = a.TypeError,
      C = a.process,
      E = a.Promise,
      L = "process" == f(C),
      S = function () {},
      b = i = g.f,
      O = !!function () {
    try {
      var t = E.resolve(1),
          e = (t.constructor = {})[n(5)("species")] = function (t) {
        t(S, S);
      };

      return (L || "function" == typeof PromiseRejectionEvent) && t.then(S) instanceof e;
    } catch (t) {}
  }(),
      T = u ? function (t, e) {
    return t === e || t === E && e === s;
  } : function (t, e) {
    return t === e;
  },
      N = function (t) {
    var e;
    return !(!h(t) || "function" != typeof (e = t.then)) && e;
  },
      w = function (t, e) {
    if (!t._n) {
      t._n = !0;
      var n = t._c;
      P(function () {
        for (var r = t._v, i = 1 == t._s, o = 0, s = function (e) {
          var n,
              o,
              s = i ? e.ok : e.fail,
              u = e.resolve,
              a = e.reject,
              l = e.domain;

          try {
            s ? (i || (2 == t._h && Y(t), t._h = 1), !0 === s ? n = r : (l && l.enter(), n = s(r), l && l.exit()), n === e.promise ? a(I("Promise-chain cycle")) : (o = N(n)) ? o.call(n, u, a) : u(n)) : a(r);
          } catch (t) {
            a(t);
          }
        }; n.length > o;) s(n[o++]);

        t._c = [], t._n = !1, e && !t._h && A(t);
      });
    }
  },
      A = function (t) {
    m.call(a, function () {
      var e,
          n,
          r,
          i = t._v,
          o = X(t);
      if (o && (e = _(function () {
        L ? C.emit("unhandledRejection", i, t) : (n = a.onunhandledrejection) ? n({
          promise: t,
          reason: i
        }) : (r = a.console) && r.error && r.error("Unhandled promise rejection", i);
      }), t._h = L || X(t) ? 2 : 1), t._a = void 0, o && e.e) throw e.v;
    });
  },
      X = function (t) {
    if (1 == t._h) return !1;

    for (var e, n = t._a || t._c, r = 0; n.length > r;) if ((e = n[r++]).fail || !X(e.promise)) return !1;

    return !0;
  },
      Y = function (t) {
    m.call(a, function () {
      var e;
      L ? C.emit("rejectionHandled", t) : (e = a.onrejectionhandled) && e({
        promise: t,
        reason: t._v
      });
    });
  },
      D = function (t) {
    var e = this || _global;
    e._d || (e._d = !0, (e = e._w || e)._v = t, e._s = 2, e._a || (e._a = e._c.slice()), w(e, !0));
  },
      B = function (t) {
    var e,
        n = this || _global;

    if (!n._d) {
      n._d = !0, n = n._w || n;

      try {
        if (n === t) throw I("Promise can't be resolved itself");
        (e = N(t)) ? P(function () {
          var r = {
            _w: n,
            _d: !1
          };

          try {
            e.call(t, l(B, r, 1), l(D, r, 1));
          } catch (t) {
            D.call(r, t);
          }
        }) : (n._v = t, n._s = 1, w(n, !1));
      } catch (t) {
        D.call({
          _w: n,
          _d: !1
        }, t);
      }
    }
  };

  O || (E = function (t) {
    d(this || _global, E, "Promise", "_h"), p(t), r.call(this || _global);

    try {
      t(l(B, this || _global, 1), l(D, this || _global, 1));
    } catch (t) {
      D.call(this || _global, t);
    }
  }, (r = function (t) {
    (this || _global)._c = [], (this || _global)._a = void 0, (this || _global)._s = 0, (this || _global)._d = !1, (this || _global)._v = void 0, (this || _global)._h = 0, (this || _global)._n = !1;
  }).prototype = n(41)(E.prototype, {
    then: function (t, e) {
      var n = b(y(this || _global, E));
      return n.ok = "function" != typeof t || t, n.fail = "function" == typeof e && e, n.domain = L ? C.domain : void 0, (this || _global)._c.push(n), (this || _global)._a && (this || _global)._a.push(n), (this || _global)._s && w(this || _global, !1), n.promise;
    },
    catch: function (t) {
      return this.then(void 0, t);
    }
  }), o = function () {
    var t = new r();
    (this || _global).promise = t, (this || _global).resolve = l(B, t, 1), (this || _global).reject = l(D, t, 1);
  }, g.f = b = function (t) {
    return T(E, t) ? new o(t) : i(t);
  }), c(c.G + c.W + c.F * !O, {
    Promise: E
  }), n(42)(E, "Promise"), n(38)("Promise"), s = n(21).Promise, c(c.S + c.F * !O, "Promise", {
    reject: function (t) {
      var e = b(this || _global);
      return (0, e.reject)(t), e.promise;
    }
  }), c(c.S + c.F * (u || !O), "Promise", {
    resolve: function (t) {
      return t instanceof E && T(t.constructor, this || _global) ? t : x(this || _global, t);
    }
  }), c(c.S + c.F * !(O && n(55)(function (t) {
    E.all(t).catch(S);
  })), "Promise", {
    all: function (t) {
      var e = this || _global,
          n = b(e),
          r = n.resolve,
          i = n.reject,
          o = _(function () {
        var n = [],
            o = 0,
            s = 1;
        v(t, !1, function (t) {
          var u = o++,
              a = !1;
          n.push(void 0), s++, e.resolve(t).then(function (t) {
            a || (a = !0, n[u] = t, --s || r(n));
          }, i);
        }), --s || r(n);
      });

      return o.e && i(o.v), n.promise;
    },
    race: function (t) {
      var e = this || _global,
          n = b(e),
          r = n.reject,
          i = _(function () {
        v(t, !1, function (t) {
          e.resolve(t).then(n.resolve, r);
        });
      });

      return i.e && r(i.v), n.promise;
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(115),
      i = n(45);
  n(59)("WeakSet", function (t) {
    return function () {
      return t(this || _global, arguments.length > 0 ? arguments[0] : void 0);
    };
  }, {
    add: function (t) {
      return r.def(i(this || _global, "WeakSet"), t, !0);
    }
  }, r, !1, !0);
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(60),
      o = n(89),
      s = n(1),
      u = n(35),
      a = n(8),
      l = n(4),
      f = n(2).ArrayBuffer,
      c = n(58),
      h = o.ArrayBuffer,
      p = o.DataView,
      d = i.ABV && f.isView,
      v = h.prototype.slice,
      y = i.VIEW;
  r(r.G + r.W + r.F * (f !== h), {
    ArrayBuffer: h
  }), r(r.S + r.F * !i.CONSTR, "ArrayBuffer", {
    isView: function (t) {
      return d && d(t) || l(t) && y in t;
    }
  }), r(r.P + r.U + r.F * n(3)(function () {
    return !new h(2).slice(1, void 0).byteLength;
  }), "ArrayBuffer", {
    slice: function (t, e) {
      if (void 0 !== v && void 0 === e) return v.call(s(this || _global), t);

      for (var n = s(this || _global).byteLength, r = u(t, n), i = u(void 0 === e ? n : e, n), o = new (c(this || _global, h))(a(i - r)), l = new p(this || _global), f = new p(o), d = 0; r < i;) f.setUint8(d++, l.getUint8(r++));

      return o;
    }
  }), n(38)("ArrayBuffer");
}, function (t, e, n) {
  var r = n(0);
  r(r.G + r.W + r.F * !n(60).ABV, {
    DataView: n(89).DataView
  });
}, function (t, e, n) {
  n(27)("Int8", 1, function (t) {
    return function (e, n, r) {
      return t(this || _global, e, n, r);
    };
  });
}, function (t, e, n) {
  n(27)("Uint8", 1, function (t) {
    return function (e, n, r) {
      return t(this || _global, e, n, r);
    };
  });
}, function (t, e, n) {
  n(27)("Uint8", 1, function (t) {
    return function (e, n, r) {
      return t(this || _global, e, n, r);
    };
  }, !0);
}, function (t, e, n) {
  n(27)("Int16", 2, function (t) {
    return function (e, n, r) {
      return t(this || _global, e, n, r);
    };
  });
}, function (t, e, n) {
  n(27)("Uint16", 2, function (t) {
    return function (e, n, r) {
      return t(this || _global, e, n, r);
    };
  });
}, function (t, e, n) {
  n(27)("Int32", 4, function (t) {
    return function (e, n, r) {
      return t(this || _global, e, n, r);
    };
  });
}, function (t, e, n) {
  n(27)("Uint32", 4, function (t) {
    return function (e, n, r) {
      return t(this || _global, e, n, r);
    };
  });
}, function (t, e, n) {
  n(27)("Float32", 4, function (t) {
    return function (e, n, r) {
      return t(this || _global, e, n, r);
    };
  });
}, function (t, e, n) {
  n(27)("Float64", 8, function (t) {
    return function (e, n, r) {
      return t(this || _global, e, n, r);
    };
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(10),
      o = n(1),
      s = (n(2).Reflect || {}).apply,
      u = Function.apply;
  r(r.S + r.F * !n(3)(function () {
    s(function () {});
  }), "Reflect", {
    apply: function (t, e, n) {
      var r = i(t),
          a = o(n);
      return s ? s(r, e, a) : u.call(r, e, a);
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(36),
      o = n(10),
      s = n(1),
      u = n(4),
      a = n(3),
      l = n(97),
      f = (n(2).Reflect || {}).construct,
      c = a(function () {
    function t() {}

    return !(f(function () {}, [], t) instanceof t);
  }),
      h = !a(function () {
    f(function () {});
  });
  r(r.S + r.F * (c || h), "Reflect", {
    construct: function (t, e) {
      o(t), s(e);
      var n = arguments.length < 3 ? t : o(arguments[2]);
      if (h && !c) return f(t, e, n);

      if (t == n) {
        switch (e.length) {
          case 0:
            return new t();

          case 1:
            return new t(e[0]);

          case 2:
            return new t(e[0], e[1]);

          case 3:
            return new t(e[0], e[1], e[2]);

          case 4:
            return new t(e[0], e[1], e[2], e[3]);
        }

        var r = [null];
        return r.push.apply(r, e), new (l.apply(t, r))();
      }

      var a = n.prototype,
          p = i(u(a) ? a : Object.prototype),
          d = Function.apply.call(t, p, e);
      return u(d) ? d : p;
    }
  });
}, function (t, e, n) {
  var r = n(7),
      i = n(0),
      o = n(1),
      s = n(22);
  i(i.S + i.F * n(3)(function () {
    Reflect.defineProperty(r.f({}, 1, {
      value: 1
    }), 1, {
      value: 2
    });
  }), "Reflect", {
    defineProperty: function (t, e, n) {
      o(t), e = s(e, !0), o(n);

      try {
        return r.f(t, e, n), !0;
      } catch (t) {
        return !1;
      }
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(16).f,
      o = n(1);
  r(r.S, "Reflect", {
    deleteProperty: function (t, e) {
      var n = i(o(t), e);
      return !(n && !n.configurable) && delete t[e];
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(1),
      o = function (t) {
    (this || _global)._t = i(t), (this || _global)._i = 0;
    var e,
        n = (this || _global)._k = [];

    for (e in t) n.push(e);
  };

  n(77)(o, "Object", function () {
    var t,
        e = (this || _global)._k;

    do {
      if ((this || _global)._i >= e.length) return {
        value: void 0,
        done: !0
      };
    } while (!((t = e[(this || _global)._i++]) in (this || _global)._t));

    return {
      value: t,
      done: !1
    };
  }), r(r.S, "Reflect", {
    enumerate: function (t) {
      return new o(t);
    }
  });
}, function (t, e, n) {
  var r = n(16),
      i = n(17),
      o = n(11),
      s = n(0),
      u = n(4),
      a = n(1);
  s(s.S, "Reflect", {
    get: function t(e, n) {
      var s,
          l,
          f = arguments.length < 3 ? e : arguments[2];
      return a(e) === f ? e[n] : (s = r.f(e, n)) ? o(s, "value") ? s.value : void 0 !== s.get ? s.get.call(f) : void 0 : u(l = i(e)) ? t(l, n, f) : void 0;
    }
  });
}, function (t, e, n) {
  var r = n(16),
      i = n(0),
      o = n(1);
  i(i.S, "Reflect", {
    getOwnPropertyDescriptor: function (t, e) {
      return r.f(o(t), e);
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(17),
      o = n(1);
  r(r.S, "Reflect", {
    getPrototypeOf: function (t) {
      return i(o(t));
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Reflect", {
    has: function (t, e) {
      return e in t;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(1),
      o = Object.isExtensible;
  r(r.S, "Reflect", {
    isExtensible: function (t) {
      return i(t), !o || o(t);
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Reflect", {
    ownKeys: n(117)
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(1),
      o = Object.preventExtensions;
  r(r.S, "Reflect", {
    preventExtensions: function (t) {
      i(t);

      try {
        return o && o(t), !0;
      } catch (t) {
        return !1;
      }
    }
  });
}, function (t, e, n) {
  var r = n(7),
      i = n(16),
      o = n(17),
      s = n(11),
      u = n(0),
      a = n(32),
      l = n(1),
      f = n(4);
  u(u.S, "Reflect", {
    set: function t(e, n, u) {
      var c,
          h,
          p = arguments.length < 4 ? e : arguments[3],
          d = i.f(l(e), n);

      if (!d) {
        if (f(h = o(e))) return t(h, n, u, p);
        d = a(0);
      }

      return s(d, "value") ? !(!1 === d.writable || !f(p) || ((c = i.f(p, n) || a(0)).value = u, r.f(p, n, c), 0)) : void 0 !== d.set && (d.set.call(p, u), !0);
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(69);
  i && r(r.S, "Reflect", {
    setPrototypeOf: function (t, e) {
      i.check(t, e);

      try {
        return i.set(t, e), !0;
      } catch (t) {
        return !1;
      }
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(50)(!0);
  r(r.P, "Array", {
    includes: function (t) {
      return i(this || _global, t, arguments.length > 1 ? arguments[1] : void 0);
    }
  }), n(31)("includes");
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(118),
      o = n(9),
      s = n(8),
      u = n(10),
      a = n(83);
  r(r.P, "Array", {
    flatMap: function (t) {
      var e,
          n,
          r = o(this || _global);
      return u(t), e = s(r.length), n = a(r, 0), i(n, r, r, e, 0, 1, t, arguments[1]), n;
    }
  }), n(31)("flatMap");
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(118),
      o = n(9),
      s = n(8),
      u = n(24),
      a = n(83);
  r(r.P, "Array", {
    flatten: function () {
      var t = arguments[0],
          e = o(this || _global),
          n = s(e.length),
          r = a(e, 0);
      return i(r, e, e, n, 0, void 0 === t ? 1 : u(t)), r;
    }
  }), n(31)("flatten");
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(75)(!0);
  r(r.P, "String", {
    at: function (t) {
      return i(this || _global, t);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(119);
  r(r.P, "String", {
    padStart: function (t) {
      return i(this || _global, t, arguments.length > 1 ? arguments[1] : void 0, !0);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(119);
  r(r.P, "String", {
    padEnd: function (t) {
      return i(this || _global, t, arguments.length > 1 ? arguments[1] : void 0, !1);
    }
  });
}, function (t, e, n) {
  "use strict";

  n(43)("trimLeft", function (t) {
    return function () {
      return t(this || _global, 1);
    };
  }, "trimStart");
}, function (t, e, n) {
  "use strict";

  n(43)("trimRight", function (t) {
    return function () {
      return t(this || _global, 2);
    };
  }, "trimEnd");
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(23),
      o = n(8),
      s = n(54),
      u = n(56),
      a = RegExp.prototype,
      l = function (t, e) {
    (this || _global)._r = t, (this || _global)._s = e;
  };

  n(77)(l, "RegExp String", function () {
    var t = (this || _global)._r.exec((this || _global)._s);

    return {
      value: t,
      done: null === t
    };
  }), r(r.P, "String", {
    matchAll: function (t) {
      if (i(this || _global), !s(t)) throw TypeError(t + " is not a regexp!");
      var e = String(this || _global),
          n = "flags" in a ? String(t.flags) : u.call(t),
          r = new RegExp(t.source, ~n.indexOf("g") ? n : "g" + n);
      return r.lastIndex = o(t.lastIndex), new l(r, e);
    }
  });
}, function (t, e, n) {
  n(65)("asyncIterator");
}, function (t, e, n) {
  n(65)("observable");
}, function (t, e, n) {
  var r = n(0),
      i = n(117),
      o = n(14),
      s = n(16),
      u = n(81);
  r(r.S, "Object", {
    getOwnPropertyDescriptors: function (t) {
      for (var e, n, r = o(t), a = s.f, l = i(r), f = {}, c = 0; l.length > c;) void 0 !== (n = a(r, e = l[c++])) && u(f, e, n);

      return f;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(120)(!1);
  r(r.S, "Object", {
    values: function (t) {
      return i(t);
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(120)(!0);
  r(r.S, "Object", {
    entries: function (t) {
      return i(t);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(9),
      o = n(10),
      s = n(7);
  n(6) && r(r.P + n(61), "Object", {
    __defineGetter__: function (t, e) {
      s.f(i(this || _global), t, {
        get: o(e),
        enumerable: !0,
        configurable: !0
      });
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(9),
      o = n(10),
      s = n(7);
  n(6) && r(r.P + n(61), "Object", {
    __defineSetter__: function (t, e) {
      s.f(i(this || _global), t, {
        set: o(e),
        enumerable: !0,
        configurable: !0
      });
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(9),
      o = n(22),
      s = n(17),
      u = n(16).f;
  n(6) && r(r.P + n(61), "Object", {
    __lookupGetter__: function (t) {
      var e,
          n = i(this || _global),
          r = o(t, !0);

      do {
        if (e = u(n, r)) return e.get;
      } while (n = s(n));
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(9),
      o = n(22),
      s = n(17),
      u = n(16).f;
  n(6) && r(r.P + n(61), "Object", {
    __lookupSetter__: function (t) {
      var e,
          n = i(this || _global),
          r = o(t, !0);

      do {
        if (e = u(n, r)) return e.set;
      } while (n = s(n));
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.P + r.R, "Map", {
    toJSON: n(121)("Map")
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.P + r.R, "Set", {
    toJSON: n(121)("Set")
  });
}, function (t, e, n) {
  n(62)("Map");
}, function (t, e, n) {
  n(62)("Set");
}, function (t, e, n) {
  n(62)("WeakMap");
}, function (t, e, n) {
  n(62)("WeakSet");
}, function (t, e, n) {
  n(63)("Map");
}, function (t, e, n) {
  n(63)("Set");
}, function (t, e, n) {
  n(63)("WeakMap");
}, function (t, e, n) {
  n(63)("WeakSet");
}, function (t, e, n) {
  var r = n(0);
  r(r.G, {
    global: n(2)
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "System", {
    global: n(2)
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(19);
  r(r.S, "Error", {
    isError: function (t) {
      return "Error" === i(t);
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    clamp: function (t, e, n) {
      return Math.min(n, Math.max(e, t));
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    DEG_PER_RAD: Math.PI / 180
  });
}, function (t, e, n) {
  var r = n(0),
      i = 180 / Math.PI;
  r(r.S, "Math", {
    degrees: function (t) {
      return t * i;
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(123),
      o = n(103);
  r(r.S, "Math", {
    fscale: function (t, e, n, r, s) {
      return o(i(t, e, n, r, s));
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    iaddh: function (t, e, n, r) {
      var i = t >>> 0,
          o = n >>> 0;
      return (e >>> 0) + (r >>> 0) + ((i & o | (i | o) & ~(i + o >>> 0)) >>> 31) | 0;
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    isubh: function (t, e, n, r) {
      var i = t >>> 0,
          o = n >>> 0;
      return (e >>> 0) - (r >>> 0) - ((~i & o | ~(i ^ o) & i - o >>> 0) >>> 31) | 0;
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    imulh: function (t, e) {
      var n = +t,
          r = +e,
          i = 65535 & n,
          o = 65535 & r,
          s = n >> 16,
          u = r >> 16,
          a = (s * o >>> 0) + (i * o >>> 16);
      return s * u + (a >> 16) + ((i * u >>> 0) + (65535 & a) >> 16);
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    RAD_PER_DEG: 180 / Math.PI
  });
}, function (t, e, n) {
  var r = n(0),
      i = Math.PI / 180;
  r(r.S, "Math", {
    radians: function (t) {
      return t * i;
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    scale: n(123)
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    umulh: function (t, e) {
      var n = +t,
          r = +e,
          i = 65535 & n,
          o = 65535 & r,
          s = n >>> 16,
          u = r >>> 16,
          a = (s * o >>> 0) + (i * o >>> 16);
      return s * u + (a >>> 16) + ((i * u >>> 0) + (65535 & a) >>> 16);
    }
  });
}, function (t, e, n) {
  var r = n(0);
  r(r.S, "Math", {
    signbit: function (t) {
      return (t = +t) != t ? t : 0 == t ? 1 / t == 1 / 0 : t > 0;
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(21),
      o = n(2),
      s = n(58),
      u = n(110);
  r(r.P + r.R, "Promise", {
    finally: function (t) {
      var e = s(this || _global, i.Promise || o.Promise),
          n = "function" == typeof t;
      return this.then(n ? function (n) {
        return u(e, t()).then(function () {
          return n;
        });
      } : t, n ? function (n) {
        return u(e, t()).then(function () {
          throw n;
        });
      } : t);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(88),
      o = n(109);
  r(r.S, "Promise", {
    try: function (t) {
      var e = i.f(this || _global),
          n = o(t);
      return (n.e ? e.reject : e.resolve)(n.v), e.promise;
    }
  });
}, function (t, e, n) {
  var r = n(28),
      i = n(1),
      o = r.key,
      s = r.set;
  r.exp({
    defineMetadata: function (t, e, n, r) {
      s(t, e, i(n), o(r));
    }
  });
}, function (t, e, n) {
  var r = n(28),
      i = n(1),
      o = r.key,
      s = r.map,
      u = r.store;
  r.exp({
    deleteMetadata: function (t, e) {
      var n = arguments.length < 3 ? void 0 : o(arguments[2]),
          r = s(i(e), n, !1);
      if (void 0 === r || !r.delete(t)) return !1;
      if (r.size) return !0;
      var a = u.get(e);
      return a.delete(n), !!a.size || u.delete(e);
    }
  });
}, function (t, e, n) {
  var r = n(28),
      i = n(1),
      o = n(17),
      s = r.has,
      u = r.get,
      a = r.key,
      l = function (t, e, n) {
    if (s(t, e, n)) return u(t, e, n);
    var r = o(e);
    return null !== r ? l(t, r, n) : void 0;
  };

  r.exp({
    getMetadata: function (t, e) {
      return l(t, i(e), arguments.length < 3 ? void 0 : a(arguments[2]));
    }
  });
}, function (t, e, n) {
  var r = n(113),
      i = n(122),
      o = n(28),
      s = n(1),
      u = n(17),
      a = o.keys,
      l = o.key,
      f = function (t, e) {
    var n = a(t, e),
        o = u(t);
    if (null === o) return n;
    var s = f(o, e);
    return s.length ? n.length ? i(new r(n.concat(s))) : s : n;
  };

  o.exp({
    getMetadataKeys: function (t) {
      return f(s(t), arguments.length < 2 ? void 0 : l(arguments[1]));
    }
  });
}, function (t, e, n) {
  var r = n(28),
      i = n(1),
      o = r.get,
      s = r.key;
  r.exp({
    getOwnMetadata: function (t, e) {
      return o(t, i(e), arguments.length < 3 ? void 0 : s(arguments[2]));
    }
  });
}, function (t, e, n) {
  var r = n(28),
      i = n(1),
      o = r.keys,
      s = r.key;
  r.exp({
    getOwnMetadataKeys: function (t) {
      return o(i(t), arguments.length < 2 ? void 0 : s(arguments[1]));
    }
  });
}, function (t, e, n) {
  var r = n(28),
      i = n(1),
      o = n(17),
      s = r.has,
      u = r.key,
      a = function (t, e, n) {
    if (s(t, e, n)) return !0;
    var r = o(e);
    return null !== r && a(t, r, n);
  };

  r.exp({
    hasMetadata: function (t, e) {
      return a(t, i(e), arguments.length < 3 ? void 0 : u(arguments[2]));
    }
  });
}, function (t, e, n) {
  var r = n(28),
      i = n(1),
      o = r.has,
      s = r.key;
  r.exp({
    hasOwnMetadata: function (t, e) {
      return o(t, i(e), arguments.length < 3 ? void 0 : s(arguments[2]));
    }
  });
}, function (t, e, n) {
  var r = n(28),
      i = n(1),
      o = n(10),
      s = r.key,
      u = r.set;
  r.exp({
    metadata: function (t, e) {
      return function (n, r) {
        u(t, e, (void 0 !== r ? i : o)(n), s(r));
      };
    }
  });
}, function (t, e, n) {
  var r = n(0),
      i = n(87)(),
      o = n(2).process,
      s = "process" == n(19)(o);
  r(r.G, {
    asap: function (t) {
      var e = s && o.domain;
      i(e ? e.bind(t) : t);
    }
  });
}, function (t, e, n) {
  "use strict";

  var r = n(0),
      i = n(2),
      o = n(21),
      s = n(87)(),
      u = n(5)("observable"),
      a = n(10),
      l = n(1),
      f = n(39),
      c = n(41),
      h = n(12),
      p = n(40),
      d = p.RETURN,
      v = function (t) {
    return null == t ? void 0 : a(t);
  },
      y = function (t) {
    var e = t._c;
    e && (t._c = void 0, e());
  },
      m = function (t) {
    return void 0 === t._o;
  },
      P = function (t) {
    m(t) || (t._o = void 0, y(t));
  },
      g = function (t, e) {
    l(t), (this || _global)._c = void 0, (this || _global)._o = t, t = new _(this || _global);

    try {
      var n = e(t),
          r = n;
      null != n && ("function" == typeof n.unsubscribe ? n = function () {
        r.unsubscribe();
      } : a(n), (this || _global)._c = n);
    } catch (e) {
      return void t.error(e);
    }

    m(this || _global) && y(this || _global);
  };

  g.prototype = c({}, {
    unsubscribe: function () {
      P(this || _global);
    }
  });

  var _ = function (t) {
    (this || _global)._s = t;
  };

  _.prototype = c({}, {
    next: function (t) {
      var e = (this || _global)._s;

      if (!m(e)) {
        var n = e._o;

        try {
          var r = v(n.next);
          if (r) return r.call(n, t);
        } catch (t) {
          try {
            P(e);
          } finally {
            throw t;
          }
        }
      }
    },
    error: function (t) {
      var e = (this || _global)._s;
      if (m(e)) throw t;
      var n = e._o;
      e._o = void 0;

      try {
        var r = v(n.error);
        if (!r) throw t;
        t = r.call(n, t);
      } catch (t) {
        try {
          y(e);
        } finally {
          throw t;
        }
      }

      return y(e), t;
    },
    complete: function (t) {
      var e = (this || _global)._s;

      if (!m(e)) {
        var n = e._o;
        e._o = void 0;

        try {
          var r = v(n.complete);
          t = r ? r.call(n, t) : void 0;
        } catch (t) {
          try {
            y(e);
          } finally {
            throw t;
          }
        }

        return y(e), t;
      }
    }
  });

  var x = function (t) {
    f(this || _global, x, "Observable", "_f")._f = a(t);
  };

  c(x.prototype, {
    subscribe: function (t) {
      return new g(t, (this || _global)._f);
    },
    forEach: function (t) {
      var e = this || _global;
      return new (o.Promise || i.Promise)(function (n, r) {
        a(t);
        var i = e.subscribe({
          next: function (e) {
            try {
              return t(e);
            } catch (t) {
              r(t), i.unsubscribe();
            }
          },
          error: r,
          complete: n
        });
      });
    }
  }), c(x, {
    from: function (t) {
      var e = "function" == typeof (this || _global) ? this || _global : x,
          n = v(l(t)[u]);

      if (n) {
        var r = l(n.call(t));
        return r.constructor === e ? r : new e(function (t) {
          return r.subscribe(t);
        });
      }

      return new e(function (e) {
        var n = !1;
        return s(function () {
          if (!n) {
            try {
              if (p(t, !1, function (t) {
                if (e.next(t), n) return d;
              }) === d) return;
            } catch (t) {
              if (n) throw t;
              return void e.error(t);
            }

            e.complete();
          }
        }), function () {
          n = !0;
        };
      });
    },
    of: function () {
      for (var t = 0, e = arguments.length, n = Array(e); t < e;) n[t] = arguments[t++];

      return new ("function" == typeof (this || _global) ? this || _global : x)(function (t) {
        var e = !1;
        return s(function () {
          if (!e) {
            for (var r = 0; r < n.length; ++r) if (t.next(n[r]), e) return;

            t.complete();
          }
        }), function () {
          e = !0;
        };
      });
    }
  }), h(x.prototype, u, function () {
    return this || _global;
  }), r(r.G, {
    Observable: x
  }), n(38)("Observable");
}, function (t, e, n) {
  var r = n(2),
      i = n(0),
      o = n(53),
      s = n(324),
      u = r.navigator,
      a = !!u && /MSIE .\./.test(u.userAgent),
      l = function (t) {
    return a ? function (e, n) {
      return t(o(s, [].slice.call(arguments, 2), "function" == typeof e ? e : Function(e)), n);
    } : t;
  };

  i(i.G + i.B + i.F * a, {
    setTimeout: l(r.setTimeout),
    setInterval: l(r.setInterval)
  });
}, function (t, e, n) {
  "use strict";

  var r = n(325),
      i = n(53),
      o = n(10);

  t.exports = function () {
    for (var t = o(this || _global), e = arguments.length, n = Array(e), s = 0, u = r._, a = !1; e > s;) (n[s] = arguments[s++]) === u && (a = !0);

    return function () {
      var r,
          o = arguments.length,
          s = 0,
          l = 0;
      if (!a && !o) return i(t, n, this || _global);
      if (r = n.slice(), a) for (; e > s; s++) r[s] === u && (r[s] = arguments[l++]);

      for (; o > l;) r.push(arguments[l++]);

      return i(t, r, this || _global);
    };
  };
}, function (t, e, n) {
  t.exports = n(2);
}, function (t, e, n) {
  var r = n(0),
      i = n(86);
  r(r.G + r.B, {
    setImmediate: i.set,
    clearImmediate: i.clear
  });
}, function (t, e, n) {
  for (var r = n(85), i = n(30), o = n(13), s = n(2), u = n(12), a = n(44), l = n(5), f = l("iterator"), c = l("toStringTag"), h = a.Array, p = {
    CSSRuleList: !0,
    CSSStyleDeclaration: !1,
    CSSValueList: !1,
    ClientRectList: !1,
    DOMRectList: !1,
    DOMStringList: !1,
    DOMTokenList: !0,
    DataTransferItemList: !1,
    FileList: !1,
    HTMLAllCollection: !1,
    HTMLCollection: !1,
    HTMLFormElement: !1,
    HTMLSelectElement: !1,
    MediaList: !0,
    MimeTypeArray: !1,
    NamedNodeMap: !1,
    NodeList: !0,
    PaintRequestList: !1,
    Plugin: !1,
    PluginArray: !1,
    SVGLengthList: !1,
    SVGNumberList: !1,
    SVGPathSegList: !1,
    SVGPointList: !1,
    SVGStringList: !1,
    SVGTransformList: !1,
    SourceBufferList: !1,
    StyleSheetList: !0,
    TextTrackCueList: !1,
    TextTrackList: !1,
    TouchList: !1
  }, d = i(p), v = 0; v < d.length; v++) {
    var y,
        m = d[v],
        P = p[m],
        g = s[m],
        _ = g && g.prototype;

    if (_ && (_[f] || u(_, f, h), _[c] || u(_, c, m), a[m] = h, P)) for (y in r) _[y] || o(_, y, r[y], !0);
  }
}, function (t, e, n) {
  (function (e) {
    !function (e) {
      "use strict";

      var n,
          r = Object.prototype,
          i = r.hasOwnProperty,
          o = "function" == typeof Symbol ? Symbol : {},
          s = o.iterator || "@@iterator",
          u = o.asyncIterator || "@@asyncIterator",
          a = o.toStringTag || "@@toStringTag",
          l = "object" == typeof t,
          f = e.regeneratorRuntime;
      if (f) l && (t.exports = f);else {
        (f = e.regeneratorRuntime = l ? t.exports : {}).wrap = _;
        var c = "suspendedStart",
            h = "suspendedYield",
            p = "executing",
            d = "completed",
            v = {},
            y = {};

        y[s] = function () {
          return this || _global;
        };

        var m = Object.getPrototypeOf,
            P = m && m(m(w([])));
        P && P !== r && i.call(P, s) && (y = P);
        var g = E.prototype = I.prototype = Object.create(y);
        C.prototype = g.constructor = E, E.constructor = C, E[a] = C.displayName = "GeneratorFunction", f.isGeneratorFunction = function (t) {
          var e = "function" == typeof t && t.constructor;
          return !!e && (e === C || "GeneratorFunction" === (e.displayName || e.name));
        }, f.mark = function (t) {
          return Object.setPrototypeOf ? Object.setPrototypeOf(t, E) : (t.__proto__ = E, a in t || (t[a] = "GeneratorFunction")), t.prototype = Object.create(g), t;
        }, f.awrap = function (t) {
          return {
            __await: t
          };
        }, L(S.prototype), S.prototype[u] = function () {
          return this || _global;
        }, f.AsyncIterator = S, f.async = function (t, e, n, r) {
          var i = new S(_(t, e, n, r));
          return f.isGeneratorFunction(e) ? i : i.next().then(function (t) {
            return t.done ? t.value : i.next();
          });
        }, L(g), g[a] = "Generator", g[s] = function () {
          return this || _global;
        }, g.toString = function () {
          return "[object Generator]";
        }, f.keys = function (t) {
          var e = [];

          for (var n in t) e.push(n);

          return e.reverse(), function n() {
            for (; e.length;) {
              var r = e.pop();
              if (r in t) return n.value = r, n.done = !1, n;
            }

            return n.done = !0, n;
          };
        }, f.values = w, N.prototype = {
          constructor: N,
          reset: function (t) {
            if ((this || _global).prev = 0, (this || _global).next = 0, (this || _global).sent = (this || _global)._sent = n, (this || _global).done = !1, (this || _global).delegate = null, (this || _global).method = "next", (this || _global).arg = n, (this || _global).tryEntries.forEach(T), !t) for (var e in this || _global) "t" === e.charAt(0) && i.call(this || _global, e) && !isNaN(+e.slice(1)) && ((this || _global)[e] = n);
          },
          stop: function () {
            (this || _global).done = !0;
            var t = (this || _global).tryEntries[0].completion;
            if ("throw" === t.type) throw t.arg;
            return (this || _global).rval;
          },
          dispatchException: function (t) {
            if ((this || _global).done) throw t;
            var e = this || _global;

            function r(r, i) {
              return u.type = "throw", u.arg = t, e.next = r, i && (e.method = "next", e.arg = n), !!i;
            }

            for (var o = (this || _global).tryEntries.length - 1; o >= 0; --o) {
              var s = (this || _global).tryEntries[o],
                  u = s.completion;
              if ("root" === s.tryLoc) return r("end");

              if (s.tryLoc <= (this || _global).prev) {
                var a = i.call(s, "catchLoc"),
                    l = i.call(s, "finallyLoc");

                if (a && l) {
                  if ((this || _global).prev < s.catchLoc) return r(s.catchLoc, !0);
                  if ((this || _global).prev < s.finallyLoc) return r(s.finallyLoc);
                } else if (a) {
                  if ((this || _global).prev < s.catchLoc) return r(s.catchLoc, !0);
                } else {
                  if (!l) throw new Error("try statement without catch or finally");
                  if ((this || _global).prev < s.finallyLoc) return r(s.finallyLoc);
                }
              }
            }
          },
          abrupt: function (t, e) {
            for (var n = (this || _global).tryEntries.length - 1; n >= 0; --n) {
              var r = (this || _global).tryEntries[n];

              if (r.tryLoc <= (this || _global).prev && i.call(r, "finallyLoc") && (this || _global).prev < r.finallyLoc) {
                var o = r;
                break;
              }
            }

            o && ("break" === t || "continue" === t) && o.tryLoc <= e && e <= o.finallyLoc && (o = null);
            var s = o ? o.completion : {};
            return s.type = t, s.arg = e, o ? ((this || _global).method = "next", (this || _global).next = o.finallyLoc, v) : this.complete(s);
          },
          complete: function (t, e) {
            if ("throw" === t.type) throw t.arg;
            return "break" === t.type || "continue" === t.type ? (this || _global).next = t.arg : "return" === t.type ? ((this || _global).rval = (this || _global).arg = t.arg, (this || _global).method = "return", (this || _global).next = "end") : "normal" === t.type && e && ((this || _global).next = e), v;
          },
          finish: function (t) {
            for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
              var n = (this || _global).tryEntries[e];
              if (n.finallyLoc === t) return this.complete(n.completion, n.afterLoc), T(n), v;
            }
          },
          catch: function (t) {
            for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
              var n = (this || _global).tryEntries[e];

              if (n.tryLoc === t) {
                var r = n.completion;

                if ("throw" === r.type) {
                  var i = r.arg;
                  T(n);
                }

                return i;
              }
            }

            throw new Error("illegal catch attempt");
          },
          delegateYield: function (t, e, r) {
            return (this || _global).delegate = {
              iterator: w(t),
              resultName: e,
              nextLoc: r
            }, "next" === (this || _global).method && ((this || _global).arg = n), v;
          }
        };
      }

      function _(t, e, n, r) {
        var i = e && e.prototype instanceof I ? e : I,
            o = Object.create(i.prototype),
            s = new N(r || []);
        return o._invoke = function (t, e, n) {
          var r = c;
          return function (i, o) {
            if (r === p) throw new Error("Generator is already running");

            if (r === d) {
              if ("throw" === i) throw o;
              return A();
            }

            for (n.method = i, n.arg = o;;) {
              var s = n.delegate;

              if (s) {
                var u = b(s, n);

                if (u) {
                  if (u === v) continue;
                  return u;
                }
              }

              if ("next" === n.method) n.sent = n._sent = n.arg;else if ("throw" === n.method) {
                if (r === c) throw r = d, n.arg;
                n.dispatchException(n.arg);
              } else "return" === n.method && n.abrupt("return", n.arg);
              r = p;
              var a = x(t, e, n);

              if ("normal" === a.type) {
                if (r = n.done ? d : h, a.arg === v) continue;
                return {
                  value: a.arg,
                  done: n.done
                };
              }

              "throw" === a.type && (r = d, n.method = "throw", n.arg = a.arg);
            }
          };
        }(t, n, s), o;
      }

      function x(t, e, n) {
        try {
          return {
            type: "normal",
            arg: t.call(e, n)
          };
        } catch (t) {
          return {
            type: "throw",
            arg: t
          };
        }
      }

      function I() {}

      function C() {}

      function E() {}

      function L(t) {
        ["next", "throw", "return"].forEach(function (e) {
          t[e] = function (t) {
            return this._invoke(e, t);
          };
        });
      }

      function S(t) {
        function n(e, r, o, s) {
          var u = x(t[e], t, r);

          if ("throw" !== u.type) {
            var a = u.arg,
                l = a.value;
            return l && "object" == typeof l && i.call(l, "__await") ? Promise.resolve(l.__await).then(function (t) {
              n("next", t, o, s);
            }, function (t) {
              n("throw", t, o, s);
            }) : Promise.resolve(l).then(function (t) {
              a.value = t, o(a);
            }, s);
          }

          s(u.arg);
        }

        var r;
        "object" == typeof e.process && e.process.domain && (n = e.process.domain.bind(n)), (this || _global)._invoke = function (t, e) {
          function i() {
            return new Promise(function (r, i) {
              n(t, e, r, i);
            });
          }

          return r = r ? r.then(i, i) : i();
        };
      }

      function b(t, e) {
        var r = t.iterator[e.method];

        if (r === n) {
          if (e.delegate = null, "throw" === e.method) {
            if (t.iterator.return && (e.method = "return", e.arg = n, b(t, e), "throw" === e.method)) return v;
            e.method = "throw", e.arg = new TypeError("The iterator does not provide a 'throw' method");
          }

          return v;
        }

        var i = x(r, t.iterator, e.arg);
        if ("throw" === i.type) return e.method = "throw", e.arg = i.arg, e.delegate = null, v;
        var o = i.arg;
        return o ? o.done ? (e[t.resultName] = o.value, e.next = t.nextLoc, "return" !== e.method && (e.method = "next", e.arg = n), e.delegate = null, v) : o : (e.method = "throw", e.arg = new TypeError("iterator result is not an object"), e.delegate = null, v);
      }

      function O(t) {
        var e = {
          tryLoc: t[0]
        };
        1 in t && (e.catchLoc = t[1]), 2 in t && (e.finallyLoc = t[2], e.afterLoc = t[3]), (this || _global).tryEntries.push(e);
      }

      function T(t) {
        var e = t.completion || {};
        e.type = "normal", delete e.arg, t.completion = e;
      }

      function N(t) {
        (this || _global).tryEntries = [{
          tryLoc: "root"
        }], t.forEach(O, this || _global), this.reset(!0);
      }

      function w(t) {
        if (t) {
          var e = t[s];
          if (e) return e.call(t);
          if ("function" == typeof t.next) return t;

          if (!isNaN(t.length)) {
            var r = -1,
                o = function e() {
              for (; ++r < t.length;) if (i.call(t, r)) return e.value = t[r], e.done = !1, e;

              return e.value = n, e.done = !0, e;
            };

            return o.next = o;
          }
        }

        return {
          next: A
        };
      }

      function A() {
        return {
          value: n,
          done: !0
        };
      }
    }("object" == typeof e ? e : "object" == typeof window ? window : "object" == typeof self ? self : this || _global);
  }).call(e, n(90));
}, function (t, e, n) {
  n(330), t.exports = n(21).RegExp.escape;
}, function (t, e, n) {
  var r = n(0),
      i = n(331)(/[\\^$*+?.()|[\]{}]/g, "\\$&");
  r(r.S, "RegExp", {
    escape: function (t) {
      return i(t);
    }
  });
}, function (t, e) {
  t.exports = function (t, e) {
    var n = e === Object(e) ? function (t) {
      return e[t];
    } : e;
    return function (e) {
      return String(e).replace(t, n);
    };
  };
}, function (t, e, n) {
  "use strict";

  Object.defineProperty(e, "__esModule", {
    value: !0
  });
  var r = s(n(124)),
      i = s(n(333)),
      o = n(334);

  function s(t) {
    return t && t.__esModule ? t : {
      default: t
    };
  }

  function u(t) {
    return function () {
      var e = t.apply(this || _global, arguments);
      return new Promise(function (t, n) {
        return function r(i, o) {
          try {
            var s = e[i](o),
                u = s.value;
          } catch (t) {
            return void n(t);
          }

          if (!s.done) return Promise.resolve(u).then(function (t) {
            r("next", t);
          }, function (t) {
            r("throw", t);
          });
          t(u);
        }("next");
      });
    };
  }

  n(335), n(336);
  var a = r.default.FeatureGroup.extend({
    options: {
      debug: !1,
      minZoom: 15,
      endPoint: "https://overpass-api.de/api/",
      query: "(node({{bbox}})[organic];node({{bbox}})[second_hand];);out qt;",
      loadedBounds: [],
      markerIcon: null,
      timeout: 30000,
      retryOnTimeout: !1,
      noInitialRequest: !1,
      cacheEnabled: !1,
      cacheTTL: 1800,
      beforeRequest: function () {},
      afterRequest: function () {},
      onSuccess: function (t) {
        for (var e = 0; e < t.elements.length; e++) {
          var n = void 0,
              i = void 0,
              o = t.elements[e];

          if (!(o.id in (this || _global)._ids)) {
            (this || _global)._ids[o.id] = !0, n = "node" === o.type ? r.default.latLng(o.lat, o.lon) : r.default.latLng(o.center.lat, o.center.lon), i = (this || _global).options.markerIcon ? r.default.marker(n, {
              icon: (this || _global).options.markerIcon
            }) : r.default.circle(n, 20, {
              stroke: !1,
              fillColor: "#E54041",
              fillOpacity: 0.9
            });

            var s = this._getPoiPopupHTML(o.tags, o.id),
                u = r.default.popup().setContent(s);

            i.bindPopup(u), (this || _global)._markers.addLayer(i);
          }
        }
      },
      onError: function () {},
      onTimeout: function () {},
      minZoomIndicatorEnabled: !0,
      minZoomIndicatorOptions: {
        minZoomMessageNoLayer: "No layer assigned",
        minZoomMessage: "Current zoom Level: CURRENTZOOM. Data are visible at Level: MINZOOMLEVEL."
      }
    },
    initialize: function (t) {
      r.default.Util.setOptions(this || _global, t), (this || _global)._ids = {}, (this || _global)._loadedBounds = t.loadedBounds || [], (this || _global)._requestInProgress = !1, (this || _global).options.cacheEnabled && this._initCacheDb();
    },
    _initCacheDb: function () {
      var t = this || _global;
      return u(regeneratorRuntime.mark(function e() {
        return regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              return e.next = 2, (0, o.openDB)("leaflet-overpass-layer", 1, {
                upgrade: function (t) {
                  t.createObjectStore("cache", {
                    autoIncrement: !0
                  });
                }
              });

            case 2:
              t._cacheDb = e.sent, !!t._map && t._loadCachedItems();

            case 5:
            case "end":
              return e.stop();
          }
        }, e, t);
      }))();
    },
    _getPoiPopupHTML: function (t, e) {
      var n = void 0,
          r = document.createElement("a"),
          i = document.createElement("table"),
          o = document.createElement("div");

      for (var s in r.href = "https://www.openstreetmap.org/edit?editor=id&node=" + e, r.appendChild(document.createTextNode("Edit this entry in iD")), i.style.borderSpacing = "10px", i.style.borderCollapse = "separate", t) (n = i.insertRow(0)).insertCell(0).appendChild(document.createTextNode(s)), n.insertCell(1).appendChild(document.createTextNode(t[s]));

      return o.appendChild(r), o.appendChild(i), o;
    },
    _buildRequestBox: function (t) {
      return r.default.rectangle(t, {
        bounds: t,
        color: "#204a87",
        stroke: !1,
        fillOpacity: 0.1,
        clickable: !1
      });
    },
    _addRequestBox: function (t) {
      return (this || _global)._requestBoxes.addLayer(t);
    },
    _getRequestBoxes: function () {
      return (this || _global)._requestBoxes.getLayers();
    },
    _removeRequestBox: function (t) {
      (this || _global)._requestBoxes.removeLayer(t);
    },
    _removeRequestBoxes: function () {
      return (this || _global)._requestBoxes.clearLayers();
    },
    _addResponseBox: function (t) {
      return (this || _global)._responseBoxes.addLayer(t);
    },
    _addResponseBoxes: function (t) {
      var e = this || _global;
      this._removeRequestBoxes(), t.forEach(function (t) {
        t.setStyle({
          color: "black",
          weight: 2
        }), e._addResponseBox(t);
      });
    },
    _isFullyLoadedBounds: function (t, e) {
      if (0 === e.length) return !1;

      var n = this._buildClipsFromBounds([t]),
          r = this._buildClipsFromBounds(e),
          o = new i.default.Clipper(),
          s = new i.default.PolyTree();

      return o.AddPaths(n, i.default.PolyType.ptSubject, !0), o.AddPaths(r, i.default.PolyType.ptClip, !0), o.Execute(i.default.ClipType.ctDifference, s, i.default.PolyFillType.pftNonZero, i.default.PolyFillType.pftNonZero), 0 === i.default.JS.PolyTreeToExPolygons(s).length;
    },
    _getLoadedBounds: function () {
      return (this || _global)._loadedBounds;
    },
    _addLoadedBounds: function (t) {
      (this || _global)._loadedBounds.push(t);
    },
    _buildClipsFromBounds: function (t) {
      return t.map(function (t) {
        return [{
          X: 1000000 * t._southWest.lng,
          Y: 1000000 * t._southWest.lat
        }, {
          X: 1000000 * t._southWest.lng,
          Y: 1000000 * t._northEast.lat
        }, {
          X: 1000000 * t._northEast.lng,
          Y: 1000000 * t._northEast.lat
        }, {
          X: 1000000 * t._northEast.lng,
          Y: 1000000 * t._southWest.lat
        }];
      });
    },
    _buildBoundsFromClips: function (t) {
      return t.map(function (t) {
        return r.default.latLngBounds(r.default.latLng(t[0].Y / 1000000, t[0].X / 1000000).wrap(), r.default.latLng(t[2].Y / 1000000, t[2].X / 1000000).wrap());
      });
    },
    _buildOverpassQueryFromQueryAndBounds: function (t, e) {
      var n = e._southWest,
          r = e._northEast,
          i = [n.lat, n.lng, r.lat, r.lng].join(",");
      return t = (t = t.replace(/(\/\/.*)/g, "")).replace(/(\{\{bbox\}\})/g, i);
    },
    _buildOverpassUrlFromEndPointAndQuery: function (t, e) {
      return t + "interpreter?data=[out:json];" + e;
    },
    _buildLargerBounds: function (t) {
      var e = Math.abs(t._northEast.lng - t._southWest.lng),
          n = Math.abs(t._northEast.lat - t._southWest.lat),
          i = e > n ? e : n;
      return t._southWest.lat -= i / 2, t._southWest.lng -= i / 2, t._northEast.lat += i / 2, t._northEast.lng += i / 2, r.default.latLngBounds(r.default.latLng(t._southWest.lat, t._southWest.lng).wrap(), r.default.latLng(t._northEast.lat, t._northEast.lng).wrap());
    },
    _setRequestInProgress: function (t) {
      (this || _global)._requestInProgress = t;
    },
    _isRequestInProgress: function () {
      return (this || _global)._requestInProgress;
    },
    _hasNextRequest: function () {
      return !!(this || _global)._nextRequest;
    },
    _getNextRequest: function () {
      return (this || _global)._nextRequest;
    },
    _setNextRequest: function (t) {
      (this || _global)._nextRequest = t;
    },
    _removeNextRequest: function () {
      (this || _global)._nextRequest = null;
    },
    _prepareRequest: function () {
      if ((this || _global)._map.getZoom() < (this || _global).options.minZoom) return !1;

      var t = this._buildLargerBounds((this || _global)._map.getBounds()),
          e = (this || _global)._sendRequest.bind(this || _global, t);

      this._isRequestInProgress() ? this._setNextRequest(e) : (this._removeNextRequest(), e());
    },
    _sendRequest: function (t) {
      var e = this || _global,
          n = this._getLoadedBounds();

      if (this._isFullyLoadedBounds(t, n)) this._setRequestInProgress(!1);else {
        var r = this._buildLargerBounds(t),
            i = this._buildOverpassUrlFromEndPointAndQuery((this || _global).options.endPoint, this._buildOverpassQueryFromQueryAndBounds((this || _global).options.query, r)),
            o = new XMLHttpRequest();

        !1 !== (this || _global).options.beforeRequest.call(this || _global) ? (this._setRequestInProgress(!0), (this || _global).options.debug && this._addRequestBox(this._buildRequestBox(r)), o.open("GET", i, !0), o.timeout = (this || _global).options.timeout, o.ontimeout = function () {
          return e._onRequestTimeout(o, i, r);
        }, o.onload = function () {
          return e._onRequestLoad(o, r);
        }, o.send()) : (this || _global).options.afterRequest.call(this || _global);
      }
    },
    _onRequestLoad: function (t, e) {
      var n = this || _global;

      if (t.status >= 200 && t.status < 400) {
        var i = JSON.parse(t.response);

        (this || _global).options.onSuccess.call(this || _global, i);

        var o = void 0 !== (this || _global)._cacheDb;

        if ((this || _global).options.cacheEnabled && o) {
          var s = new Date();
          s.setSeconds(s.getSeconds() + (this || _global).options.cacheTTL), this._getCachedItems().then(function (t) {
            var o = void 0;
            t.forEach(function (t, i) {
              var s = t.query === n.options.query,
                  u = r.default.latLng(t.bounds._northEast),
                  a = r.default.latLng(t.bounds._southWest),
                  l = r.default.latLngBounds(u, a);
              s && e.equals(l) && (o = i);
            }), n._cacheDb.put("cache", {
              query: n.options.query,
              result: i,
              bounds: e,
              expires: s
            }, o);
          });
        }

        this._onRequestLoadCallback(e);
      } else this._onRequestErrorCallback(e), (this || _global).options.onError.call(this || _global, t);

      this._onRequestCompleteCallback(e);
    },
    _onRequestTimeout: function (t, e, n) {
      (this || _global).options.onTimeout.call(this || _global, t), (this || _global).options.retryOnTimeout ? this._sendRequest(e) : (this._onRequestErrorCallback(n), this._onRequestCompleteCallback(n));
    },
    _onRequestLoadCallback: function (t) {
      this._addLoadedBounds(t), (this || _global).options.debug && this._addResponseBoxes(this._getRequestBoxes());
    },
    _onRequestErrorCallback: function (t) {
      (this || _global).options.debug && this._removeRequestBox(this._buildRequestBox(t));
    },
    _onRequestCompleteCallback: function () {
      if ((this || _global).options.afterRequest.call(this || _global), this._hasNextRequest()) {
        var t = this._getNextRequest();

        this._removeNextRequest(), t();
      } else this._setRequestInProgress(!1);
    },
    _getCachedItems: function () {
      var t = this || _global;
      return u(regeneratorRuntime.mark(function e() {
        var n, r, i;
        return regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if (void 0 !== t._cacheDb) {
                e.next = 3;
                break;
              }

              return e.abrupt("return");

            case 3:
              return e.next = 5, t._cacheDb.getAllKeys("cache");

            case 5:
              return n = e.sent, e.next = 8, t._cacheDb.getAll("cache");

            case 8:
              return r = e.sent, i = new Map(), r.forEach(function (t, e) {
                var r = n[e];
                i.set(r, t);
              }), e.abrupt("return", i);

            case 12:
            case "end":
              return e.stop();
          }
        }, e, t);
      }))();
    },
    _loadCachedItems: function () {
      var t = this || _global;
      return u(regeneratorRuntime.mark(function e() {
        return regeneratorRuntime.wrap(function (e) {
          for (;;) switch (e.prev = e.next) {
            case 0:
              if (void 0 !== t._cacheDb && t._map) {
                e.next = 3;
                break;
              }

              return e.abrupt("return");

            case 3:
              return e.next = 5, t._getCachedItems();

            case 5:
              e.sent.forEach(function (e, n) {
                new Date() > e.expires ? t._cacheDb.delete("cache", n) : e.query === t.options.query && (t.options.onSuccess.call(t, e.result), t._onRequestLoadCallback(e.bounds));
              });

            case 7:
            case "end":
              return e.stop();
          }
        }, e, t);
      }))();
    },
    onAdd: function (t) {
      ((this || _global)._map = t, !0 === (this || _global).options.minZoomIndicatorEnabled && ((this || _global)._map.zoomIndicator ? ((this || _global)._zoomControl = (this || _global)._map.zoomIndicator, (this || _global)._zoomControl._addLayer(this || _global)) : ((this || _global)._zoomControl = new r.default.Control.MinZoomIndicator((this || _global).options.minZoomIndicatorOptions), (this || _global)._map.addControl((this || _global)._zoomControl), (this || _global)._zoomControl._addLayer(this || _global))), (this || _global).options.debug && ((this || _global)._requestBoxes = r.default.featureGroup().addTo((this || _global)._map), (this || _global)._responseBoxes = r.default.featureGroup().addTo((this || _global)._map)), (this || _global)._markers = r.default.featureGroup().addTo((this || _global)._map), (this || _global).options.cacheEnabled) && void 0 !== (this || _global)._cacheDb && this._loadCachedItems();
      (this || _global).options.noInitialRequest || this._prepareRequest(), (this || _global)._map.on("moveend", (this || _global)._prepareRequest, this || _global);
    },
    onRemove: function (t) {
      r.default.LayerGroup.prototype.onRemove.call(this || _global, t), !0 === (this || _global).options.minZoomIndicatorEnabled && (this || _global)._map.zoomIndicator && ((this || _global)._zoomControl._removeLayer(this || _global), (this || _global)._map.removeControl((this || _global)._zoomControl), (this || _global)._zoomControl = null, (this || _global)._map.zoomIndicator = null), (this || _global).options.debug && ((this || _global)._map.removeLayer((this || _global)._requestBoxes), (this || _global)._map.removeLayer((this || _global)._responseBoxes)), this._resetData(), (this || _global)._map.removeLayer((this || _global)._markers), (this || _global)._map.off("moveend", (this || _global)._prepareRequest, this || _global);
    },
    setQuery: function (t) {
      (this || _global).options.query = t, this._resetData(), this._prepareRequest();
    },
    _resetData: function () {
      (this || _global)._ids = {}, (this || _global)._loadedBounds = [], (this || _global)._requestInProgress = !1, (this || _global)._markers.clearLayers(), (this || _global).options.debug && ((this || _global)._requestBoxes.clearLayers(), (this || _global)._responseBoxes.clearLayers());
    },
    getData: function () {
      return (this || _global)._data;
    }
  });
  r.default.OverPassLayer = a, r.default.overpassLayer = function (t) {
    return new r.default.OverPassLayer(t);
  }, e.default = a;
}, function (t, e, n) {
  "use strict";

  var r,
      i = {},
      o = !1;

  if (void 0 !== t && t.exports ? (t.exports = i, o = !0) : "undefined" != typeof document ? window.ClipperLib = i : self.ClipperLib = i, o) {
    s = "chrome";
    r = "Netscape";
  } else {
    var s = navigator.userAgent.toString().toLowerCase();
    r = navigator.appName;
  }

  var u,
      a = {};
  -1 != s.indexOf("chrome") && -1 == s.indexOf("chromium") ? a.chrome = 1 : a.chrome = 0, -1 != s.indexOf("chromium") ? a.chromium = 1 : a.chromium = 0, -1 != s.indexOf("safari") && -1 == s.indexOf("chrome") && -1 == s.indexOf("chromium") ? a.safari = 1 : a.safari = 0, -1 != s.indexOf("firefox") ? a.firefox = 1 : a.firefox = 0, -1 != s.indexOf("firefox/17") ? a.firefox17 = 1 : a.firefox17 = 0, -1 != s.indexOf("firefox/15") ? a.firefox15 = 1 : a.firefox15 = 0, -1 != s.indexOf("firefox/3") ? a.firefox3 = 1 : a.firefox3 = 0, -1 != s.indexOf("opera") ? a.opera = 1 : a.opera = 0, -1 != s.indexOf("msie 10") ? a.msie10 = 1 : a.msie10 = 0, -1 != s.indexOf("msie 9") ? a.msie9 = 1 : a.msie9 = 0, -1 != s.indexOf("msie 8") ? a.msie8 = 1 : a.msie8 = 0, -1 != s.indexOf("msie 7") ? a.msie7 = 1 : a.msie7 = 0, -1 != s.indexOf("msie ") ? a.msie = 1 : a.msie = 0, i.biginteger_used = null;

  function l(t, e, n) {
    i.biginteger_used = 1, null != t && ("number" == typeof t && void 0 === e ? this.fromInt(t) : "number" == typeof t ? this.fromNumber(t, e, n) : null == e && "string" != typeof t ? this.fromString(t, 256) : this.fromString(t, e));
  }

  function f() {
    return new l(null);
  }

  "Microsoft Internet Explorer" == r ? (l.prototype.am = function (t, e, n, r, i, o) {
    for (var s = 32767 & e, u = e >> 15; --o >= 0;) {
      var a = 32767 & (this || _global)[t],
          l = (this || _global)[t++] >> 15,
          f = u * a + l * s;
      i = ((a = s * a + ((32767 & f) << 15) + n[r] + (1073741823 & i)) >>> 30) + (f >>> 15) + u * l + (i >>> 30), n[r++] = 1073741823 & a;
    }

    return i;
  }, u = 30) : "Netscape" != r ? (l.prototype.am = function (t, e, n, r, i, o) {
    for (; --o >= 0;) {
      var s = e * (this || _global)[t++] + n[r] + i;
      i = Math.floor(s / 67108864), n[r++] = 67108863 & s;
    }

    return i;
  }, u = 26) : (l.prototype.am = function (t, e, n, r, i, o) {
    for (var s = 16383 & e, u = e >> 14; --o >= 0;) {
      var a = 16383 & (this || _global)[t],
          l = (this || _global)[t++] >> 14,
          f = u * a + l * s;
      i = ((a = s * a + ((16383 & f) << 14) + n[r] + i) >> 28) + (f >> 14) + u * l, n[r++] = 268435455 & a;
    }

    return i;
  }, u = 28), l.prototype.DB = u, l.prototype.DM = (1 << u) - 1, l.prototype.DV = 1 << u;
  l.prototype.FV = Math.pow(2, 52), l.prototype.F1 = 52 - u, l.prototype.F2 = 2 * u - 52;
  var c,
      h,
      p = "0123456789abcdefghijklmnopqrstuvwxyz",
      d = new Array();

  for (c = "0".charCodeAt(0), h = 0; h <= 9; ++h) d[c++] = h;

  for (c = "a".charCodeAt(0), h = 10; h < 36; ++h) d[c++] = h;

  for (c = "A".charCodeAt(0), h = 10; h < 36; ++h) d[c++] = h;

  function v(t) {
    return p.charAt(t);
  }

  function y(t, e) {
    var n = d[t.charCodeAt(e)];
    return null == n ? -1 : n;
  }

  function m(t) {
    var e = f();
    return e.fromInt(t), e;
  }

  function P(t) {
    var e,
        n = 1;
    return 0 != (e = t >>> 16) && (t = e, n += 16), 0 != (e = t >> 8) && (t = e, n += 8), 0 != (e = t >> 4) && (t = e, n += 4), 0 != (e = t >> 2) && (t = e, n += 2), 0 != (e = t >> 1) && (t = e, n += 1), n;
  }

  function g(t) {
    (this || _global).m = t;
  }

  function _(t) {
    (this || _global).m = t, (this || _global).mp = t.invDigit(), (this || _global).mpl = 32767 & (this || _global).mp, (this || _global).mph = (this || _global).mp >> 15, (this || _global).um = (1 << t.DB - 15) - 1, (this || _global).mt2 = 2 * t.t;
  }

  function x(t, e) {
    return t & e;
  }

  function I(t, e) {
    return t | e;
  }

  function C(t, e) {
    return t ^ e;
  }

  function E(t, e) {
    return t & ~e;
  }

  function L(t) {
    if (0 == t) return -1;
    var e = 0;
    return 0 == (65535 & t) && (t >>= 16, e += 16), 0 == (255 & t) && (t >>= 8, e += 8), 0 == (15 & t) && (t >>= 4, e += 4), 0 == (3 & t) && (t >>= 2, e += 2), 0 == (1 & t) && ++e, e;
  }

  function S(t) {
    for (var e = 0; 0 != t;) t &= t - 1, ++e;

    return e;
  }

  function b() {}

  function O(t) {
    return t;
  }

  function T(t) {
    (this || _global).r2 = f(), (this || _global).q3 = f(), l.ONE.dlShiftTo(2 * t.t, (this || _global).r2), (this || _global).mu = (this || _global).r2.divide(t), (this || _global).m = t;
  }

  g.prototype.convert = function (t) {
    return t.s < 0 || t.compareTo((this || _global).m) >= 0 ? t.mod((this || _global).m) : t;
  }, g.prototype.revert = function (t) {
    return t;
  }, g.prototype.reduce = function (t) {
    t.divRemTo((this || _global).m, null, t);
  }, g.prototype.mulTo = function (t, e, n) {
    t.multiplyTo(e, n), this.reduce(n);
  }, g.prototype.sqrTo = function (t, e) {
    t.squareTo(e), this.reduce(e);
  }, _.prototype.convert = function (t) {
    var e = f();
    return t.abs().dlShiftTo((this || _global).m.t, e), e.divRemTo((this || _global).m, null, e), t.s < 0 && e.compareTo(l.ZERO) > 0 && (this || _global).m.subTo(e, e), e;
  }, _.prototype.revert = function (t) {
    var e = f();
    return t.copyTo(e), this.reduce(e), e;
  }, _.prototype.reduce = function (t) {
    for (; t.t <= (this || _global).mt2;) t[t.t++] = 0;

    for (var e = 0; e < (this || _global).m.t; ++e) {
      var n = 32767 & t[e],
          r = n * (this || _global).mpl + ((n * (this || _global).mph + (t[e] >> 15) * (this || _global).mpl & (this || _global).um) << 15) & t.DM;

      for (t[n = e + (this || _global).m.t] += (this || _global).m.am(0, r, t, e, 0, (this || _global).m.t); t[n] >= t.DV;) t[n] -= t.DV, t[++n]++;
    }

    t.clamp(), t.drShiftTo((this || _global).m.t, t), t.compareTo((this || _global).m) >= 0 && t.subTo((this || _global).m, t);
  }, _.prototype.mulTo = function (t, e, n) {
    t.multiplyTo(e, n), this.reduce(n);
  }, _.prototype.sqrTo = function (t, e) {
    t.squareTo(e), this.reduce(e);
  }, l.prototype.copyTo = function (t) {
    for (var e = (this || _global).t - 1; e >= 0; --e) t[e] = (this || _global)[e];

    t.t = (this || _global).t, t.s = (this || _global).s;
  }, l.prototype.fromInt = function (t) {
    (this || _global).t = 1, (this || _global).s = t < 0 ? -1 : 0, t > 0 ? (this || _global)[0] = t : t < -1 ? (this || _global)[0] = t + (this || _global).DV : (this || _global).t = 0;
  }, l.prototype.fromString = function (t, e) {
    var n;
    if (16 == e) n = 4;else if (8 == e) n = 3;else if (256 == e) n = 8;else if (2 == e) n = 1;else if (32 == e) n = 5;else {
      if (4 != e) return void this.fromRadix(t, e);
      n = 2;
    }
    (this || _global).t = 0, (this || _global).s = 0;

    for (var r = t.length, i = !1, o = 0; --r >= 0;) {
      var s = 8 == n ? 255 & t[r] : y(t, r);
      s < 0 ? "-" == t.charAt(r) && (i = !0) : (i = !1, 0 == o ? (this || _global)[(this || _global).t++] = s : o + n > (this || _global).DB ? ((this || _global)[(this || _global).t - 1] |= (s & (1 << (this || _global).DB - o) - 1) << o, (this || _global)[(this || _global).t++] = s >> (this || _global).DB - o) : (this || _global)[(this || _global).t - 1] |= s << o, (o += n) >= (this || _global).DB && (o -= (this || _global).DB));
    }

    8 == n && 0 != (128 & t[0]) && ((this || _global).s = -1, o > 0 && ((this || _global)[(this || _global).t - 1] |= (1 << (this || _global).DB - o) - 1 << o)), this.clamp(), i && l.ZERO.subTo(this || _global, this || _global);
  }, l.prototype.clamp = function () {
    for (var t = (this || _global).s & (this || _global).DM; (this || _global).t > 0 && (this || _global)[(this || _global).t - 1] == t;) --(this || _global).t;
  }, l.prototype.dlShiftTo = function (t, e) {
    var n;

    for (n = (this || _global).t - 1; n >= 0; --n) e[n + t] = (this || _global)[n];

    for (n = t - 1; n >= 0; --n) e[n] = 0;

    e.t = (this || _global).t + t, e.s = (this || _global).s;
  }, l.prototype.drShiftTo = function (t, e) {
    for (var n = t; n < (this || _global).t; ++n) e[n - t] = (this || _global)[n];

    e.t = Math.max((this || _global).t - t, 0), e.s = (this || _global).s;
  }, l.prototype.lShiftTo = function (t, e) {
    var n,
        r = t % (this || _global).DB,
        i = (this || _global).DB - r,
        o = (1 << i) - 1,
        s = Math.floor(t / (this || _global).DB),
        u = (this || _global).s << r & (this || _global).DM;

    for (n = (this || _global).t - 1; n >= 0; --n) e[n + s + 1] = (this || _global)[n] >> i | u, u = ((this || _global)[n] & o) << r;

    for (n = s - 1; n >= 0; --n) e[n] = 0;

    e[s] = u, e.t = (this || _global).t + s + 1, e.s = (this || _global).s, e.clamp();
  }, l.prototype.rShiftTo = function (t, e) {
    e.s = (this || _global).s;
    var n = Math.floor(t / (this || _global).DB);
    if (n >= (this || _global).t) e.t = 0;else {
      var r = t % (this || _global).DB,
          i = (this || _global).DB - r,
          o = (1 << r) - 1;
      e[0] = (this || _global)[n] >> r;

      for (var s = n + 1; s < (this || _global).t; ++s) e[s - n - 1] |= ((this || _global)[s] & o) << i, e[s - n] = (this || _global)[s] >> r;

      r > 0 && (e[(this || _global).t - n - 1] |= ((this || _global).s & o) << i), e.t = (this || _global).t - n, e.clamp();
    }
  }, l.prototype.subTo = function (t, e) {
    for (var n = 0, r = 0, i = Math.min(t.t, (this || _global).t); n < i;) r += (this || _global)[n] - t[n], e[n++] = r & (this || _global).DM, r >>= (this || _global).DB;

    if (t.t < (this || _global).t) {
      for (r -= t.s; n < (this || _global).t;) r += (this || _global)[n], e[n++] = r & (this || _global).DM, r >>= (this || _global).DB;

      r += (this || _global).s;
    } else {
      for (r += (this || _global).s; n < t.t;) r -= t[n], e[n++] = r & (this || _global).DM, r >>= (this || _global).DB;

      r -= t.s;
    }

    e.s = r < 0 ? -1 : 0, r < -1 ? e[n++] = (this || _global).DV + r : r > 0 && (e[n++] = r), e.t = n, e.clamp();
  }, l.prototype.multiplyTo = function (t, e) {
    var n = this.abs(),
        r = t.abs(),
        i = n.t;

    for (e.t = i + r.t; --i >= 0;) e[i] = 0;

    for (i = 0; i < r.t; ++i) e[i + n.t] = n.am(0, r[i], e, i, 0, n.t);

    e.s = 0, e.clamp(), (this || _global).s != t.s && l.ZERO.subTo(e, e);
  }, l.prototype.squareTo = function (t) {
    for (var e = this.abs(), n = t.t = 2 * e.t; --n >= 0;) t[n] = 0;

    for (n = 0; n < e.t - 1; ++n) {
      var r = e.am(n, e[n], t, 2 * n, 0, 1);
      (t[n + e.t] += e.am(n + 1, 2 * e[n], t, 2 * n + 1, r, e.t - n - 1)) >= e.DV && (t[n + e.t] -= e.DV, t[n + e.t + 1] = 1);
    }

    t.t > 0 && (t[t.t - 1] += e.am(n, e[n], t, 2 * n, 0, 1)), t.s = 0, t.clamp();
  }, l.prototype.divRemTo = function (t, e, n) {
    var r = t.abs();

    if (!(r.t <= 0)) {
      var i = this.abs();
      if (i.t < r.t) return null != e && e.fromInt(0), void (null != n && this.copyTo(n));
      null == n && (n = f());
      var o = f(),
          s = (this || _global).s,
          u = t.s,
          a = (this || _global).DB - P(r[r.t - 1]);
      a > 0 ? (r.lShiftTo(a, o), i.lShiftTo(a, n)) : (r.copyTo(o), i.copyTo(n));
      var c = o.t,
          h = o[c - 1];

      if (0 != h) {
        var p = h * (1 << (this || _global).F1) + (c > 1 ? o[c - 2] >> (this || _global).F2 : 0),
            d = (this || _global).FV / p,
            v = (1 << (this || _global).F1) / p,
            y = 1 << (this || _global).F2,
            m = n.t,
            g = m - c,
            _ = null == e ? f() : e;

        for (o.dlShiftTo(g, _), n.compareTo(_) >= 0 && (n[n.t++] = 1, n.subTo(_, n)), l.ONE.dlShiftTo(c, _), _.subTo(o, o); o.t < c;) o[o.t++] = 0;

        for (; --g >= 0;) {
          var x = n[--m] == h ? (this || _global).DM : Math.floor(n[m] * d + (n[m - 1] + y) * v);
          if ((n[m] += o.am(0, x, n, g, 0, c)) < x) for (o.dlShiftTo(g, _), n.subTo(_, n); n[m] < --x;) n.subTo(_, n);
        }

        null != e && (n.drShiftTo(c, e), s != u && l.ZERO.subTo(e, e)), n.t = c, n.clamp(), a > 0 && n.rShiftTo(a, n), s < 0 && l.ZERO.subTo(n, n);
      }
    }
  }, l.prototype.invDigit = function () {
    if ((this || _global).t < 1) return 0;
    var t = (this || _global)[0];
    if (0 == (1 & t)) return 0;
    var e = 3 & t;
    return (e = (e = (e = (e = e * (2 - (15 & t) * e) & 15) * (2 - (255 & t) * e) & 255) * (2 - ((65535 & t) * e & 65535)) & 65535) * (2 - t * e % (this || _global).DV) % (this || _global).DV) > 0 ? (this || _global).DV - e : -e;
  }, l.prototype.isEven = function () {
    return 0 == ((this || _global).t > 0 ? 1 & (this || _global)[0] : (this || _global).s);
  }, l.prototype.exp = function (t, e) {
    if (t > 4294967295 || t < 1) return l.ONE;
    var n = f(),
        r = f(),
        i = e.convert(this || _global),
        o = P(t) - 1;

    for (i.copyTo(n); --o >= 0;) if (e.sqrTo(n, r), (t & 1 << o) > 0) e.mulTo(r, i, n);else {
      var s = n;
      n = r, r = s;
    }

    return e.revert(n);
  }, l.prototype.toString = function (t) {
    if ((this || _global).s < 0) return "-" + this.negate().toString(t);
    var e;
    if (16 == t) e = 4;else if (8 == t) e = 3;else if (2 == t) e = 1;else if (32 == t) e = 5;else {
      if (4 != t) return this.toRadix(t);
      e = 2;
    }
    var n,
        r = (1 << e) - 1,
        i = !1,
        o = "",
        s = (this || _global).t,
        u = (this || _global).DB - s * (this || _global).DB % e;
    if (s-- > 0) for (u < (this || _global).DB && (n = (this || _global)[s] >> u) > 0 && (i = !0, o = v(n)); s >= 0;) u < e ? (n = ((this || _global)[s] & (1 << u) - 1) << e - u, n |= (this || _global)[--s] >> (u += (this || _global).DB - e)) : (n = (this || _global)[s] >> (u -= e) & r, u <= 0 && (u += (this || _global).DB, --s)), n > 0 && (i = !0), i && (o += v(n));
    return i ? o : "0";
  }, l.prototype.negate = function () {
    var t = f();
    return l.ZERO.subTo(this || _global, t), t;
  }, l.prototype.abs = function () {
    return (this || _global).s < 0 ? this.negate() : this || _global;
  }, l.prototype.compareTo = function (t) {
    var e = (this || _global).s - t.s;
    if (0 != e) return e;
    var n = (this || _global).t;
    if (0 != (e = n - t.t)) return (this || _global).s < 0 ? -e : e;

    for (; --n >= 0;) if (0 != (e = (this || _global)[n] - t[n])) return e;

    return 0;
  }, l.prototype.bitLength = function () {
    return (this || _global).t <= 0 ? 0 : (this || _global).DB * ((this || _global).t - 1) + P((this || _global)[(this || _global).t - 1] ^ (this || _global).s & (this || _global).DM);
  }, l.prototype.mod = function (t) {
    var e = f();
    return this.abs().divRemTo(t, null, e), (this || _global).s < 0 && e.compareTo(l.ZERO) > 0 && t.subTo(e, e), e;
  }, l.prototype.modPowInt = function (t, e) {
    var n;
    return n = t < 256 || e.isEven() ? new g(e) : new _(e), this.exp(t, n);
  }, l.ZERO = m(0), l.ONE = m(1), b.prototype.convert = O, b.prototype.revert = O, b.prototype.mulTo = function (t, e, n) {
    t.multiplyTo(e, n);
  }, b.prototype.sqrTo = function (t, e) {
    t.squareTo(e);
  }, T.prototype.convert = function (t) {
    if (t.s < 0 || t.t > 2 * (this || _global).m.t) return t.mod((this || _global).m);
    if (t.compareTo((this || _global).m) < 0) return t;
    var e = f();
    return t.copyTo(e), this.reduce(e), e;
  }, T.prototype.revert = function (t) {
    return t;
  }, T.prototype.reduce = function (t) {
    for (t.drShiftTo((this || _global).m.t - 1, (this || _global).r2), t.t > (this || _global).m.t + 1 && (t.t = (this || _global).m.t + 1, t.clamp()), (this || _global).mu.multiplyUpperTo((this || _global).r2, (this || _global).m.t + 1, (this || _global).q3), (this || _global).m.multiplyLowerTo((this || _global).q3, (this || _global).m.t + 1, (this || _global).r2); t.compareTo((this || _global).r2) < 0;) t.dAddOffset(1, (this || _global).m.t + 1);

    for (t.subTo((this || _global).r2, t); t.compareTo((this || _global).m) >= 0;) t.subTo((this || _global).m, t);
  }, T.prototype.mulTo = function (t, e, n) {
    t.multiplyTo(e, n), this.reduce(n);
  }, T.prototype.sqrTo = function (t, e) {
    t.squareTo(e), this.reduce(e);
  };
  var N = [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97, 101, 103, 107, 109, 113, 127, 131, 137, 139, 149, 151, 157, 163, 167, 173, 179, 181, 191, 193, 197, 199, 211, 223, 227, 229, 233, 239, 241, 251, 257, 263, 269, 271, 277, 281, 283, 293, 307, 311, 313, 317, 331, 337, 347, 349, 353, 359, 367, 373, 379, 383, 389, 397, 401, 409, 419, 421, 431, 433, 439, 443, 449, 457, 461, 463, 467, 479, 487, 491, 499, 503, 509, 521, 523, 541, 547, 557, 563, 569, 571, 577, 587, 593, 599, 601, 607, 613, 617, 619, 631, 641, 643, 647, 653, 659, 661, 673, 677, 683, 691, 701, 709, 719, 727, 733, 739, 743, 751, 757, 761, 769, 773, 787, 797, 809, 811, 821, 823, 827, 829, 839, 853, 857, 859, 863, 877, 881, 883, 887, 907, 911, 919, 929, 937, 941, 947, 953, 967, 971, 977, 983, 991, 997],
      w = (1 << 26) / N[N.length - 1];
  l.prototype.chunkSize = function (t) {
    return Math.floor(Math.LN2 * (this || _global).DB / Math.log(t));
  }, l.prototype.toRadix = function (t) {
    if (null == t && (t = 10), 0 == this.signum() || t < 2 || t > 36) return "0";
    var e = this.chunkSize(t),
        n = Math.pow(t, e),
        r = m(n),
        i = f(),
        o = f(),
        s = "";

    for (this.divRemTo(r, i, o); i.signum() > 0;) s = (n + o.intValue()).toString(t).substr(1) + s, i.divRemTo(r, i, o);

    return o.intValue().toString(t) + s;
  }, l.prototype.fromRadix = function (t, e) {
    this.fromInt(0), null == e && (e = 10);

    for (var n = this.chunkSize(e), r = Math.pow(e, n), i = !1, o = 0, s = 0, u = 0; u < t.length; ++u) {
      var a = y(t, u);
      a < 0 ? "-" == t.charAt(u) && 0 == this.signum() && (i = !0) : (s = e * s + a, ++o >= n && (this.dMultiply(r), this.dAddOffset(s, 0), o = 0, s = 0));
    }

    o > 0 && (this.dMultiply(Math.pow(e, o)), this.dAddOffset(s, 0)), i && l.ZERO.subTo(this || _global, this || _global);
  }, l.prototype.fromNumber = function (t, e, n) {
    if ("number" == typeof e) {
      if (t < 2) this.fromInt(1);else for (this.fromNumber(t, n), this.testBit(t - 1) || this.bitwiseTo(l.ONE.shiftLeft(t - 1), I, this || _global), this.isEven() && this.dAddOffset(1, 0); !this.isProbablePrime(e);) this.dAddOffset(2, 0), this.bitLength() > t && this.subTo(l.ONE.shiftLeft(t - 1), this || _global);
    } else {
      var r = new Array(),
          i = 7 & t;
      r.length = 1 + (t >> 3), e.nextBytes(r), i > 0 ? r[0] &= (1 << i) - 1 : r[0] = 0, this.fromString(r, 256);
    }
  }, l.prototype.bitwiseTo = function (t, e, n) {
    var r,
        i,
        o = Math.min(t.t, (this || _global).t);

    for (r = 0; r < o; ++r) n[r] = e((this || _global)[r], t[r]);

    if (t.t < (this || _global).t) {
      for (i = t.s & (this || _global).DM, r = o; r < (this || _global).t; ++r) n[r] = e((this || _global)[r], i);

      n.t = (this || _global).t;
    } else {
      for (i = (this || _global).s & (this || _global).DM, r = o; r < t.t; ++r) n[r] = e(i, t[r]);

      n.t = t.t;
    }

    n.s = e((this || _global).s, t.s), n.clamp();
  }, l.prototype.changeBit = function (t, e) {
    var n = l.ONE.shiftLeft(t);
    return this.bitwiseTo(n, e, n), n;
  }, l.prototype.addTo = function (t, e) {
    for (var n = 0, r = 0, i = Math.min(t.t, (this || _global).t); n < i;) r += (this || _global)[n] + t[n], e[n++] = r & (this || _global).DM, r >>= (this || _global).DB;

    if (t.t < (this || _global).t) {
      for (r += t.s; n < (this || _global).t;) r += (this || _global)[n], e[n++] = r & (this || _global).DM, r >>= (this || _global).DB;

      r += (this || _global).s;
    } else {
      for (r += (this || _global).s; n < t.t;) r += t[n], e[n++] = r & (this || _global).DM, r >>= (this || _global).DB;

      r += t.s;
    }

    e.s = r < 0 ? -1 : 0, r > 0 ? e[n++] = r : r < -1 && (e[n++] = (this || _global).DV + r), e.t = n, e.clamp();
  }, l.prototype.dMultiply = function (t) {
    (this || _global)[(this || _global).t] = this.am(0, t - 1, this || _global, 0, 0, (this || _global).t), ++(this || _global).t, this.clamp();
  }, l.prototype.dAddOffset = function (t, e) {
    if (0 != t) {
      for (; (this || _global).t <= e;) (this || _global)[(this || _global).t++] = 0;

      for ((this || _global)[e] += t; (this || _global)[e] >= (this || _global).DV;) (this || _global)[e] -= (this || _global).DV, ++e >= (this || _global).t && ((this || _global)[(this || _global).t++] = 0), ++(this || _global)[e];
    }
  }, l.prototype.multiplyLowerTo = function (t, e, n) {
    var r,
        i = Math.min((this || _global).t + t.t, e);

    for (n.s = 0, n.t = i; i > 0;) n[--i] = 0;

    for (r = n.t - (this || _global).t; i < r; ++i) n[i + (this || _global).t] = this.am(0, t[i], n, i, 0, (this || _global).t);

    for (r = Math.min(t.t, e); i < r; ++i) this.am(0, t[i], n, i, 0, e - i);

    n.clamp();
  }, l.prototype.multiplyUpperTo = function (t, e, n) {
    --e;
    var r = n.t = (this || _global).t + t.t - e;

    for (n.s = 0; --r >= 0;) n[r] = 0;

    for (r = Math.max(e - (this || _global).t, 0); r < t.t; ++r) n[(this || _global).t + r - e] = this.am(e - r, t[r], n, 0, 0, (this || _global).t + r - e);

    n.clamp(), n.drShiftTo(1, n);
  }, l.prototype.modInt = function (t) {
    if (t <= 0) return 0;
    var e = (this || _global).DV % t,
        n = (this || _global).s < 0 ? t - 1 : 0;
    if ((this || _global).t > 0) if (0 == e) n = (this || _global)[0] % t;else for (var r = (this || _global).t - 1; r >= 0; --r) n = (e * n + (this || _global)[r]) % t;
    return n;
  }, l.prototype.millerRabin = function (t) {
    var e = this.subtract(l.ONE),
        n = e.getLowestSetBit();
    if (n <= 0) return !1;
    var r = e.shiftRight(n);
    (t = t + 1 >> 1) > N.length && (t = N.length);

    for (var i = f(), o = 0; o < t; ++o) {
      i.fromInt(N[Math.floor(Math.random() * N.length)]);
      var s = i.modPow(r, this || _global);

      if (0 != s.compareTo(l.ONE) && 0 != s.compareTo(e)) {
        for (var u = 1; u++ < n && 0 != s.compareTo(e);) if (0 == (s = s.modPowInt(2, this || _global)).compareTo(l.ONE)) return !1;

        if (0 != s.compareTo(e)) return !1;
      }
    }

    return !0;
  }, l.prototype.clone = function () {
    var t = f();
    return this.copyTo(t), t;
  }, l.prototype.intValue = function () {
    if ((this || _global).s < 0) {
      if (1 == (this || _global).t) return (this || _global)[0] - (this || _global).DV;
      if (0 == (this || _global).t) return -1;
    } else {
      if (1 == (this || _global).t) return (this || _global)[0];
      if (0 == (this || _global).t) return 0;
    }

    return ((this || _global)[1] & (1 << 32 - (this || _global).DB) - 1) << (this || _global).DB | (this || _global)[0];
  }, l.prototype.byteValue = function () {
    return 0 == (this || _global).t ? (this || _global).s : (this || _global)[0] << 24 >> 24;
  }, l.prototype.shortValue = function () {
    return 0 == (this || _global).t ? (this || _global).s : (this || _global)[0] << 16 >> 16;
  }, l.prototype.signum = function () {
    return (this || _global).s < 0 ? -1 : (this || _global).t <= 0 || 1 == (this || _global).t && (this || _global)[0] <= 0 ? 0 : 1;
  }, l.prototype.toByteArray = function () {
    var t = (this || _global).t,
        e = new Array();
    e[0] = (this || _global).s;
    var n,
        r = (this || _global).DB - t * (this || _global).DB % 8,
        i = 0;
    if (t-- > 0) for (r < (this || _global).DB && (n = (this || _global)[t] >> r) != ((this || _global).s & (this || _global).DM) >> r && (e[i++] = n | (this || _global).s << (this || _global).DB - r); t >= 0;) r < 8 ? (n = ((this || _global)[t] & (1 << r) - 1) << 8 - r, n |= (this || _global)[--t] >> (r += (this || _global).DB - 8)) : (n = (this || _global)[t] >> (r -= 8) & 255, r <= 0 && (r += (this || _global).DB, --t)), 0 != (128 & n) && (n |= -256), 0 == i && (128 & (this || _global).s) != (128 & n) && ++i, (i > 0 || n != (this || _global).s) && (e[i++] = n);
    return e;
  }, l.prototype.equals = function (t) {
    return 0 == this.compareTo(t);
  }, l.prototype.min = function (t) {
    return this.compareTo(t) < 0 ? this || _global : t;
  }, l.prototype.max = function (t) {
    return this.compareTo(t) > 0 ? this || _global : t;
  }, l.prototype.and = function (t) {
    var e = f();
    return this.bitwiseTo(t, x, e), e;
  }, l.prototype.or = function (t) {
    var e = f();
    return this.bitwiseTo(t, I, e), e;
  }, l.prototype.xor = function (t) {
    var e = f();
    return this.bitwiseTo(t, C, e), e;
  }, l.prototype.andNot = function (t) {
    var e = f();
    return this.bitwiseTo(t, E, e), e;
  }, l.prototype.not = function () {
    for (var t = f(), e = 0; e < (this || _global).t; ++e) t[e] = (this || _global).DM & ~(this || _global)[e];

    return t.t = (this || _global).t, t.s = ~(this || _global).s, t;
  }, l.prototype.shiftLeft = function (t) {
    var e = f();
    return t < 0 ? this.rShiftTo(-t, e) : this.lShiftTo(t, e), e;
  }, l.prototype.shiftRight = function (t) {
    var e = f();
    return t < 0 ? this.lShiftTo(-t, e) : this.rShiftTo(t, e), e;
  }, l.prototype.getLowestSetBit = function () {
    for (var t = 0; t < (this || _global).t; ++t) if (0 != (this || _global)[t]) return t * (this || _global).DB + L((this || _global)[t]);

    return (this || _global).s < 0 ? (this || _global).t * (this || _global).DB : -1;
  }, l.prototype.bitCount = function () {
    for (var t = 0, e = (this || _global).s & (this || _global).DM, n = 0; n < (this || _global).t; ++n) t += S((this || _global)[n] ^ e);

    return t;
  }, l.prototype.testBit = function (t) {
    var e = Math.floor(t / (this || _global).DB);
    return e >= (this || _global).t ? 0 != (this || _global).s : 0 != ((this || _global)[e] & 1 << t % (this || _global).DB);
  }, l.prototype.setBit = function (t) {
    return this.changeBit(t, I);
  }, l.prototype.clearBit = function (t) {
    return this.changeBit(t, E);
  }, l.prototype.flipBit = function (t) {
    return this.changeBit(t, C);
  }, l.prototype.add = function (t) {
    var e = f();
    return this.addTo(t, e), e;
  }, l.prototype.subtract = function (t) {
    var e = f();
    return this.subTo(t, e), e;
  }, l.prototype.multiply = function (t) {
    var e = f();
    return this.multiplyTo(t, e), e;
  }, l.prototype.divide = function (t) {
    var e = f();
    return this.divRemTo(t, e, null), e;
  }, l.prototype.remainder = function (t) {
    var e = f();
    return this.divRemTo(t, null, e), e;
  }, l.prototype.divideAndRemainder = function (t) {
    var e = f(),
        n = f();
    return this.divRemTo(t, e, n), new Array(e, n);
  }, l.prototype.modPow = function (t, e) {
    var n,
        r,
        i = t.bitLength(),
        o = m(1);
    if (i <= 0) return o;
    n = i < 18 ? 1 : i < 48 ? 3 : i < 144 ? 4 : i < 768 ? 5 : 6, r = i < 8 ? new g(e) : e.isEven() ? new T(e) : new _(e);
    var s = new Array(),
        u = 3,
        a = n - 1,
        l = (1 << n) - 1;

    if (s[1] = r.convert(this || _global), n > 1) {
      var c = f();

      for (r.sqrTo(s[1], c); u <= l;) s[u] = f(), r.mulTo(c, s[u - 2], s[u]), u += 2;
    }

    var h,
        p,
        d = t.t - 1,
        v = !0,
        y = f();

    for (i = P(t[d]) - 1; d >= 0;) {
      for (i >= a ? h = t[d] >> i - a & l : (h = (t[d] & (1 << i + 1) - 1) << a - i, d > 0 && (h |= t[d - 1] >> (this || _global).DB + i - a)), u = n; 0 == (1 & h);) h >>= 1, --u;

      if ((i -= u) < 0 && (i += (this || _global).DB, --d), v) s[h].copyTo(o), v = !1;else {
        for (; u > 1;) r.sqrTo(o, y), r.sqrTo(y, o), u -= 2;

        u > 0 ? r.sqrTo(o, y) : (p = o, o = y, y = p), r.mulTo(y, s[h], o);
      }

      for (; d >= 0 && 0 == (t[d] & 1 << i);) r.sqrTo(o, y), p = o, o = y, y = p, --i < 0 && (i = (this || _global).DB - 1, --d);
    }

    return r.revert(o);
  }, l.prototype.modInverse = function (t) {
    var e = t.isEven();
    if (this.isEven() && e || 0 == t.signum()) return l.ZERO;

    for (var n = t.clone(), r = this.clone(), i = m(1), o = m(0), s = m(0), u = m(1); 0 != n.signum();) {
      for (; n.isEven();) n.rShiftTo(1, n), e ? (i.isEven() && o.isEven() || (i.addTo(this || _global, i), o.subTo(t, o)), i.rShiftTo(1, i)) : o.isEven() || o.subTo(t, o), o.rShiftTo(1, o);

      for (; r.isEven();) r.rShiftTo(1, r), e ? (s.isEven() && u.isEven() || (s.addTo(this || _global, s), u.subTo(t, u)), s.rShiftTo(1, s)) : u.isEven() || u.subTo(t, u), u.rShiftTo(1, u);

      n.compareTo(r) >= 0 ? (n.subTo(r, n), e && i.subTo(s, i), o.subTo(u, o)) : (r.subTo(n, r), e && s.subTo(i, s), u.subTo(o, u));
    }

    return 0 != r.compareTo(l.ONE) ? l.ZERO : u.compareTo(t) >= 0 ? u.subtract(t) : u.signum() < 0 ? (u.addTo(t, u), u.signum() < 0 ? u.add(t) : u) : u;
  }, l.prototype.pow = function (t) {
    return this.exp(t, new b());
  }, l.prototype.gcd = function (t) {
    var e = (this || _global).s < 0 ? this.negate() : this.clone(),
        n = t.s < 0 ? t.negate() : t.clone();

    if (e.compareTo(n) < 0) {
      var r = e;
      e = n, n = r;
    }

    var i = e.getLowestSetBit(),
        o = n.getLowestSetBit();
    if (o < 0) return e;

    for (i < o && (o = i), o > 0 && (e.rShiftTo(o, e), n.rShiftTo(o, n)); e.signum() > 0;) (i = e.getLowestSetBit()) > 0 && e.rShiftTo(i, e), (i = n.getLowestSetBit()) > 0 && n.rShiftTo(i, n), e.compareTo(n) >= 0 ? (e.subTo(n, e), e.rShiftTo(1, e)) : (n.subTo(e, n), n.rShiftTo(1, n));

    return o > 0 && n.lShiftTo(o, n), n;
  }, l.prototype.isProbablePrime = function (t) {
    var e,
        n = this.abs();

    if (1 == n.t && n[0] <= N[N.length - 1]) {
      for (e = 0; e < N.length; ++e) if (n[0] == N[e]) return !0;

      return !1;
    }

    if (n.isEven()) return !1;

    for (e = 1; e < N.length;) {
      for (var r = N[e], i = e + 1; i < N.length && r < w;) r *= N[i++];

      for (r = n.modInt(r); e < i;) if (r % N[e++] == 0) return !1;
    }

    return n.millerRabin(t);
  }, l.prototype.square = function () {
    var t = f();
    return this.squareTo(t), t;
  };
  var A = l;
  if (A.prototype.IsNegative = function () {
    return -1 == this.compareTo(A.ZERO);
  }, A.op_Equality = function (t, e) {
    return 0 == t.compareTo(e);
  }, A.op_Inequality = function (t, e) {
    return 0 != t.compareTo(e);
  }, A.op_GreaterThan = function (t, e) {
    return t.compareTo(e) > 0;
  }, A.op_LessThan = function (t, e) {
    return t.compareTo(e) < 0;
  }, A.op_Addition = function (t, e) {
    return new A(t).add(new A(e));
  }, A.op_Subtraction = function (t, e) {
    return new A(t).subtract(new A(e));
  }, A.Int128Mul = function (t, e) {
    return new A(t).multiply(new A(e));
  }, A.op_Division = function (t, e) {
    return t.divide(e);
  }, A.prototype.ToDouble = function () {
    return parseFloat(this.toString());
  }, void 0 === X) var X = function (t, e) {
    var n;

    if (void 0 === Object.getOwnPropertyNames) {
      for (n in e.prototype) void 0 !== t.prototype[n] && t.prototype[n] != Object.prototype[n] || (t.prototype[n] = e.prototype[n]);

      for (n in e) void 0 === t[n] && (t[n] = e[n]);

      t.$baseCtor = e;
    } else {
      for (var r = Object.getOwnPropertyNames(e.prototype), i = 0; i < r.length; i++) void 0 === Object.getOwnPropertyDescriptor(t.prototype, r[i]) && Object.defineProperty(t.prototype, r[i], Object.getOwnPropertyDescriptor(e.prototype, r[i]));

      for (n in e) void 0 === t[n] && (t[n] = e[n]);

      t.$baseCtor = e;
    }
  };
  i.Path = function () {
    return [];
  }, i.Paths = function () {
    return [];
  }, i.DoublePoint = function () {
    var t = arguments;
    (this || _global).X = 0, (this || _global).Y = 0, 1 == t.length ? ((this || _global).X = t[0].X, (this || _global).Y = t[0].Y) : 2 == t.length && ((this || _global).X = t[0], (this || _global).Y = t[1]);
  }, i.DoublePoint0 = function () {
    (this || _global).X = 0, (this || _global).Y = 0;
  }, i.DoublePoint1 = function (t) {
    (this || _global).X = t.X, (this || _global).Y = t.Y;
  }, i.DoublePoint2 = function (t, e) {
    (this || _global).X = t, (this || _global).Y = e;
  }, i.PolyNode = function () {
    (this || _global).m_Parent = null, (this || _global).m_polygon = new i.Path(), (this || _global).m_Index = 0, (this || _global).m_jointype = 0, (this || _global).m_endtype = 0, (this || _global).m_Childs = [], (this || _global).IsOpen = !1;
  }, i.PolyNode.prototype.IsHoleNode = function () {
    for (var t = !0, e = (this || _global).m_Parent; null !== e;) t = !t, e = e.m_Parent;

    return t;
  }, i.PolyNode.prototype.ChildCount = function () {
    return (this || _global).m_Childs.length;
  }, i.PolyNode.prototype.Contour = function () {
    return (this || _global).m_polygon;
  }, i.PolyNode.prototype.AddChild = function (t) {
    var e = (this || _global).m_Childs.length;
    (this || _global).m_Childs.push(t), t.m_Parent = this || _global, t.m_Index = e;
  }, i.PolyNode.prototype.GetNext = function () {
    return (this || _global).m_Childs.length > 0 ? (this || _global).m_Childs[0] : this.GetNextSiblingUp();
  }, i.PolyNode.prototype.GetNextSiblingUp = function () {
    return null === (this || _global).m_Parent ? null : (this || _global).m_Index == (this || _global).m_Parent.m_Childs.length - 1 ? (this || _global).m_Parent.GetNextSiblingUp() : (this || _global).m_Parent.m_Childs[(this || _global).m_Index + 1];
  }, i.PolyNode.prototype.Childs = function () {
    return (this || _global).m_Childs;
  }, i.PolyNode.prototype.Parent = function () {
    return (this || _global).m_Parent;
  }, i.PolyNode.prototype.IsHole = function () {
    return this.IsHoleNode();
  }, i.PolyTree = function () {
    (this || _global).m_AllPolys = [], i.PolyNode.call(this || _global);
  }, i.PolyTree.prototype.Clear = function () {
    for (var t = 0, e = (this || _global).m_AllPolys.length; t < e; t++) (this || _global).m_AllPolys[t] = null;

    (this || _global).m_AllPolys.length = 0, (this || _global).m_Childs.length = 0;
  }, i.PolyTree.prototype.GetFirst = function () {
    return (this || _global).m_Childs.length > 0 ? (this || _global).m_Childs[0] : null;
  }, i.PolyTree.prototype.Total = function () {
    return (this || _global).m_AllPolys.length;
  }, X(i.PolyTree, i.PolyNode), i.Math_Abs_Int64 = i.Math_Abs_Int32 = i.Math_Abs_Double = function (t) {
    return Math.abs(t);
  }, i.Math_Max_Int32_Int32 = function (t, e) {
    return Math.max(t, e);
  }, a.msie || a.opera || a.safari ? i.Cast_Int32 = function (t) {
    return 0 | t;
  } : i.Cast_Int32 = function (t) {
    return ~~t;
  }, a.chrome ? i.Cast_Int64 = function (t) {
    return t < -2147483648 || t > 2147483647 ? t < 0 ? Math.ceil(t) : Math.floor(t) : ~~t;
  } : a.firefox && "function" == typeof Number.toInteger ? i.Cast_Int64 = function (t) {
    return Number.toInteger(t);
  } : a.msie7 || a.msie8 ? i.Cast_Int64 = function (t) {
    return parseInt(t, 10);
  } : a.msie ? i.Cast_Int64 = function (t) {
    return t < -2147483648 || t > 2147483647 ? t < 0 ? Math.ceil(t) : Math.floor(t) : 0 | t;
  } : i.Cast_Int64 = function (t) {
    return t < 0 ? Math.ceil(t) : Math.floor(t);
  }, i.Clear = function (t) {
    t.length = 0;
  }, i.PI = 3.141592653589793, i.PI2 = 6.283185307179586, i.IntPoint = function () {
    var t = arguments,
        e = t.length;
    if ((this || _global).X = 0, (this || _global).Y = 0, 2 == e) (this || _global).X = t[0], (this || _global).Y = t[1];else if (1 == e) {
      if (t[0] instanceof i.DoublePoint) {
        var n = t[0];
        (this || _global).X = i.Clipper.Round(n.X), (this || _global).Y = i.Clipper.Round(n.Y);
      } else {
        var r = t[0];
        (this || _global).X = r.X, (this || _global).Y = r.Y;
      }
    } else (this || _global).X = 0, (this || _global).Y = 0;
  }, i.IntPoint.op_Equality = function (t, e) {
    return t.X == e.X && t.Y == e.Y;
  }, i.IntPoint.op_Inequality = function (t, e) {
    return t.X != e.X || t.Y != e.Y;
  }, i.IntPoint0 = function () {
    (this || _global).X = 0, (this || _global).Y = 0;
  }, i.IntPoint1 = function (t) {
    (this || _global).X = t.X, (this || _global).Y = t.Y;
  }, i.IntPoint1dp = function (t) {
    (this || _global).X = i.Clipper.Round(t.X), (this || _global).Y = i.Clipper.Round(t.Y);
  }, i.IntPoint2 = function (t, e) {
    (this || _global).X = t, (this || _global).Y = e;
  }, i.IntRect = function () {
    var t = arguments,
        e = t.length;
    4 == e ? ((this || _global).left = t[0], (this || _global).top = t[1], (this || _global).right = t[2], (this || _global).bottom = t[3]) : 1 == e ? ((this || _global).left = ir.left, (this || _global).top = ir.top, (this || _global).right = ir.right, (this || _global).bottom = ir.bottom) : ((this || _global).left = 0, (this || _global).top = 0, (this || _global).right = 0, (this || _global).bottom = 0);
  }, i.IntRect0 = function () {
    (this || _global).left = 0, (this || _global).top = 0, (this || _global).right = 0, (this || _global).bottom = 0;
  }, i.IntRect1 = function (t) {
    (this || _global).left = t.left, (this || _global).top = t.top, (this || _global).right = t.right, (this || _global).bottom = t.bottom;
  }, i.IntRect4 = function (t, e, n, r) {
    (this || _global).left = t, (this || _global).top = e, (this || _global).right = n, (this || _global).bottom = r;
  }, i.ClipType = {
    ctIntersection: 0,
    ctUnion: 1,
    ctDifference: 2,
    ctXor: 3
  }, i.PolyType = {
    ptSubject: 0,
    ptClip: 1
  }, i.PolyFillType = {
    pftEvenOdd: 0,
    pftNonZero: 1,
    pftPositive: 2,
    pftNegative: 3
  }, i.JoinType = {
    jtSquare: 0,
    jtRound: 1,
    jtMiter: 2
  }, i.EndType = {
    etOpenSquare: 0,
    etOpenRound: 1,
    etOpenButt: 2,
    etClosedLine: 3,
    etClosedPolygon: 4
  }, i.EdgeSide = {
    esLeft: 0,
    esRight: 1
  }, i.Direction = {
    dRightToLeft: 0,
    dLeftToRight: 1
  }, i.TEdge = function () {
    (this || _global).Bot = new i.IntPoint(), (this || _global).Curr = new i.IntPoint(), (this || _global).Top = new i.IntPoint(), (this || _global).Delta = new i.IntPoint(), (this || _global).Dx = 0, (this || _global).PolyTyp = i.PolyType.ptSubject, (this || _global).Side = i.EdgeSide.esLeft, (this || _global).WindDelta = 0, (this || _global).WindCnt = 0, (this || _global).WindCnt2 = 0, (this || _global).OutIdx = 0, (this || _global).Next = null, (this || _global).Prev = null, (this || _global).NextInLML = null, (this || _global).NextInAEL = null, (this || _global).PrevInAEL = null, (this || _global).NextInSEL = null, (this || _global).PrevInSEL = null;
  }, i.IntersectNode = function () {
    (this || _global).Edge1 = null, (this || _global).Edge2 = null, (this || _global).Pt = new i.IntPoint();
  }, i.MyIntersectNodeSort = function () {}, i.MyIntersectNodeSort.Compare = function (t, e) {
    return e.Pt.Y - t.Pt.Y;
  }, i.LocalMinima = function () {
    (this || _global).Y = 0, (this || _global).LeftBound = null, (this || _global).RightBound = null, (this || _global).Next = null;
  }, i.Scanbeam = function () {
    (this || _global).Y = 0, (this || _global).Next = null;
  }, i.OutRec = function () {
    (this || _global).Idx = 0, (this || _global).IsHole = !1, (this || _global).IsOpen = !1, (this || _global).FirstLeft = null, (this || _global).Pts = null, (this || _global).BottomPt = null, (this || _global).PolyNode = null;
  }, i.OutPt = function () {
    (this || _global).Idx = 0, (this || _global).Pt = new i.IntPoint(), (this || _global).Next = null, (this || _global).Prev = null;
  }, i.Join = function () {
    (this || _global).OutPt1 = null, (this || _global).OutPt2 = null, (this || _global).OffPt = new i.IntPoint();
  }, i.ClipperBase = function () {
    (this || _global).m_MinimaList = null, (this || _global).m_CurrentLM = null, (this || _global).m_edges = new Array(), (this || _global).m_UseFullRange = !1, (this || _global).m_HasOpenPaths = !1, (this || _global).PreserveCollinear = !1, (this || _global).m_MinimaList = null, (this || _global).m_CurrentLM = null, (this || _global).m_UseFullRange = !1, (this || _global).m_HasOpenPaths = !1;
  }, i.ClipperBase.horizontal = -9007199254740992, i.ClipperBase.Skip = -2, i.ClipperBase.Unassigned = -1, i.ClipperBase.tolerance = 1e-20, i.ClipperBase.loRange = 47453132, i.ClipperBase.hiRange = 4503599627370495, i.ClipperBase.near_zero = function (t) {
    return t > -i.ClipperBase.tolerance && t < i.ClipperBase.tolerance;
  }, i.ClipperBase.IsHorizontal = function (t) {
    return 0 === t.Delta.Y;
  }, i.ClipperBase.prototype.PointIsVertex = function (t, e) {
    var n = e;

    do {
      if (i.IntPoint.op_Equality(n.Pt, t)) return !0;
      n = n.Next;
    } while (n != e);

    return !1;
  }, i.ClipperBase.prototype.PointOnLineSegment = function (t, e, n, r) {
    return r ? t.X == e.X && t.Y == e.Y || t.X == n.X && t.Y == n.Y || t.X > e.X == t.X < n.X && t.Y > e.Y == t.Y < n.Y && A.op_Equality(A.Int128Mul(t.X - e.X, n.Y - e.Y), A.Int128Mul(n.X - e.X, t.Y - e.Y)) : t.X == e.X && t.Y == e.Y || t.X == n.X && t.Y == n.Y || t.X > e.X == t.X < n.X && t.Y > e.Y == t.Y < n.Y && (t.X - e.X) * (n.Y - e.Y) == (n.X - e.X) * (t.Y - e.Y);
  }, i.ClipperBase.prototype.PointOnPolygon = function (t, e, n) {
    for (var r = e;;) {
      if (this.PointOnLineSegment(t, r.Pt, r.Next.Pt, n)) return !0;
      if ((r = r.Next) == e) break;
    }

    return !1;
  }, i.ClipperBase.prototype.SlopesEqual = i.ClipperBase.SlopesEqual = function () {
    var t,
        e,
        n,
        r,
        o,
        s,
        u = arguments,
        a = u.length;
    return 3 == a ? (t = u[0], e = u[1], u[2] ? A.op_Equality(A.Int128Mul(t.Delta.Y, e.Delta.X), A.Int128Mul(t.Delta.X, e.Delta.Y)) : i.Cast_Int64(t.Delta.Y * e.Delta.X) == i.Cast_Int64(t.Delta.X * e.Delta.Y)) : 4 == a ? (n = u[0], r = u[1], o = u[2], u[3] ? A.op_Equality(A.Int128Mul(n.Y - r.Y, r.X - o.X), A.Int128Mul(n.X - r.X, r.Y - o.Y)) : i.Cast_Int64((n.Y - r.Y) * (r.X - o.X)) - i.Cast_Int64((n.X - r.X) * (r.Y - o.Y)) == 0) : (n = u[0], r = u[1], o = u[2], s = u[3], u[4] ? A.op_Equality(A.Int128Mul(n.Y - r.Y, o.X - s.X), A.Int128Mul(n.X - r.X, o.Y - s.Y)) : i.Cast_Int64((n.Y - r.Y) * (o.X - s.X)) - i.Cast_Int64((n.X - r.X) * (o.Y - s.Y)) == 0);
  }, i.ClipperBase.SlopesEqual3 = function (t, e, n) {
    return n ? A.op_Equality(A.Int128Mul(t.Delta.Y, e.Delta.X), A.Int128Mul(t.Delta.X, e.Delta.Y)) : i.Cast_Int64(t.Delta.Y * e.Delta.X) == i.Cast_Int64(t.Delta.X * e.Delta.Y);
  }, i.ClipperBase.SlopesEqual4 = function (t, e, n, r) {
    return r ? A.op_Equality(A.Int128Mul(t.Y - e.Y, e.X - n.X), A.Int128Mul(t.X - e.X, e.Y - n.Y)) : i.Cast_Int64((t.Y - e.Y) * (e.X - n.X)) - i.Cast_Int64((t.X - e.X) * (e.Y - n.Y)) == 0;
  }, i.ClipperBase.SlopesEqual5 = function (t, e, n, r, o) {
    return o ? A.op_Equality(A.Int128Mul(t.Y - e.Y, n.X - r.X), A.Int128Mul(t.X - e.X, n.Y - r.Y)) : i.Cast_Int64((t.Y - e.Y) * (n.X - r.X)) - i.Cast_Int64((t.X - e.X) * (n.Y - r.Y)) == 0;
  }, i.ClipperBase.prototype.Clear = function () {
    this.DisposeLocalMinimaList();

    for (var t = 0, e = (this || _global).m_edges.length; t < e; ++t) {
      for (var n = 0, r = (this || _global).m_edges[t].length; n < r; ++n) (this || _global).m_edges[t][n] = null;

      i.Clear((this || _global).m_edges[t]);
    }

    i.Clear((this || _global).m_edges), (this || _global).m_UseFullRange = !1, (this || _global).m_HasOpenPaths = !1;
  }, i.ClipperBase.prototype.DisposeLocalMinimaList = function () {
    for (; null !== (this || _global).m_MinimaList;) {
      var t = (this || _global).m_MinimaList.Next;
      (this || _global).m_MinimaList = null, (this || _global).m_MinimaList = t;
    }

    (this || _global).m_CurrentLM = null;
  }, i.ClipperBase.prototype.RangeTest = function (t, e) {
    e.Value ? (t.X > i.ClipperBase.hiRange || t.Y > i.ClipperBase.hiRange || -t.X > i.ClipperBase.hiRange || -t.Y > i.ClipperBase.hiRange) && i.Error("Coordinate outside allowed range in RangeTest().") : (t.X > i.ClipperBase.loRange || t.Y > i.ClipperBase.loRange || -t.X > i.ClipperBase.loRange || -t.Y > i.ClipperBase.loRange) && (e.Value = !0, this.RangeTest(t, e));
  }, i.ClipperBase.prototype.InitEdge = function (t, e, n, r) {
    t.Next = e, t.Prev = n, t.Curr.X = r.X, t.Curr.Y = r.Y, t.OutIdx = -1;
  }, i.ClipperBase.prototype.InitEdge2 = function (t, e) {
    t.Curr.Y >= t.Next.Curr.Y ? (t.Bot.X = t.Curr.X, t.Bot.Y = t.Curr.Y, t.Top.X = t.Next.Curr.X, t.Top.Y = t.Next.Curr.Y) : (t.Top.X = t.Curr.X, t.Top.Y = t.Curr.Y, t.Bot.X = t.Next.Curr.X, t.Bot.Y = t.Next.Curr.Y), this.SetDx(t), t.PolyTyp = e;
  }, i.ClipperBase.prototype.FindNextLocMin = function (t) {
    for (var e;;) {
      for (; i.IntPoint.op_Inequality(t.Bot, t.Prev.Bot) || i.IntPoint.op_Equality(t.Curr, t.Top);) t = t.Next;

      if (t.Dx != i.ClipperBase.horizontal && t.Prev.Dx != i.ClipperBase.horizontal) break;

      for (; t.Prev.Dx == i.ClipperBase.horizontal;) t = t.Prev;

      for (e = t; t.Dx == i.ClipperBase.horizontal;) t = t.Next;

      if (t.Top.Y != t.Prev.Bot.Y) {
        e.Prev.Bot.X < t.Bot.X && (t = e);
        break;
      }
    }

    return t;
  }, i.ClipperBase.prototype.ProcessBound = function (t, e) {
    var n,
        r,
        o = t,
        s = t;
    if (t.Dx == i.ClipperBase.horizontal && (r = e ? t.Prev.Bot.X : t.Next.Bot.X, t.Bot.X != r && this.ReverseHorizontal(t)), s.OutIdx != i.ClipperBase.Skip) if (e) {
      for (; s.Top.Y == s.Next.Bot.Y && s.Next.OutIdx != i.ClipperBase.Skip;) s = s.Next;

      if (s.Dx == i.ClipperBase.horizontal && s.Next.OutIdx != i.ClipperBase.Skip) {
        for (n = s; n.Prev.Dx == i.ClipperBase.horizontal;) n = n.Prev;

        n.Prev.Top.X == s.Next.Top.X ? e || (s = n.Prev) : n.Prev.Top.X > s.Next.Top.X && (s = n.Prev);
      }

      for (; t != s;) t.NextInLML = t.Next, t.Dx == i.ClipperBase.horizontal && t != o && t.Bot.X != t.Prev.Top.X && this.ReverseHorizontal(t), t = t.Next;

      t.Dx == i.ClipperBase.horizontal && t != o && t.Bot.X != t.Prev.Top.X && this.ReverseHorizontal(t), s = s.Next;
    } else {
      for (; s.Top.Y == s.Prev.Bot.Y && s.Prev.OutIdx != i.ClipperBase.Skip;) s = s.Prev;

      if (s.Dx == i.ClipperBase.horizontal && s.Prev.OutIdx != i.ClipperBase.Skip) {
        for (n = s; n.Next.Dx == i.ClipperBase.horizontal;) n = n.Next;

        n.Next.Top.X == s.Prev.Top.X ? e || (s = n.Next) : n.Next.Top.X > s.Prev.Top.X && (s = n.Next);
      }

      for (; t != s;) t.NextInLML = t.Prev, t.Dx == i.ClipperBase.horizontal && t != o && t.Bot.X != t.Next.Top.X && this.ReverseHorizontal(t), t = t.Prev;

      t.Dx == i.ClipperBase.horizontal && t != o && t.Bot.X != t.Next.Top.X && this.ReverseHorizontal(t), s = s.Prev;
    }

    if (s.OutIdx == i.ClipperBase.Skip) {
      if (t = s, e) {
        for (; t.Top.Y == t.Next.Bot.Y;) t = t.Next;

        for (; t != s && t.Dx == i.ClipperBase.horizontal;) t = t.Prev;
      } else {
        for (; t.Top.Y == t.Prev.Bot.Y;) t = t.Prev;

        for (; t != s && t.Dx == i.ClipperBase.horizontal;) t = t.Next;
      }

      if (t == s) s = e ? t.Next : t.Prev;else {
        t = e ? s.Next : s.Prev;
        var u = new i.LocalMinima();
        u.Next = null, u.Y = t.Bot.Y, u.LeftBound = null, u.RightBound = t, u.RightBound.WindDelta = 0, s = this.ProcessBound(u.RightBound, e), this.InsertLocalMinima(u);
      }
    }

    return s;
  }, i.ClipperBase.prototype.AddPath = function (t, e, n) {
    n || e != i.PolyType.ptClip || i.Error("AddPath: Open paths must be subject.");
    var r = t.length - 1;
    if (n) for (; r > 0 && i.IntPoint.op_Equality(t[r], t[0]);) --r;

    for (; r > 0 && i.IntPoint.op_Equality(t[r], t[r - 1]);) --r;

    if (n && r < 2 || !n && r < 1) return !1;

    for (var o = new Array(), s = 0; s <= r; s++) o.push(new i.TEdge());

    var u = !0;
    o[1].Curr.X = t[1].X, o[1].Curr.Y = t[1].Y;
    var a = {
      Value: (this || _global).m_UseFullRange
    };
    this.RangeTest(t[0], a), (this || _global).m_UseFullRange = a.Value, a.Value = (this || _global).m_UseFullRange, this.RangeTest(t[r], a), (this || _global).m_UseFullRange = a.Value, this.InitEdge(o[0], o[1], o[r], t[0]), this.InitEdge(o[r], o[0], o[r - 1], t[r]);

    for (s = r - 1; s >= 1; --s) a.Value = (this || _global).m_UseFullRange, this.RangeTest(t[s], a), (this || _global).m_UseFullRange = a.Value, this.InitEdge(o[s], o[s + 1], o[s - 1], t[s]);

    for (var l = o[0], f = l, c = l;;) if (i.IntPoint.op_Equality(f.Curr, f.Next.Curr)) {
      if (f == f.Next) break;
      f == l && (l = f.Next), c = f = this.RemoveEdge(f);
    } else {
      if (f.Prev == f.Next) break;

      if (!n || !i.ClipperBase.SlopesEqual(f.Prev.Curr, f.Curr, f.Next.Curr, (this || _global).m_UseFullRange) || (this || _global).PreserveCollinear && this.Pt2IsBetweenPt1AndPt3(f.Prev.Curr, f.Curr, f.Next.Curr)) {
        if ((f = f.Next) == c) break;
      } else f == l && (l = f.Next), c = f = (f = this.RemoveEdge(f)).Prev;
    }

    if (!n && f == f.Next || n && f.Prev == f.Next) return !1;
    n || ((this || _global).m_HasOpenPaths = !0, l.Prev.OutIdx = i.ClipperBase.Skip);
    var h;
    f = l;

    do {
      this.InitEdge2(f, e), f = f.Next, u && f.Curr.Y != l.Curr.Y && (u = !1);
    } while (f != l);

    if (u) {
      if (n) return !1;

      for (f.Prev.OutIdx = i.ClipperBase.Skip, f.Prev.Bot.X < f.Prev.Top.X && this.ReverseHorizontal(f.Prev), (d = new i.LocalMinima()).Next = null, d.Y = f.Bot.Y, d.LeftBound = null, d.RightBound = f, d.RightBound.Side = i.EdgeSide.esRight, d.RightBound.WindDelta = 0; f.Next.OutIdx != i.ClipperBase.Skip;) f.NextInLML = f.Next, f.Bot.X != f.Prev.Top.X && this.ReverseHorizontal(f), f = f.Next;

      return this.InsertLocalMinima(d), (this || _global).m_edges.push(o), !0;
    }

    (this || _global).m_edges.push(o);

    for (var p = null; (f = this.FindNextLocMin(f)) != p;) {
      var d;
      null == p && (p = f), (d = new i.LocalMinima()).Next = null, d.Y = f.Bot.Y, f.Dx < f.Prev.Dx ? (d.LeftBound = f.Prev, d.RightBound = f, h = !1) : (d.LeftBound = f, d.RightBound = f.Prev, h = !0), d.LeftBound.Side = i.EdgeSide.esLeft, d.RightBound.Side = i.EdgeSide.esRight, n ? d.LeftBound.Next == d.RightBound ? d.LeftBound.WindDelta = -1 : d.LeftBound.WindDelta = 1 : d.LeftBound.WindDelta = 0, d.RightBound.WindDelta = -d.LeftBound.WindDelta, f = this.ProcessBound(d.LeftBound, h);
      var v = this.ProcessBound(d.RightBound, !h);
      d.LeftBound.OutIdx == i.ClipperBase.Skip ? d.LeftBound = null : d.RightBound.OutIdx == i.ClipperBase.Skip && (d.RightBound = null), this.InsertLocalMinima(d), h || (f = v);
    }

    return !0;
  }, i.ClipperBase.prototype.AddPaths = function (t, e, n) {
    for (var r = !1, i = 0, o = t.length; i < o; ++i) this.AddPath(t[i], e, n) && (r = !0);

    return r;
  }, i.ClipperBase.prototype.Pt2IsBetweenPt1AndPt3 = function (t, e, n) {
    return !(i.IntPoint.op_Equality(t, n) || i.IntPoint.op_Equality(t, e) || i.IntPoint.op_Equality(n, e)) && (t.X != n.X ? e.X > t.X == e.X < n.X : e.Y > t.Y == e.Y < n.Y);
  }, i.ClipperBase.prototype.RemoveEdge = function (t) {
    t.Prev.Next = t.Next, t.Next.Prev = t.Prev;
    var e = t.Next;
    return t.Prev = null, e;
  }, i.ClipperBase.prototype.SetDx = function (t) {
    t.Delta.X = t.Top.X - t.Bot.X, t.Delta.Y = t.Top.Y - t.Bot.Y, 0 === t.Delta.Y ? t.Dx = i.ClipperBase.horizontal : t.Dx = t.Delta.X / t.Delta.Y;
  }, i.ClipperBase.prototype.InsertLocalMinima = function (t) {
    if (null === (this || _global).m_MinimaList) (this || _global).m_MinimaList = t;else if (t.Y >= (this || _global).m_MinimaList.Y) t.Next = (this || _global).m_MinimaList, (this || _global).m_MinimaList = t;else {
      for (var e = (this || _global).m_MinimaList; null !== e.Next && t.Y < e.Next.Y;) e = e.Next;

      t.Next = e.Next, e.Next = t;
    }
  }, i.ClipperBase.prototype.PopLocalMinima = function () {
    null !== (this || _global).m_CurrentLM && ((this || _global).m_CurrentLM = (this || _global).m_CurrentLM.Next);
  }, i.ClipperBase.prototype.ReverseHorizontal = function (t) {
    var e = t.Top.X;
    t.Top.X = t.Bot.X, t.Bot.X = e;
  }, i.ClipperBase.prototype.Reset = function () {
    if ((this || _global).m_CurrentLM = (this || _global).m_MinimaList, null != (this || _global).m_CurrentLM) for (var t = (this || _global).m_MinimaList; null != t;) {
      var e = t.LeftBound;
      null != e && (e.Curr.X = e.Bot.X, e.Curr.Y = e.Bot.Y, e.Side = i.EdgeSide.esLeft, e.OutIdx = i.ClipperBase.Unassigned), null != (e = t.RightBound) && (e.Curr.X = e.Bot.X, e.Curr.Y = e.Bot.Y, e.Side = i.EdgeSide.esRight, e.OutIdx = i.ClipperBase.Unassigned), t = t.Next;
    }
  }, i.Clipper = function (t) {
    void 0 === t && (t = 0), (this || _global).m_PolyOuts = null, (this || _global).m_ClipType = i.ClipType.ctIntersection, (this || _global).m_Scanbeam = null, (this || _global).m_ActiveEdges = null, (this || _global).m_SortedEdges = null, (this || _global).m_IntersectList = null, (this || _global).m_IntersectNodeComparer = null, (this || _global).m_ExecuteLocked = !1, (this || _global).m_ClipFillType = i.PolyFillType.pftEvenOdd, (this || _global).m_SubjFillType = i.PolyFillType.pftEvenOdd, (this || _global).m_Joins = null, (this || _global).m_GhostJoins = null, (this || _global).m_UsingPolyTree = !1, (this || _global).ReverseSolution = !1, (this || _global).StrictlySimple = !1, i.ClipperBase.call(this || _global), (this || _global).m_Scanbeam = null, (this || _global).m_ActiveEdges = null, (this || _global).m_SortedEdges = null, (this || _global).m_IntersectList = new Array(), (this || _global).m_IntersectNodeComparer = i.MyIntersectNodeSort.Compare, (this || _global).m_ExecuteLocked = !1, (this || _global).m_UsingPolyTree = !1, (this || _global).m_PolyOuts = new Array(), (this || _global).m_Joins = new Array(), (this || _global).m_GhostJoins = new Array(), (this || _global).ReverseSolution = 0 != (1 & t), (this || _global).StrictlySimple = 0 != (2 & t), (this || _global).PreserveCollinear = 0 != (4 & t);
  }, i.Clipper.ioReverseSolution = 1, i.Clipper.ioStrictlySimple = 2, i.Clipper.ioPreserveCollinear = 4, i.Clipper.prototype.Clear = function () {
    0 !== (this || _global).m_edges.length && (this.DisposeAllPolyPts(), i.ClipperBase.prototype.Clear.call(this || _global));
  }, i.Clipper.prototype.DisposeScanbeamList = function () {
    for (; null !== (this || _global).m_Scanbeam;) {
      var t = (this || _global).m_Scanbeam.Next;
      (this || _global).m_Scanbeam = null, (this || _global).m_Scanbeam = t;
    }
  }, i.Clipper.prototype.Reset = function () {
    i.ClipperBase.prototype.Reset.call(this || _global), (this || _global).m_Scanbeam = null, (this || _global).m_ActiveEdges = null, (this || _global).m_SortedEdges = null;

    for (var t = (this || _global).m_MinimaList; null !== t;) this.InsertScanbeam(t.Y), t = t.Next;
  }, i.Clipper.prototype.InsertScanbeam = function (t) {
    if (null === (this || _global).m_Scanbeam) (this || _global).m_Scanbeam = new i.Scanbeam(), (this || _global).m_Scanbeam.Next = null, (this || _global).m_Scanbeam.Y = t;else if (t > (this || _global).m_Scanbeam.Y) {
      (n = new i.Scanbeam()).Y = t, n.Next = (this || _global).m_Scanbeam, (this || _global).m_Scanbeam = n;
    } else {
      for (var e = (this || _global).m_Scanbeam; null !== e.Next && t <= e.Next.Y;) e = e.Next;

      if (t == e.Y) return;
      var n;
      (n = new i.Scanbeam()).Y = t, n.Next = e.Next, e.Next = n;
    }
  }, i.Clipper.prototype.Execute = function () {
    var t = arguments,
        e = t.length,
        n = t[1] instanceof i.PolyTree;

    if (4 == e && !n) {
      var r = t[0],
          o = t[1],
          s = t[2],
          u = t[3];
      if ((this || _global).m_ExecuteLocked) return !1;
      (this || _global).m_HasOpenPaths && i.Error("Error: PolyTree struct is need for open path clipping."), (this || _global).m_ExecuteLocked = !0, i.Clear(o), (this || _global).m_SubjFillType = s, (this || _global).m_ClipFillType = u, (this || _global).m_ClipType = r, (this || _global).m_UsingPolyTree = !1;

      try {
        (l = this.ExecuteInternal()) && this.BuildResult(o);
      } finally {
        this.DisposeAllPolyPts(), (this || _global).m_ExecuteLocked = !1;
      }

      return l;
    }

    if (4 == e && n) {
      r = t[0];
      var a = t[1];
      s = t[2], u = t[3];
      if ((this || _global).m_ExecuteLocked) return !1;
      (this || _global).m_ExecuteLocked = !0, (this || _global).m_SubjFillType = s, (this || _global).m_ClipFillType = u, (this || _global).m_ClipType = r, (this || _global).m_UsingPolyTree = !0;

      try {
        var l;
        (l = this.ExecuteInternal()) && this.BuildResult2(a);
      } finally {
        this.DisposeAllPolyPts(), (this || _global).m_ExecuteLocked = !1;
      }

      return l;
    }

    if (2 == e && !n) {
      r = t[0], o = t[1];
      return this.Execute(r, o, i.PolyFillType.pftEvenOdd, i.PolyFillType.pftEvenOdd);
    }

    if (2 == e && n) {
      r = t[0], a = t[1];
      return this.Execute(r, a, i.PolyFillType.pftEvenOdd, i.PolyFillType.pftEvenOdd);
    }
  }, i.Clipper.prototype.FixHoleLinkage = function (t) {
    if (null !== t.FirstLeft && (t.IsHole == t.FirstLeft.IsHole || null === t.FirstLeft.Pts)) {
      for (var e = t.FirstLeft; null !== e && (e.IsHole == t.IsHole || null === e.Pts);) e = e.FirstLeft;

      t.FirstLeft = e;
    }
  }, i.Clipper.prototype.ExecuteInternal = function () {
    try {
      if (this.Reset(), null === (this || _global).m_CurrentLM) return !1;
      var t = this.PopScanbeam();

      do {
        if (this.InsertLocalMinimaIntoAEL(t), i.Clear((this || _global).m_GhostJoins), this.ProcessHorizontals(!1), null === (this || _global).m_Scanbeam) break;
        var e = this.PopScanbeam();
        if (!this.ProcessIntersections(t, e)) return !1;
        this.ProcessEdgesAtTopOfScanbeam(e), t = e;
      } while (null !== (this || _global).m_Scanbeam || null !== (this || _global).m_CurrentLM);

      for (var n = 0, r = (this || _global).m_PolyOuts.length; n < r; n++) {
        null === (o = (this || _global).m_PolyOuts[n]).Pts || o.IsOpen || (o.IsHole ^ (this || _global).ReverseSolution) == this.Area(o) > 0 && this.ReversePolyPtLinks(o.Pts);
      }

      this.JoinCommonEdges();

      for (n = 0, r = (this || _global).m_PolyOuts.length; n < r; n++) {
        var o;
        null === (o = (this || _global).m_PolyOuts[n]).Pts || o.IsOpen || this.FixupOutPolygon(o);
      }

      return (this || _global).StrictlySimple && this.DoSimplePolygons(), !0;
    } finally {
      i.Clear((this || _global).m_Joins), i.Clear((this || _global).m_GhostJoins);
    }
  }, i.Clipper.prototype.PopScanbeam = function () {
    var t = (this || _global).m_Scanbeam.Y;
    (this || _global).m_Scanbeam;
    return (this || _global).m_Scanbeam = (this || _global).m_Scanbeam.Next, null, t;
  }, i.Clipper.prototype.DisposeAllPolyPts = function () {
    for (var t = 0, e = (this || _global).m_PolyOuts.length; t < e; ++t) this.DisposeOutRec(t);

    i.Clear((this || _global).m_PolyOuts);
  }, i.Clipper.prototype.DisposeOutRec = function (t) {
    var e = (this || _global).m_PolyOuts[t];
    null !== e.Pts && this.DisposeOutPts(e.Pts), e = null, (this || _global).m_PolyOuts[t] = null;
  }, i.Clipper.prototype.DisposeOutPts = function (t) {
    if (null !== t) {
      for (t.Prev.Next = null; null !== t;) t, t = t.Next, null;
    }
  }, i.Clipper.prototype.AddJoin = function (t, e, n) {
    var r = new i.Join();
    r.OutPt1 = t, r.OutPt2 = e, r.OffPt.X = n.X, r.OffPt.Y = n.Y, (this || _global).m_Joins.push(r);
  }, i.Clipper.prototype.AddGhostJoin = function (t, e) {
    var n = new i.Join();
    n.OutPt1 = t, n.OffPt.X = e.X, n.OffPt.Y = e.Y, (this || _global).m_GhostJoins.push(n);
  }, i.Clipper.prototype.InsertLocalMinimaIntoAEL = function (t) {
    for (; null !== (this || _global).m_CurrentLM && (this || _global).m_CurrentLM.Y == t;) {
      var e = (this || _global).m_CurrentLM.LeftBound,
          n = (this || _global).m_CurrentLM.RightBound;
      this.PopLocalMinima();
      var r = null;

      if (null === e ? (this.InsertEdgeIntoAEL(n, null), this.SetWindingCount(n), this.IsContributing(n) && (r = this.AddOutPt(n, n.Bot))) : null == n ? (this.InsertEdgeIntoAEL(e, null), this.SetWindingCount(e), this.IsContributing(e) && (r = this.AddOutPt(e, e.Bot)), this.InsertScanbeam(e.Top.Y)) : (this.InsertEdgeIntoAEL(e, null), this.InsertEdgeIntoAEL(n, e), this.SetWindingCount(e), n.WindCnt = e.WindCnt, n.WindCnt2 = e.WindCnt2, this.IsContributing(e) && (r = this.AddLocalMinPoly(e, n, e.Bot)), this.InsertScanbeam(e.Top.Y)), null != n && (i.ClipperBase.IsHorizontal(n) ? this.AddEdgeToSEL(n) : this.InsertScanbeam(n.Top.Y)), null != e && null != n) {
        if (null !== r && i.ClipperBase.IsHorizontal(n) && (this || _global).m_GhostJoins.length > 0 && 0 !== n.WindDelta) for (var o = 0, s = (this || _global).m_GhostJoins.length; o < s; o++) {
          var u = (this || _global).m_GhostJoins[o];
          this.HorzSegmentsOverlap(u.OutPt1.Pt, u.OffPt, n.Bot, n.Top) && this.AddJoin(u.OutPt1, r, u.OffPt);
        }

        if (e.OutIdx >= 0 && null !== e.PrevInAEL && e.PrevInAEL.Curr.X == e.Bot.X && e.PrevInAEL.OutIdx >= 0 && i.ClipperBase.SlopesEqual(e.PrevInAEL, e, (this || _global).m_UseFullRange) && 0 !== e.WindDelta && 0 !== e.PrevInAEL.WindDelta) {
          var a = this.AddOutPt(e.PrevInAEL, e.Bot);
          this.AddJoin(r, a, e.Top);
        }

        if (e.NextInAEL != n) {
          if (n.OutIdx >= 0 && n.PrevInAEL.OutIdx >= 0 && i.ClipperBase.SlopesEqual(n.PrevInAEL, n, (this || _global).m_UseFullRange) && 0 !== n.WindDelta && 0 !== n.PrevInAEL.WindDelta) {
            a = this.AddOutPt(n.PrevInAEL, n.Bot);
            this.AddJoin(r, a, n.Top);
          }

          var l = e.NextInAEL;
          if (null !== l) for (; l != n;) this.IntersectEdges(n, l, e.Curr, !1), l = l.NextInAEL;
        }
      }
    }
  }, i.Clipper.prototype.InsertEdgeIntoAEL = function (t, e) {
    if (null === (this || _global).m_ActiveEdges) t.PrevInAEL = null, t.NextInAEL = null, (this || _global).m_ActiveEdges = t;else if (null === e && this.E2InsertsBeforeE1((this || _global).m_ActiveEdges, t)) t.PrevInAEL = null, t.NextInAEL = (this || _global).m_ActiveEdges, (this || _global).m_ActiveEdges.PrevInAEL = t, (this || _global).m_ActiveEdges = t;else {
      for (null === e && (e = (this || _global).m_ActiveEdges); null !== e.NextInAEL && !this.E2InsertsBeforeE1(e.NextInAEL, t);) e = e.NextInAEL;

      t.NextInAEL = e.NextInAEL, null !== e.NextInAEL && (e.NextInAEL.PrevInAEL = t), t.PrevInAEL = e, e.NextInAEL = t;
    }
  }, i.Clipper.prototype.E2InsertsBeforeE1 = function (t, e) {
    return e.Curr.X == t.Curr.X ? e.Top.Y > t.Top.Y ? e.Top.X < i.Clipper.TopX(t, e.Top.Y) : t.Top.X > i.Clipper.TopX(e, t.Top.Y) : e.Curr.X < t.Curr.X;
  }, i.Clipper.prototype.IsEvenOddFillType = function (t) {
    return t.PolyTyp == i.PolyType.ptSubject ? (this || _global).m_SubjFillType == i.PolyFillType.pftEvenOdd : (this || _global).m_ClipFillType == i.PolyFillType.pftEvenOdd;
  }, i.Clipper.prototype.IsEvenOddAltFillType = function (t) {
    return t.PolyTyp == i.PolyType.ptSubject ? (this || _global).m_ClipFillType == i.PolyFillType.pftEvenOdd : (this || _global).m_SubjFillType == i.PolyFillType.pftEvenOdd;
  }, i.Clipper.prototype.IsContributing = function (t) {
    var e, n;

    switch (t.PolyTyp == i.PolyType.ptSubject ? (e = (this || _global).m_SubjFillType, n = (this || _global).m_ClipFillType) : (e = (this || _global).m_ClipFillType, n = (this || _global).m_SubjFillType), e) {
      case i.PolyFillType.pftEvenOdd:
        if (0 === t.WindDelta && 1 != t.WindCnt) return !1;
        break;

      case i.PolyFillType.pftNonZero:
        if (1 != Math.abs(t.WindCnt)) return !1;
        break;

      case i.PolyFillType.pftPositive:
        if (1 != t.WindCnt) return !1;
        break;

      default:
        if (-1 != t.WindCnt) return !1;
    }

    switch ((this || _global).m_ClipType) {
      case i.ClipType.ctIntersection:
        switch (n) {
          case i.PolyFillType.pftEvenOdd:
          case i.PolyFillType.pftNonZero:
            return 0 !== t.WindCnt2;

          case i.PolyFillType.pftPositive:
            return t.WindCnt2 > 0;

          default:
            return t.WindCnt2 < 0;
        }

      case i.ClipType.ctUnion:
        switch (n) {
          case i.PolyFillType.pftEvenOdd:
          case i.PolyFillType.pftNonZero:
            return 0 === t.WindCnt2;

          case i.PolyFillType.pftPositive:
            return t.WindCnt2 <= 0;

          default:
            return t.WindCnt2 >= 0;
        }

      case i.ClipType.ctDifference:
        if (t.PolyTyp == i.PolyType.ptSubject) switch (n) {
          case i.PolyFillType.pftEvenOdd:
          case i.PolyFillType.pftNonZero:
            return 0 === t.WindCnt2;

          case i.PolyFillType.pftPositive:
            return t.WindCnt2 <= 0;

          default:
            return t.WindCnt2 >= 0;
        } else switch (n) {
          case i.PolyFillType.pftEvenOdd:
          case i.PolyFillType.pftNonZero:
            return 0 !== t.WindCnt2;

          case i.PolyFillType.pftPositive:
            return t.WindCnt2 > 0;

          default:
            return t.WindCnt2 < 0;
        }

      case i.ClipType.ctXor:
        if (0 !== t.WindDelta) return !0;

        switch (n) {
          case i.PolyFillType.pftEvenOdd:
          case i.PolyFillType.pftNonZero:
            return 0 === t.WindCnt2;

          case i.PolyFillType.pftPositive:
            return t.WindCnt2 <= 0;

          default:
            return t.WindCnt2 >= 0;
        }

    }

    return !0;
  }, i.Clipper.prototype.SetWindingCount = function (t) {
    for (var e = t.PrevInAEL; null !== e && (e.PolyTyp != t.PolyTyp || 0 === e.WindDelta);) e = e.PrevInAEL;

    if (null === e) t.WindCnt = 0 === t.WindDelta ? 1 : t.WindDelta, t.WindCnt2 = 0, e = (this || _global).m_ActiveEdges;else if (0 === t.WindDelta && (this || _global).m_ClipType != i.ClipType.ctUnion) t.WindCnt = 1, t.WindCnt2 = e.WindCnt2, e = e.NextInAEL;else if (this.IsEvenOddFillType(t)) {
      if (0 === t.WindDelta) {
        for (var n = !0, r = e.PrevInAEL; null !== r;) r.PolyTyp == e.PolyTyp && 0 !== r.WindDelta && (n = !n), r = r.PrevInAEL;

        t.WindCnt = n ? 0 : 1;
      } else t.WindCnt = t.WindDelta;

      t.WindCnt2 = e.WindCnt2, e = e.NextInAEL;
    } else e.WindCnt * e.WindDelta < 0 ? Math.abs(e.WindCnt) > 1 ? e.WindDelta * t.WindDelta < 0 ? t.WindCnt = e.WindCnt : t.WindCnt = e.WindCnt + t.WindDelta : t.WindCnt = 0 === t.WindDelta ? 1 : t.WindDelta : 0 === t.WindDelta ? t.WindCnt = e.WindCnt < 0 ? e.WindCnt - 1 : e.WindCnt + 1 : e.WindDelta * t.WindDelta < 0 ? t.WindCnt = e.WindCnt : t.WindCnt = e.WindCnt + t.WindDelta, t.WindCnt2 = e.WindCnt2, e = e.NextInAEL;
    if (this.IsEvenOddAltFillType(t)) for (; e != t;) 0 !== e.WindDelta && (t.WindCnt2 = 0 === t.WindCnt2 ? 1 : 0), e = e.NextInAEL;else for (; e != t;) t.WindCnt2 += e.WindDelta, e = e.NextInAEL;
  }, i.Clipper.prototype.AddEdgeToSEL = function (t) {
    null === (this || _global).m_SortedEdges ? ((this || _global).m_SortedEdges = t, t.PrevInSEL = null, t.NextInSEL = null) : (t.NextInSEL = (this || _global).m_SortedEdges, t.PrevInSEL = null, (this || _global).m_SortedEdges.PrevInSEL = t, (this || _global).m_SortedEdges = t);
  }, i.Clipper.prototype.CopyAELToSEL = function () {
    var t = (this || _global).m_ActiveEdges;

    for ((this || _global).m_SortedEdges = t; null !== t;) t.PrevInSEL = t.PrevInAEL, t.NextInSEL = t.NextInAEL, t = t.NextInAEL;
  }, i.Clipper.prototype.SwapPositionsInAEL = function (t, e) {
    if (t.NextInAEL != t.PrevInAEL && e.NextInAEL != e.PrevInAEL) {
      if (t.NextInAEL == e) null !== (n = e.NextInAEL) && (n.PrevInAEL = t), null !== (r = t.PrevInAEL) && (r.NextInAEL = e), e.PrevInAEL = r, e.NextInAEL = t, t.PrevInAEL = e, t.NextInAEL = n;else if (e.NextInAEL == t) {
        null !== (n = t.NextInAEL) && (n.PrevInAEL = e), null !== (r = e.PrevInAEL) && (r.NextInAEL = t), t.PrevInAEL = r, t.NextInAEL = e, e.PrevInAEL = t, e.NextInAEL = n;
      } else {
        var n = t.NextInAEL,
            r = t.PrevInAEL;
        t.NextInAEL = e.NextInAEL, null !== t.NextInAEL && (t.NextInAEL.PrevInAEL = t), t.PrevInAEL = e.PrevInAEL, null !== t.PrevInAEL && (t.PrevInAEL.NextInAEL = t), e.NextInAEL = n, null !== e.NextInAEL && (e.NextInAEL.PrevInAEL = e), e.PrevInAEL = r, null !== e.PrevInAEL && (e.PrevInAEL.NextInAEL = e);
      }
      null === t.PrevInAEL ? (this || _global).m_ActiveEdges = t : null === e.PrevInAEL && ((this || _global).m_ActiveEdges = e);
    }
  }, i.Clipper.prototype.SwapPositionsInSEL = function (t, e) {
    if (!(null === t.NextInSEL && null === t.PrevInSEL || null === e.NextInSEL && null === e.PrevInSEL)) {
      if (t.NextInSEL == e) null !== (n = e.NextInSEL) && (n.PrevInSEL = t), null !== (r = t.PrevInSEL) && (r.NextInSEL = e), e.PrevInSEL = r, e.NextInSEL = t, t.PrevInSEL = e, t.NextInSEL = n;else if (e.NextInSEL == t) {
        null !== (n = t.NextInSEL) && (n.PrevInSEL = e), null !== (r = e.PrevInSEL) && (r.NextInSEL = t), t.PrevInSEL = r, t.NextInSEL = e, e.PrevInSEL = t, e.NextInSEL = n;
      } else {
        var n = t.NextInSEL,
            r = t.PrevInSEL;
        t.NextInSEL = e.NextInSEL, null !== t.NextInSEL && (t.NextInSEL.PrevInSEL = t), t.PrevInSEL = e.PrevInSEL, null !== t.PrevInSEL && (t.PrevInSEL.NextInSEL = t), e.NextInSEL = n, null !== e.NextInSEL && (e.NextInSEL.PrevInSEL = e), e.PrevInSEL = r, null !== e.PrevInSEL && (e.PrevInSEL.NextInSEL = e);
      }
      null === t.PrevInSEL ? (this || _global).m_SortedEdges = t : null === e.PrevInSEL && ((this || _global).m_SortedEdges = e);
    }
  }, i.Clipper.prototype.AddLocalMaxPoly = function (t, e, n) {
    this.AddOutPt(t, n), 0 == e.WindDelta && this.AddOutPt(e, n), t.OutIdx == e.OutIdx ? (t.OutIdx = -1, e.OutIdx = -1) : t.OutIdx < e.OutIdx ? this.AppendPolygon(t, e) : this.AppendPolygon(e, t);
  }, i.Clipper.prototype.AddLocalMinPoly = function (t, e, n) {
    var r, o, s;

    if (i.ClipperBase.IsHorizontal(e) || t.Dx > e.Dx ? (r = this.AddOutPt(t, n), e.OutIdx = t.OutIdx, t.Side = i.EdgeSide.esLeft, e.Side = i.EdgeSide.esRight, s = (o = t).PrevInAEL == e ? e.PrevInAEL : o.PrevInAEL) : (r = this.AddOutPt(e, n), t.OutIdx = e.OutIdx, t.Side = i.EdgeSide.esRight, e.Side = i.EdgeSide.esLeft, s = (o = e).PrevInAEL == t ? t.PrevInAEL : o.PrevInAEL), null !== s && s.OutIdx >= 0 && i.Clipper.TopX(s, n.Y) == i.Clipper.TopX(o, n.Y) && i.ClipperBase.SlopesEqual(o, s, (this || _global).m_UseFullRange) && 0 !== o.WindDelta && 0 !== s.WindDelta) {
      var u = this.AddOutPt(s, n);
      this.AddJoin(r, u, o.Top);
    }

    return r;
  }, i.Clipper.prototype.CreateOutRec = function () {
    var t = new i.OutRec();
    return t.Idx = -1, t.IsHole = !1, t.IsOpen = !1, t.FirstLeft = null, t.Pts = null, t.BottomPt = null, t.PolyNode = null, (this || _global).m_PolyOuts.push(t), t.Idx = (this || _global).m_PolyOuts.length - 1, t;
  }, i.Clipper.prototype.AddOutPt = function (t, e) {
    var n = t.Side == i.EdgeSide.esLeft;

    if (t.OutIdx < 0) {
      (o = this.CreateOutRec()).IsOpen = 0 === t.WindDelta;
      var r = new i.OutPt();
      return o.Pts = r, r.Idx = o.Idx, r.Pt.X = e.X, r.Pt.Y = e.Y, r.Next = r, r.Prev = r, o.IsOpen || this.SetHoleState(t, o), t.OutIdx = o.Idx, r;
    }

    var o,
        s = (o = (this || _global).m_PolyOuts[t.OutIdx]).Pts;
    return n && i.IntPoint.op_Equality(e, s.Pt) ? s : !n && i.IntPoint.op_Equality(e, s.Prev.Pt) ? s.Prev : ((r = new i.OutPt()).Idx = o.Idx, r.Pt.X = e.X, r.Pt.Y = e.Y, r.Next = s, r.Prev = s.Prev, r.Prev.Next = r, s.Prev = r, n && (o.Pts = r), r);
  }, i.Clipper.prototype.SwapPoints = function (t, e) {
    var n = new i.IntPoint(t.Value);
    t.Value.X = e.Value.X, t.Value.Y = e.Value.Y, e.Value.X = n.X, e.Value.Y = n.Y;
  }, i.Clipper.prototype.HorzSegmentsOverlap = function (t, e, n, r) {
    return t.X > n.X == t.X < r.X || e.X > n.X == e.X < r.X || n.X > t.X == n.X < e.X || r.X > t.X == r.X < e.X || t.X == n.X && e.X == r.X || t.X == r.X && e.X == n.X;
  }, i.Clipper.prototype.InsertPolyPtBetween = function (t, e, n) {
    var r = new i.OutPt();
    return r.Pt.X = n.X, r.Pt.Y = n.Y, e == t.Next ? (t.Next = r, e.Prev = r, r.Next = e, r.Prev = t) : (e.Next = r, t.Prev = r, r.Next = t, r.Prev = e), r;
  }, i.Clipper.prototype.SetHoleState = function (t, e) {
    for (var n = !1, r = t.PrevInAEL; null !== r;) r.OutIdx >= 0 && 0 != r.WindDelta && (n = !n, null === e.FirstLeft && (e.FirstLeft = (this || _global).m_PolyOuts[r.OutIdx])), r = r.PrevInAEL;

    n && (e.IsHole = !0);
  }, i.Clipper.prototype.GetDx = function (t, e) {
    return t.Y == e.Y ? i.ClipperBase.horizontal : (e.X - t.X) / (e.Y - t.Y);
  }, i.Clipper.prototype.FirstIsBottomPt = function (t, e) {
    for (var n = t.Prev; i.IntPoint.op_Equality(n.Pt, t.Pt) && n != t;) n = n.Prev;

    var r = Math.abs(this.GetDx(t.Pt, n.Pt));

    for (n = t.Next; i.IntPoint.op_Equality(n.Pt, t.Pt) && n != t;) n = n.Next;

    var o = Math.abs(this.GetDx(t.Pt, n.Pt));

    for (n = e.Prev; i.IntPoint.op_Equality(n.Pt, e.Pt) && n != e;) n = n.Prev;

    var s = Math.abs(this.GetDx(e.Pt, n.Pt));

    for (n = e.Next; i.IntPoint.op_Equality(n.Pt, e.Pt) && n != e;) n = n.Next;

    var u = Math.abs(this.GetDx(e.Pt, n.Pt));
    return r >= s && r >= u || o >= s && o >= u;
  }, i.Clipper.prototype.GetBottomPt = function (t) {
    for (var e = null, n = t.Next; n != t;) n.Pt.Y > t.Pt.Y ? (t = n, e = null) : n.Pt.Y == t.Pt.Y && n.Pt.X <= t.Pt.X && (n.Pt.X < t.Pt.X ? (e = null, t = n) : n.Next != t && n.Prev != t && (e = n)), n = n.Next;

    if (null !== e) for (; e != n;) for (this.FirstIsBottomPt(n, e) || (t = e), e = e.Next; i.IntPoint.op_Inequality(e.Pt, t.Pt);) e = e.Next;
    return t;
  }, i.Clipper.prototype.GetLowermostRec = function (t, e) {
    null === t.BottomPt && (t.BottomPt = this.GetBottomPt(t.Pts)), null === e.BottomPt && (e.BottomPt = this.GetBottomPt(e.Pts));
    var n = t.BottomPt,
        r = e.BottomPt;
    return n.Pt.Y > r.Pt.Y ? t : n.Pt.Y < r.Pt.Y ? e : n.Pt.X < r.Pt.X ? t : n.Pt.X > r.Pt.X ? e : n.Next == n ? e : r.Next == r ? t : this.FirstIsBottomPt(n, r) ? t : e;
  }, i.Clipper.prototype.Param1RightOfParam2 = function (t, e) {
    do {
      if ((t = t.FirstLeft) == e) return !0;
    } while (null !== t);

    return !1;
  }, i.Clipper.prototype.GetOutRec = function (t) {
    for (var e = (this || _global).m_PolyOuts[t]; e != (this || _global).m_PolyOuts[e.Idx];) e = (this || _global).m_PolyOuts[e.Idx];

    return e;
  }, i.Clipper.prototype.AppendPolygon = function (t, e) {
    var n,
        r = (this || _global).m_PolyOuts[t.OutIdx],
        o = (this || _global).m_PolyOuts[e.OutIdx];
    n = this.Param1RightOfParam2(r, o) ? o : this.Param1RightOfParam2(o, r) ? r : this.GetLowermostRec(r, o);
    var s,
        u = r.Pts,
        a = u.Prev,
        l = o.Pts,
        f = l.Prev;
    t.Side == i.EdgeSide.esLeft ? (e.Side == i.EdgeSide.esLeft ? (this.ReversePolyPtLinks(l), l.Next = u, u.Prev = l, a.Next = f, f.Prev = a, r.Pts = f) : (f.Next = u, u.Prev = f, l.Prev = a, a.Next = l, r.Pts = l), s = i.EdgeSide.esLeft) : (e.Side == i.EdgeSide.esRight ? (this.ReversePolyPtLinks(l), a.Next = f, f.Prev = a, l.Next = u, u.Prev = l) : (a.Next = l, l.Prev = a, u.Prev = f, f.Next = u), s = i.EdgeSide.esRight), r.BottomPt = null, n == o && (o.FirstLeft != r && (r.FirstLeft = o.FirstLeft), r.IsHole = o.IsHole), o.Pts = null, o.BottomPt = null, o.FirstLeft = r;
    var c = t.OutIdx,
        h = e.OutIdx;
    t.OutIdx = -1, e.OutIdx = -1;

    for (var p = (this || _global).m_ActiveEdges; null !== p;) {
      if (p.OutIdx == h) {
        p.OutIdx = c, p.Side = s;
        break;
      }

      p = p.NextInAEL;
    }

    o.Idx = r.Idx;
  }, i.Clipper.prototype.ReversePolyPtLinks = function (t) {
    if (null !== t) {
      var e, n;
      e = t;

      do {
        n = e.Next, e.Next = e.Prev, e.Prev = n, e = n;
      } while (e != t);
    }
  }, i.Clipper.SwapSides = function (t, e) {
    var n = t.Side;
    t.Side = e.Side, e.Side = n;
  }, i.Clipper.SwapPolyIndexes = function (t, e) {
    var n = t.OutIdx;
    t.OutIdx = e.OutIdx, e.OutIdx = n;
  }, i.Clipper.prototype.IntersectEdges = function (t, e, n, r) {
    var o,
        s,
        u,
        a,
        l,
        f,
        c = !r && null === t.NextInLML && t.Top.X == n.X && t.Top.Y == n.Y,
        h = !r && null === e.NextInLML && e.Top.X == n.X && e.Top.Y == n.Y,
        p = t.OutIdx >= 0,
        d = e.OutIdx >= 0;
    if (0 === t.WindDelta || 0 === e.WindDelta) return 0 === t.WindDelta && 0 === e.WindDelta ? (c || h) && p && d && this.AddLocalMaxPoly(t, e, n) : t.PolyTyp == e.PolyTyp && t.WindDelta != e.WindDelta && (this || _global).m_ClipType == i.ClipType.ctUnion ? 0 === t.WindDelta ? d && (this.AddOutPt(t, n), p && (t.OutIdx = -1)) : p && (this.AddOutPt(e, n), d && (e.OutIdx = -1)) : t.PolyTyp != e.PolyTyp && (0 !== t.WindDelta || 1 != Math.abs(e.WindCnt) || (this || _global).m_ClipType == i.ClipType.ctUnion && 0 !== e.WindCnt2 ? 0 !== e.WindDelta || 1 != Math.abs(t.WindCnt) || (this || _global).m_ClipType == i.ClipType.ctUnion && 0 !== t.WindCnt2 || (this.AddOutPt(e, n), d && (e.OutIdx = -1)) : (this.AddOutPt(t, n), p && (t.OutIdx = -1))), c && (t.OutIdx < 0 ? this.DeleteFromAEL(t) : i.Error("Error intersecting polylines")), void (h && (e.OutIdx < 0 ? this.DeleteFromAEL(e) : i.Error("Error intersecting polylines")));
    if (t.PolyTyp == e.PolyTyp) {
      if (this.IsEvenOddFillType(t)) {
        var v = t.WindCnt;
        t.WindCnt = e.WindCnt, e.WindCnt = v;
      } else t.WindCnt + e.WindDelta === 0 ? t.WindCnt = -t.WindCnt : t.WindCnt += e.WindDelta, e.WindCnt - t.WindDelta == 0 ? e.WindCnt = -e.WindCnt : e.WindCnt -= t.WindDelta;
    } else this.IsEvenOddFillType(e) ? t.WindCnt2 = 0 === t.WindCnt2 ? 1 : 0 : t.WindCnt2 += e.WindDelta, this.IsEvenOddFillType(t) ? e.WindCnt2 = 0 === e.WindCnt2 ? 1 : 0 : e.WindCnt2 -= t.WindDelta;

    switch (t.PolyTyp == i.PolyType.ptSubject ? (o = (this || _global).m_SubjFillType, u = (this || _global).m_ClipFillType) : (o = (this || _global).m_ClipFillType, u = (this || _global).m_SubjFillType), e.PolyTyp == i.PolyType.ptSubject ? (s = (this || _global).m_SubjFillType, a = (this || _global).m_ClipFillType) : (s = (this || _global).m_ClipFillType, a = (this || _global).m_SubjFillType), o) {
      case i.PolyFillType.pftPositive:
        l = t.WindCnt;
        break;

      case i.PolyFillType.pftNegative:
        l = -t.WindCnt;
        break;

      default:
        l = Math.abs(t.WindCnt);
    }

    switch (s) {
      case i.PolyFillType.pftPositive:
        f = e.WindCnt;
        break;

      case i.PolyFillType.pftNegative:
        f = -e.WindCnt;
        break;

      default:
        f = Math.abs(e.WindCnt);
    }

    if (p && d) c || h || 0 !== l && 1 != l || 0 !== f && 1 != f || t.PolyTyp != e.PolyTyp && (this || _global).m_ClipType != i.ClipType.ctXor ? this.AddLocalMaxPoly(t, e, n) : (this.AddOutPt(t, n), this.AddOutPt(e, n), i.Clipper.SwapSides(t, e), i.Clipper.SwapPolyIndexes(t, e));else if (p) 0 !== f && 1 != f || (this.AddOutPt(t, n), i.Clipper.SwapSides(t, e), i.Clipper.SwapPolyIndexes(t, e));else if (d) 0 !== l && 1 != l || (this.AddOutPt(e, n), i.Clipper.SwapSides(t, e), i.Clipper.SwapPolyIndexes(t, e));else if (!(0 !== l && 1 != l || 0 !== f && 1 != f || c || h)) {
      var y, m;

      switch (u) {
        case i.PolyFillType.pftPositive:
          y = t.WindCnt2;
          break;

        case i.PolyFillType.pftNegative:
          y = -t.WindCnt2;
          break;

        default:
          y = Math.abs(t.WindCnt2);
      }

      switch (a) {
        case i.PolyFillType.pftPositive:
          m = e.WindCnt2;
          break;

        case i.PolyFillType.pftNegative:
          m = -e.WindCnt2;
          break;

        default:
          m = Math.abs(e.WindCnt2);
      }

      if (t.PolyTyp != e.PolyTyp) this.AddLocalMinPoly(t, e, n);else if (1 == l && 1 == f) switch ((this || _global).m_ClipType) {
        case i.ClipType.ctIntersection:
          y > 0 && m > 0 && this.AddLocalMinPoly(t, e, n);
          break;

        case i.ClipType.ctUnion:
          y <= 0 && m <= 0 && this.AddLocalMinPoly(t, e, n);
          break;

        case i.ClipType.ctDifference:
          (t.PolyTyp == i.PolyType.ptClip && y > 0 && m > 0 || t.PolyTyp == i.PolyType.ptSubject && y <= 0 && m <= 0) && this.AddLocalMinPoly(t, e, n);
          break;

        case i.ClipType.ctXor:
          this.AddLocalMinPoly(t, e, n);
      } else i.Clipper.SwapSides(t, e);
    }
    c != h && (c && t.OutIdx >= 0 || h && e.OutIdx >= 0) && (i.Clipper.SwapSides(t, e), i.Clipper.SwapPolyIndexes(t, e)), c && this.DeleteFromAEL(t), h && this.DeleteFromAEL(e);
  }, i.Clipper.prototype.DeleteFromAEL = function (t) {
    var e = t.PrevInAEL,
        n = t.NextInAEL;
    null === e && null === n && t != (this || _global).m_ActiveEdges || (null !== e ? e.NextInAEL = n : (this || _global).m_ActiveEdges = n, null !== n && (n.PrevInAEL = e), t.NextInAEL = null, t.PrevInAEL = null);
  }, i.Clipper.prototype.DeleteFromSEL = function (t) {
    var e = t.PrevInSEL,
        n = t.NextInSEL;
    null === e && null === n && t != (this || _global).m_SortedEdges || (null !== e ? e.NextInSEL = n : (this || _global).m_SortedEdges = n, null !== n && (n.PrevInSEL = e), t.NextInSEL = null, t.PrevInSEL = null);
  }, i.Clipper.prototype.UpdateEdgeIntoAEL = function (t) {
    null === t.NextInLML && i.Error("UpdateEdgeIntoAEL: invalid call");
    var e = t.PrevInAEL,
        n = t.NextInAEL;
    return t.NextInLML.OutIdx = t.OutIdx, null !== e ? e.NextInAEL = t.NextInLML : (this || _global).m_ActiveEdges = t.NextInLML, null !== n && (n.PrevInAEL = t.NextInLML), t.NextInLML.Side = t.Side, t.NextInLML.WindDelta = t.WindDelta, t.NextInLML.WindCnt = t.WindCnt, t.NextInLML.WindCnt2 = t.WindCnt2, (t = t.NextInLML).Curr.X = t.Bot.X, t.Curr.Y = t.Bot.Y, t.PrevInAEL = e, t.NextInAEL = n, i.ClipperBase.IsHorizontal(t) || this.InsertScanbeam(t.Top.Y), t;
  }, i.Clipper.prototype.ProcessHorizontals = function (t) {
    for (var e = (this || _global).m_SortedEdges; null !== e;) this.DeleteFromSEL(e), this.ProcessHorizontal(e, t), e = (this || _global).m_SortedEdges;
  }, i.Clipper.prototype.GetHorzDirection = function (t, e) {
    t.Bot.X < t.Top.X ? (e.Left = t.Bot.X, e.Right = t.Top.X, e.Dir = i.Direction.dLeftToRight) : (e.Left = t.Top.X, e.Right = t.Bot.X, e.Dir = i.Direction.dRightToLeft);
  }, i.Clipper.prototype.PrepareHorzJoins = function (t, e) {
    var n = (this || _global).m_PolyOuts[t.OutIdx].Pts;
    t.Side != i.EdgeSide.esLeft && (n = n.Prev), e && (i.IntPoint.op_Equality(n.Pt, t.Top) ? this.AddGhostJoin(n, t.Bot) : this.AddGhostJoin(n, t.Top));
  }, i.Clipper.prototype.ProcessHorizontal = function (t, e) {
    var n = {
      Dir: null,
      Left: null,
      Right: null
    };
    this.GetHorzDirection(t, n);

    for (var r = n.Dir, o = n.Left, s = n.Right, u = t, a = null; null !== u.NextInLML && i.ClipperBase.IsHorizontal(u.NextInLML);) u = u.NextInLML;

    for (null === u.NextInLML && (a = this.GetMaximaPair(u));;) {
      for (var l = t == u, f = this.GetNextInAEL(t, r); null !== f && !(f.Curr.X == t.Top.X && null !== t.NextInLML && f.Dx < t.NextInLML.Dx);) {
        var c = this.GetNextInAEL(f, r);

        if (r == i.Direction.dLeftToRight && f.Curr.X <= s || r == i.Direction.dRightToLeft && f.Curr.X >= o) {
          if (t.OutIdx >= 0 && 0 != t.WindDelta && this.PrepareHorzJoins(t, e), f == a && l) return r == i.Direction.dLeftToRight ? this.IntersectEdges(t, f, f.Top, !1) : this.IntersectEdges(f, t, f.Top, !1), void (a.OutIdx >= 0 && i.Error("ProcessHorizontal error"));

          if (r == i.Direction.dLeftToRight) {
            var h = new i.IntPoint(f.Curr.X, t.Curr.Y);
            this.IntersectEdges(t, f, h, !0);
          } else {
            h = new i.IntPoint(f.Curr.X, t.Curr.Y);
            this.IntersectEdges(f, t, h, !0);
          }

          this.SwapPositionsInAEL(t, f);
        } else if (r == i.Direction.dLeftToRight && f.Curr.X >= s || r == i.Direction.dRightToLeft && f.Curr.X <= o) break;

        f = c;
      }

      if (t.OutIdx >= 0 && 0 !== t.WindDelta && this.PrepareHorzJoins(t, e), null === t.NextInLML || !i.ClipperBase.IsHorizontal(t.NextInLML)) break;
      (t = this.UpdateEdgeIntoAEL(t)).OutIdx >= 0 && this.AddOutPt(t, t.Bot);
      n = {
        Dir: r,
        Left: o,
        Right: s
      };
      this.GetHorzDirection(t, n), r = n.Dir, o = n.Left, s = n.Right;
    }

    if (null !== t.NextInLML) {
      if (t.OutIdx >= 0) {
        var p = this.AddOutPt(t, t.Top);
        if (0 === (t = this.UpdateEdgeIntoAEL(t)).WindDelta) return;
        var d = t.PrevInAEL;
        c = t.NextInAEL;

        if (null !== d && d.Curr.X == t.Bot.X && d.Curr.Y == t.Bot.Y && 0 !== d.WindDelta && d.OutIdx >= 0 && d.Curr.Y > d.Top.Y && i.ClipperBase.SlopesEqual(t, d, (this || _global).m_UseFullRange)) {
          var v = this.AddOutPt(d, t.Bot);
          this.AddJoin(p, v, t.Top);
        } else if (null !== c && c.Curr.X == t.Bot.X && c.Curr.Y == t.Bot.Y && 0 !== c.WindDelta && c.OutIdx >= 0 && c.Curr.Y > c.Top.Y && i.ClipperBase.SlopesEqual(t, c, (this || _global).m_UseFullRange)) {
          v = this.AddOutPt(c, t.Bot);
          this.AddJoin(p, v, t.Top);
        }
      } else t = this.UpdateEdgeIntoAEL(t);
    } else null !== a ? a.OutIdx >= 0 ? (r == i.Direction.dLeftToRight ? this.IntersectEdges(t, a, t.Top, !1) : this.IntersectEdges(a, t, t.Top, !1), a.OutIdx >= 0 && i.Error("ProcessHorizontal error")) : (this.DeleteFromAEL(t), this.DeleteFromAEL(a)) : (t.OutIdx >= 0 && this.AddOutPt(t, t.Top), this.DeleteFromAEL(t));
  }, i.Clipper.prototype.GetNextInAEL = function (t, e) {
    return e == i.Direction.dLeftToRight ? t.NextInAEL : t.PrevInAEL;
  }, i.Clipper.prototype.IsMinima = function (t) {
    return null !== t && t.Prev.NextInLML != t && t.Next.NextInLML != t;
  }, i.Clipper.prototype.IsMaxima = function (t, e) {
    return null !== t && t.Top.Y == e && null === t.NextInLML;
  }, i.Clipper.prototype.IsIntermediate = function (t, e) {
    return t.Top.Y == e && null !== t.NextInLML;
  }, i.Clipper.prototype.GetMaximaPair = function (t) {
    var e = null;
    return i.IntPoint.op_Equality(t.Next.Top, t.Top) && null === t.Next.NextInLML ? e = t.Next : i.IntPoint.op_Equality(t.Prev.Top, t.Top) && null === t.Prev.NextInLML && (e = t.Prev), null === e || -2 != e.OutIdx && (e.NextInAEL != e.PrevInAEL || i.ClipperBase.IsHorizontal(e)) ? e : null;
  }, i.Clipper.prototype.ProcessIntersections = function (t, e) {
    if (null == (this || _global).m_ActiveEdges) return !0;

    try {
      if (this.BuildIntersectList(t, e), 0 == (this || _global).m_IntersectList.length) return !0;
      if (1 != (this || _global).m_IntersectList.length && !this.FixupIntersectionOrder()) return !1;
      this.ProcessIntersectList();
    } catch (t) {
      (this || _global).m_SortedEdges = null, (this || _global).m_IntersectList.length = 0, i.Error("ProcessIntersections error");
    }

    return (this || _global).m_SortedEdges = null, !0;
  }, i.Clipper.prototype.BuildIntersectList = function (t, e) {
    if (null !== (this || _global).m_ActiveEdges) {
      var n = (this || _global).m_ActiveEdges;

      for ((this || _global).m_SortedEdges = n; null !== n;) n.PrevInSEL = n.PrevInAEL, n.NextInSEL = n.NextInAEL, n.Curr.X = i.Clipper.TopX(n, e), n = n.NextInAEL;

      for (var r = !0; r && null !== (this || _global).m_SortedEdges;) {
        for (r = !1, n = (this || _global).m_SortedEdges; null !== n.NextInSEL;) {
          var o = n.NextInSEL,
              s = new i.IntPoint();

          if (n.Curr.X > o.Curr.X) {
            !this.IntersectPoint(n, o, s) && n.Curr.X > o.Curr.X + 1 && i.Error("Intersection error"), s.Y > t && (s.Y = t, Math.abs(n.Dx) > Math.abs(o.Dx) ? s.X = i.Clipper.TopX(o, t) : s.X = i.Clipper.TopX(n, t));
            var u = new i.IntersectNode();
            u.Edge1 = n, u.Edge2 = o, u.Pt.X = s.X, u.Pt.Y = s.Y, (this || _global).m_IntersectList.push(u), this.SwapPositionsInSEL(n, o), r = !0;
          } else n = o;
        }

        if (null === n.PrevInSEL) break;
        n.PrevInSEL.NextInSEL = null;
      }

      (this || _global).m_SortedEdges = null;
    }
  }, i.Clipper.prototype.EdgesAdjacent = function (t) {
    return t.Edge1.NextInSEL == t.Edge2 || t.Edge1.PrevInSEL == t.Edge2;
  }, i.Clipper.IntersectNodeSort = function (t, e) {
    return e.Pt.Y - t.Pt.Y;
  }, i.Clipper.prototype.FixupIntersectionOrder = function () {
    (this || _global).m_IntersectList.sort((this || _global).m_IntersectNodeComparer), this.CopyAELToSEL();

    for (var t = (this || _global).m_IntersectList.length, e = 0; e < t; e++) {
      if (!this.EdgesAdjacent((this || _global).m_IntersectList[e])) {
        for (var n = e + 1; n < t && !this.EdgesAdjacent((this || _global).m_IntersectList[n]);) n++;

        if (n == t) return !1;
        var r = (this || _global).m_IntersectList[e];
        (this || _global).m_IntersectList[e] = (this || _global).m_IntersectList[n], (this || _global).m_IntersectList[n] = r;
      }

      this.SwapPositionsInSEL((this || _global).m_IntersectList[e].Edge1, (this || _global).m_IntersectList[e].Edge2);
    }

    return !0;
  }, i.Clipper.prototype.ProcessIntersectList = function () {
    for (var t = 0, e = (this || _global).m_IntersectList.length; t < e; t++) {
      var n = (this || _global).m_IntersectList[t];
      this.IntersectEdges(n.Edge1, n.Edge2, n.Pt, !0), this.SwapPositionsInAEL(n.Edge1, n.Edge2);
    }

    (this || _global).m_IntersectList.length = 0;
  };
  a.msie ? i.Clipper.Round = function (t) {
    return t < 0 ? Math.ceil(t - 0.5) : Math.round(t);
  } : a.chromium ? i.Clipper.Round = function (t) {
    return t < 0 ? -Math.round(Math.abs(t)) : Math.round(t);
  } : a.safari ? i.Clipper.Round = function (t) {
    return t < 0 ? (t -= 0.5) < -2147483648 ? Math.ceil(t) : 0 | t : (t += 0.5) > 2147483647 ? Math.floor(t) : 0 | t;
  } : i.Clipper.Round = function (t) {
    return t < 0 ? Math.ceil(t - 0.5) : Math.floor(t + 0.5);
  }, i.Clipper.TopX = function (t, e) {
    return e == t.Top.Y ? t.Top.X : t.Bot.X + i.Clipper.Round(t.Dx * (e - t.Bot.Y));
  }, i.Clipper.prototype.IntersectPoint = function (t, e, n) {
    var r, o;
    if (n.X = 0, n.Y = 0, i.ClipperBase.SlopesEqual(t, e, (this || _global).m_UseFullRange) || t.Dx == e.Dx) return e.Bot.Y > t.Bot.Y ? (n.X = e.Bot.X, n.Y = e.Bot.Y) : (n.X = t.Bot.X, n.Y = t.Bot.Y), !1;
    if (0 === t.Delta.X) n.X = t.Bot.X, i.ClipperBase.IsHorizontal(e) ? n.Y = e.Bot.Y : (o = e.Bot.Y - e.Bot.X / e.Dx, n.Y = i.Clipper.Round(n.X / e.Dx + o));else if (0 === e.Delta.X) n.X = e.Bot.X, i.ClipperBase.IsHorizontal(t) ? n.Y = t.Bot.Y : (r = t.Bot.Y - t.Bot.X / t.Dx, n.Y = i.Clipper.Round(n.X / t.Dx + r));else {
      r = t.Bot.X - t.Bot.Y * t.Dx;
      var s = ((o = e.Bot.X - e.Bot.Y * e.Dx) - r) / (t.Dx - e.Dx);
      n.Y = i.Clipper.Round(s), Math.abs(t.Dx) < Math.abs(e.Dx) ? n.X = i.Clipper.Round(t.Dx * s + r) : n.X = i.Clipper.Round(e.Dx * s + o);
    }

    if (n.Y < t.Top.Y || n.Y < e.Top.Y) {
      if (t.Top.Y > e.Top.Y) return n.Y = t.Top.Y, n.X = i.Clipper.TopX(e, t.Top.Y), n.X < t.Top.X;
      n.Y = e.Top.Y, Math.abs(t.Dx) < Math.abs(e.Dx) ? n.X = i.Clipper.TopX(t, n.Y) : n.X = i.Clipper.TopX(e, n.Y);
    }

    return !0;
  }, i.Clipper.prototype.ProcessEdgesAtTopOfScanbeam = function (t) {
    for (var e = (this || _global).m_ActiveEdges; null !== e;) {
      var n = this.IsMaxima(e, t);

      if (n) {
        var r = this.GetMaximaPair(e);
        n = null === r || !i.ClipperBase.IsHorizontal(r);
      }

      if (n) {
        var o = e.PrevInAEL;
        this.DoMaxima(e), e = null === o ? (this || _global).m_ActiveEdges : o.NextInAEL;
      } else {
        if (this.IsIntermediate(e, t) && i.ClipperBase.IsHorizontal(e.NextInLML) ? ((e = this.UpdateEdgeIntoAEL(e)).OutIdx >= 0 && this.AddOutPt(e, e.Bot), this.AddEdgeToSEL(e)) : (e.Curr.X = i.Clipper.TopX(e, t), e.Curr.Y = t), (this || _global).StrictlySimple) {
          o = e.PrevInAEL;

          if (e.OutIdx >= 0 && 0 !== e.WindDelta && null !== o && o.OutIdx >= 0 && o.Curr.X == e.Curr.X && 0 !== o.WindDelta) {
            var s = this.AddOutPt(o, e.Curr),
                u = this.AddOutPt(e, e.Curr);
            this.AddJoin(s, u, e.Curr);
          }
        }

        e = e.NextInAEL;
      }
    }

    for (this.ProcessHorizontals(!0), e = (this || _global).m_ActiveEdges; null !== e;) {
      if (this.IsIntermediate(e, t)) {
        s = null;
        e.OutIdx >= 0 && (s = this.AddOutPt(e, e.Top));
        o = (e = this.UpdateEdgeIntoAEL(e)).PrevInAEL;
        var a = e.NextInAEL;

        if (null !== o && o.Curr.X == e.Bot.X && o.Curr.Y == e.Bot.Y && null !== s && o.OutIdx >= 0 && o.Curr.Y > o.Top.Y && i.ClipperBase.SlopesEqual(e, o, (this || _global).m_UseFullRange) && 0 !== e.WindDelta && 0 !== o.WindDelta) {
          u = this.AddOutPt(o, e.Bot);
          this.AddJoin(s, u, e.Top);
        } else if (null !== a && a.Curr.X == e.Bot.X && a.Curr.Y == e.Bot.Y && null !== s && a.OutIdx >= 0 && a.Curr.Y > a.Top.Y && i.ClipperBase.SlopesEqual(e, a, (this || _global).m_UseFullRange) && 0 !== e.WindDelta && 0 !== a.WindDelta) {
          u = this.AddOutPt(a, e.Bot);
          this.AddJoin(s, u, e.Top);
        }
      }

      e = e.NextInAEL;
    }
  }, i.Clipper.prototype.DoMaxima = function (t) {
    var e = this.GetMaximaPair(t);
    if (null === e) return t.OutIdx >= 0 && this.AddOutPt(t, t.Top), void this.DeleteFromAEL(t);

    for (var n = t.NextInAEL; null !== n && n != e;) this.IntersectEdges(t, n, t.Top, !0), this.SwapPositionsInAEL(t, n), n = t.NextInAEL;

    -1 == t.OutIdx && -1 == e.OutIdx ? (this.DeleteFromAEL(t), this.DeleteFromAEL(e)) : t.OutIdx >= 0 && e.OutIdx >= 0 ? this.IntersectEdges(t, e, t.Top, !1) : 0 === t.WindDelta ? (t.OutIdx >= 0 && (this.AddOutPt(t, t.Top), t.OutIdx = -1), this.DeleteFromAEL(t), e.OutIdx >= 0 && (this.AddOutPt(e, t.Top), e.OutIdx = -1), this.DeleteFromAEL(e)) : i.Error("DoMaxima error");
  }, i.Clipper.ReversePaths = function (t) {
    for (var e = 0, n = t.length; e < n; e++) t[e].reverse();
  }, i.Clipper.Orientation = function (t) {
    return i.Clipper.Area(t) >= 0;
  }, i.Clipper.prototype.PointCount = function (t) {
    if (null === t) return 0;
    var e = 0,
        n = t;

    do {
      e++, n = n.Next;
    } while (n != t);

    return e;
  }, i.Clipper.prototype.BuildResult = function (t) {
    i.Clear(t);

    for (var e = 0, n = (this || _global).m_PolyOuts.length; e < n; e++) {
      var r = (this || _global).m_PolyOuts[e];

      if (null !== r.Pts) {
        var o = r.Pts.Prev,
            s = this.PointCount(o);

        if (!(s < 2)) {
          for (var u = new Array(s), a = 0; a < s; a++) u[a] = o.Pt, o = o.Prev;

          t.push(u);
        }
      }
    }
  }, i.Clipper.prototype.BuildResult2 = function (t) {
    t.Clear();

    for (var e = 0, n = (this || _global).m_PolyOuts.length; e < n; e++) {
      var r = (this || _global).m_PolyOuts[e],
          o = this.PointCount(r.Pts);

      if (!(r.IsOpen && o < 2 || !r.IsOpen && o < 3)) {
        this.FixHoleLinkage(r);
        var s = new i.PolyNode();
        t.m_AllPolys.push(s), r.PolyNode = s, s.m_polygon.length = o;

        for (var u = r.Pts.Prev, a = 0; a < o; a++) s.m_polygon[a] = u.Pt, u = u.Prev;
      }
    }

    for (e = 0, n = (this || _global).m_PolyOuts.length; e < n; e++) {
      null !== (r = (this || _global).m_PolyOuts[e]).PolyNode && (r.IsOpen ? (r.PolyNode.IsOpen = !0, t.AddChild(r.PolyNode)) : null !== r.FirstLeft && null != r.FirstLeft.PolyNode ? r.FirstLeft.PolyNode.AddChild(r.PolyNode) : t.AddChild(r.PolyNode));
    }
  }, i.Clipper.prototype.FixupOutPolygon = function (t) {
    var e = null;
    t.BottomPt = null;

    for (var n = t.Pts;;) {
      if (n.Prev == n || n.Prev == n.Next) return this.DisposeOutPts(n), void (t.Pts = null);

      if (i.IntPoint.op_Equality(n.Pt, n.Next.Pt) || i.IntPoint.op_Equality(n.Pt, n.Prev.Pt) || i.ClipperBase.SlopesEqual(n.Prev.Pt, n.Pt, n.Next.Pt, (this || _global).m_UseFullRange) && (!(this || _global).PreserveCollinear || !this.Pt2IsBetweenPt1AndPt3(n.Prev.Pt, n.Pt, n.Next.Pt))) {
        e = null;
        n.Prev.Next = n.Next, n.Next.Prev = n.Prev, n = n.Prev, null;
      } else {
        if (n == e) break;
        null === e && (e = n), n = n.Next;
      }
    }

    t.Pts = n;
  }, i.Clipper.prototype.DupOutPt = function (t, e) {
    var n = new i.OutPt();
    return n.Pt.X = t.Pt.X, n.Pt.Y = t.Pt.Y, n.Idx = t.Idx, e ? (n.Next = t.Next, n.Prev = t, t.Next.Prev = n, t.Next = n) : (n.Prev = t.Prev, n.Next = t, t.Prev.Next = n, t.Prev = n), n;
  }, i.Clipper.prototype.GetOverlap = function (t, e, n, r, i) {
    return t < e ? n < r ? (i.Left = Math.max(t, n), i.Right = Math.min(e, r)) : (i.Left = Math.max(t, r), i.Right = Math.min(e, n)) : n < r ? (i.Left = Math.max(e, n), i.Right = Math.min(t, r)) : (i.Left = Math.max(e, r), i.Right = Math.min(t, n)), i.Left < i.Right;
  }, i.Clipper.prototype.JoinHorz = function (t, e, n, r, o, s) {
    var u = t.Pt.X > e.Pt.X ? i.Direction.dRightToLeft : i.Direction.dLeftToRight,
        a = n.Pt.X > r.Pt.X ? i.Direction.dRightToLeft : i.Direction.dLeftToRight;
    if (u == a) return !1;

    if (u == i.Direction.dLeftToRight) {
      for (; t.Next.Pt.X <= o.X && t.Next.Pt.X >= t.Pt.X && t.Next.Pt.Y == o.Y;) t = t.Next;

      s && t.Pt.X != o.X && (t = t.Next), e = this.DupOutPt(t, !s), i.IntPoint.op_Inequality(e.Pt, o) && ((t = e).Pt.X = o.X, t.Pt.Y = o.Y, e = this.DupOutPt(t, !s));
    } else {
      for (; t.Next.Pt.X >= o.X && t.Next.Pt.X <= t.Pt.X && t.Next.Pt.Y == o.Y;) t = t.Next;

      s || t.Pt.X == o.X || (t = t.Next), e = this.DupOutPt(t, s), i.IntPoint.op_Inequality(e.Pt, o) && ((t = e).Pt.X = o.X, t.Pt.Y = o.Y, e = this.DupOutPt(t, s));
    }

    if (a == i.Direction.dLeftToRight) {
      for (; n.Next.Pt.X <= o.X && n.Next.Pt.X >= n.Pt.X && n.Next.Pt.Y == o.Y;) n = n.Next;

      s && n.Pt.X != o.X && (n = n.Next), r = this.DupOutPt(n, !s), i.IntPoint.op_Inequality(r.Pt, o) && ((n = r).Pt.X = o.X, n.Pt.Y = o.Y, r = this.DupOutPt(n, !s));
    } else {
      for (; n.Next.Pt.X >= o.X && n.Next.Pt.X <= n.Pt.X && n.Next.Pt.Y == o.Y;) n = n.Next;

      s || n.Pt.X == o.X || (n = n.Next), r = this.DupOutPt(n, s), i.IntPoint.op_Inequality(r.Pt, o) && ((n = r).Pt.X = o.X, n.Pt.Y = o.Y, r = this.DupOutPt(n, s));
    }

    return u == i.Direction.dLeftToRight == s ? (t.Prev = n, n.Next = t, e.Next = r, r.Prev = e) : (t.Next = n, n.Prev = t, e.Prev = r, r.Next = e), !0;
  }, i.Clipper.prototype.JoinPoints = function (t, e, n) {
    var r = t.OutPt1,
        o = new i.OutPt(),
        s = t.OutPt2,
        u = new i.OutPt(),
        a = t.OutPt1.Pt.Y == t.OffPt.Y;

    if (a && i.IntPoint.op_Equality(t.OffPt, t.OutPt1.Pt) && i.IntPoint.op_Equality(t.OffPt, t.OutPt2.Pt)) {
      for (o = t.OutPt1.Next; o != r && i.IntPoint.op_Equality(o.Pt, t.OffPt);) o = o.Next;

      var l = o.Pt.Y > t.OffPt.Y;

      for (u = t.OutPt2.Next; u != s && i.IntPoint.op_Equality(u.Pt, t.OffPt);) u = u.Next;

      return l != u.Pt.Y > t.OffPt.Y && (l ? (o = this.DupOutPt(r, !1), u = this.DupOutPt(s, !0), r.Prev = s, s.Next = r, o.Next = u, u.Prev = o, t.OutPt1 = r, t.OutPt2 = o, !0) : (o = this.DupOutPt(r, !0), u = this.DupOutPt(s, !1), r.Next = s, s.Prev = r, o.Prev = u, u.Next = o, t.OutPt1 = r, t.OutPt2 = o, !0));
    }

    if (a) {
      for (o = r; r.Prev.Pt.Y == r.Pt.Y && r.Prev != o && r.Prev != s;) r = r.Prev;

      for (; o.Next.Pt.Y == o.Pt.Y && o.Next != r && o.Next != s;) o = o.Next;

      if (o.Next == r || o.Next == s) return !1;

      for (u = s; s.Prev.Pt.Y == s.Pt.Y && s.Prev != u && s.Prev != o;) s = s.Prev;

      for (; u.Next.Pt.Y == u.Pt.Y && u.Next != s && u.Next != r;) u = u.Next;

      if (u.Next == s || u.Next == r) return !1;
      var f = {
        Left: null,
        Right: null
      };
      if (!this.GetOverlap(r.Pt.X, o.Pt.X, s.Pt.X, u.Pt.X, f)) return !1;
      var c,
          h = f.Left,
          p = f.Right,
          d = new i.IntPoint();
      return r.Pt.X >= h && r.Pt.X <= p ? (d.X = r.Pt.X, d.Y = r.Pt.Y, c = r.Pt.X > o.Pt.X) : s.Pt.X >= h && s.Pt.X <= p ? (d.X = s.Pt.X, d.Y = s.Pt.Y, c = s.Pt.X > u.Pt.X) : o.Pt.X >= h && o.Pt.X <= p ? (d.X = o.Pt.X, d.Y = o.Pt.Y, c = o.Pt.X > r.Pt.X) : (d.X = u.Pt.X, d.Y = u.Pt.Y, c = u.Pt.X > s.Pt.X), t.OutPt1 = r, t.OutPt2 = s, this.JoinHorz(r, o, s, u, d, c);
    }

    for (o = r.Next; i.IntPoint.op_Equality(o.Pt, r.Pt) && o != r;) o = o.Next;

    var v = o.Pt.Y > r.Pt.Y || !i.ClipperBase.SlopesEqual(r.Pt, o.Pt, t.OffPt, (this || _global).m_UseFullRange);

    if (v) {
      for (o = r.Prev; i.IntPoint.op_Equality(o.Pt, r.Pt) && o != r;) o = o.Prev;

      if (o.Pt.Y > r.Pt.Y || !i.ClipperBase.SlopesEqual(r.Pt, o.Pt, t.OffPt, (this || _global).m_UseFullRange)) return !1;
    }

    for (u = s.Next; i.IntPoint.op_Equality(u.Pt, s.Pt) && u != s;) u = u.Next;

    var y = u.Pt.Y > s.Pt.Y || !i.ClipperBase.SlopesEqual(s.Pt, u.Pt, t.OffPt, (this || _global).m_UseFullRange);

    if (y) {
      for (u = s.Prev; i.IntPoint.op_Equality(u.Pt, s.Pt) && u != s;) u = u.Prev;

      if (u.Pt.Y > s.Pt.Y || !i.ClipperBase.SlopesEqual(s.Pt, u.Pt, t.OffPt, (this || _global).m_UseFullRange)) return !1;
    }

    return o != r && u != s && o != u && (e != n || v != y) && (v ? (o = this.DupOutPt(r, !1), u = this.DupOutPt(s, !0), r.Prev = s, s.Next = r, o.Next = u, u.Prev = o, t.OutPt1 = r, t.OutPt2 = o, !0) : (o = this.DupOutPt(r, !0), u = this.DupOutPt(s, !1), r.Next = s, s.Prev = r, o.Prev = u, u.Next = o, t.OutPt1 = r, t.OutPt2 = o, !0));
  }, i.Clipper.GetBounds = function (t) {
    for (var e = 0, n = t.length; e < n && 0 == t[e].length;) e++;

    if (e == n) return new i.IntRect(0, 0, 0, 0);
    var r = new i.IntRect();

    for (r.left = t[e][0].X, r.right = r.left, r.top = t[e][0].Y, r.bottom = r.top; e < n; e++) for (var o = 0, s = t[e].length; o < s; o++) t[e][o].X < r.left ? r.left = t[e][o].X : t[e][o].X > r.right && (r.right = t[e][o].X), t[e][o].Y < r.top ? r.top = t[e][o].Y : t[e][o].Y > r.bottom && (r.bottom = t[e][o].Y);

    return r;
  }, i.Clipper.prototype.GetBounds2 = function (t) {
    var e = t,
        n = new i.IntRect();

    for (n.left = t.Pt.X, n.right = t.Pt.X, n.top = t.Pt.Y, n.bottom = t.Pt.Y, t = t.Next; t != e;) t.Pt.X < n.left && (n.left = t.Pt.X), t.Pt.X > n.right && (n.right = t.Pt.X), t.Pt.Y < n.top && (n.top = t.Pt.Y), t.Pt.Y > n.bottom && (n.bottom = t.Pt.Y), t = t.Next;

    return n;
  }, i.Clipper.PointInPolygon = function (t, e) {
    var n = 0,
        r = e.length;
    if (r < 3) return 0;

    for (var i = e[0], o = 1; o <= r; ++o) {
      var s = o == r ? e[0] : e[o];
      if (s.Y == t.Y && (s.X == t.X || i.Y == t.Y && s.X > t.X == i.X < t.X)) return -1;
      if (i.Y < t.Y != s.Y < t.Y) if (i.X >= t.X) {
        if (s.X > t.X) n = 1 - n;else {
          if (0 == (u = (i.X - t.X) * (s.Y - t.Y) - (s.X - t.X) * (i.Y - t.Y))) return -1;
          u > 0 == s.Y > i.Y && (n = 1 - n);
        }
      } else if (s.X > t.X) {
        var u;
        if (0 == (u = (i.X - t.X) * (s.Y - t.Y) - (s.X - t.X) * (i.Y - t.Y))) return -1;
        u > 0 == s.Y > i.Y && (n = 1 - n);
      }
      i = s;
    }

    return n;
  }, i.Clipper.prototype.PointInPolygon = function (t, e) {
    for (var n = 0, r = e;;) {
      var i = e.Pt.X,
          o = e.Pt.Y,
          s = e.Next.Pt.X,
          u = e.Next.Pt.Y;
      if (u == t.Y && (s == t.X || o == t.Y && s > t.X == i < t.X)) return -1;
      if (o < t.Y != u < t.Y) if (i >= t.X) {
        if (s > t.X) n = 1 - n;else {
          if (0 == (a = (i - t.X) * (u - t.Y) - (s - t.X) * (o - t.Y))) return -1;
          a > 0 == u > o && (n = 1 - n);
        }
      } else if (s > t.X) {
        var a;
        if (0 == (a = (i - t.X) * (u - t.Y) - (s - t.X) * (o - t.Y))) return -1;
        a > 0 == u > o && (n = 1 - n);
      }
      if (r == (e = e.Next)) break;
    }

    return n;
  }, i.Clipper.prototype.Poly2ContainsPoly1 = function (t, e) {
    var n = t;

    do {
      var r = this.PointInPolygon(n.Pt, e);
      if (r >= 0) return 0 != r;
      n = n.Next;
    } while (n != t);

    return !0;
  }, i.Clipper.prototype.FixupFirstLefts1 = function (t, e) {
    for (var n = 0, r = (this || _global).m_PolyOuts.length; n < r; n++) {
      var i = (this || _global).m_PolyOuts[n];
      null !== i.Pts && i.FirstLeft == t && this.Poly2ContainsPoly1(i.Pts, e.Pts) && (i.FirstLeft = e);
    }
  }, i.Clipper.prototype.FixupFirstLefts2 = function (t, e) {
    for (var n = 0, r = (this || _global).m_PolyOuts, i = r.length, o = r[n]; n < i; o = r[++n]) o.FirstLeft == t && (o.FirstLeft = e);
  }, i.Clipper.ParseFirstLeft = function (t) {
    for (; null != t && null == t.Pts;) t = t.FirstLeft;

    return t;
  }, i.Clipper.prototype.JoinCommonEdges = function () {
    for (var t = 0, e = (this || _global).m_Joins.length; t < e; t++) {
      var n,
          r = (this || _global).m_Joins[t],
          o = this.GetOutRec(r.OutPt1.Idx),
          s = this.GetOutRec(r.OutPt2.Idx);
      if (null != o.Pts && null != s.Pts) if (n = o == s ? o : this.Param1RightOfParam2(o, s) ? s : this.Param1RightOfParam2(s, o) ? o : this.GetLowermostRec(o, s), this.JoinPoints(r, o, s)) if (o == s) {
        if (o.Pts = r.OutPt1, o.BottomPt = null, (s = this.CreateOutRec()).Pts = r.OutPt2, this.UpdateOutPtIdxs(s), (this || _global).m_UsingPolyTree) for (var u = 0, a = (this || _global).m_PolyOuts.length; u < a - 1; u++) {
          var l = (this || _global).m_PolyOuts[u];
          null != l.Pts && i.Clipper.ParseFirstLeft(l.FirstLeft) == o && l.IsHole != o.IsHole && this.Poly2ContainsPoly1(l.Pts, r.OutPt2) && (l.FirstLeft = s);
        }
        this.Poly2ContainsPoly1(s.Pts, o.Pts) ? (s.IsHole = !o.IsHole, s.FirstLeft = o, (this || _global).m_UsingPolyTree && this.FixupFirstLefts2(s, o), (s.IsHole ^ (this || _global).ReverseSolution) == this.Area(s) > 0 && this.ReversePolyPtLinks(s.Pts)) : this.Poly2ContainsPoly1(o.Pts, s.Pts) ? (s.IsHole = o.IsHole, o.IsHole = !s.IsHole, s.FirstLeft = o.FirstLeft, o.FirstLeft = s, (this || _global).m_UsingPolyTree && this.FixupFirstLefts2(o, s), (o.IsHole ^ (this || _global).ReverseSolution) == this.Area(o) > 0 && this.ReversePolyPtLinks(o.Pts)) : (s.IsHole = o.IsHole, s.FirstLeft = o.FirstLeft, (this || _global).m_UsingPolyTree && this.FixupFirstLefts1(o, s));
      } else s.Pts = null, s.BottomPt = null, s.Idx = o.Idx, o.IsHole = n.IsHole, n == s && (o.FirstLeft = s.FirstLeft), s.FirstLeft = o, (this || _global).m_UsingPolyTree && this.FixupFirstLefts2(s, o);
    }
  }, i.Clipper.prototype.UpdateOutPtIdxs = function (t) {
    var e = t.Pts;

    do {
      e.Idx = t.Idx, e = e.Prev;
    } while (e != t.Pts);
  }, i.Clipper.prototype.DoSimplePolygons = function () {
    for (var t = 0; t < (this || _global).m_PolyOuts.length;) {
      var e = (this || _global).m_PolyOuts[t++],
          n = e.Pts;
      if (null !== n) do {
        for (var r = n.Next; r != e.Pts;) {
          if (i.IntPoint.op_Equality(n.Pt, r.Pt) && r.Next != n && r.Prev != n) {
            var o = n.Prev,
                s = r.Prev;
            n.Prev = s, s.Next = n, r.Prev = o, o.Next = r, e.Pts = n;
            var u = this.CreateOutRec();
            u.Pts = r, this.UpdateOutPtIdxs(u), this.Poly2ContainsPoly1(u.Pts, e.Pts) ? (u.IsHole = !e.IsHole, u.FirstLeft = e) : this.Poly2ContainsPoly1(e.Pts, u.Pts) ? (u.IsHole = e.IsHole, e.IsHole = !u.IsHole, u.FirstLeft = e.FirstLeft, e.FirstLeft = u) : (u.IsHole = e.IsHole, u.FirstLeft = e.FirstLeft), r = n;
          }

          r = r.Next;
        }

        n = n.Next;
      } while (n != e.Pts);
    }
  }, i.Clipper.Area = function (t) {
    var e = t.length;
    if (e < 3) return 0;

    for (var n = 0, r = 0, i = e - 1; r < e; ++r) n += (t[i].X + t[r].X) * (t[i].Y - t[r].Y), i = r;

    return 0.5 * -n;
  }, i.Clipper.prototype.Area = function (t) {
    var e = t.Pts;
    if (null == e) return 0;
    var n = 0;

    do {
      n += (e.Prev.Pt.X + e.Pt.X) * (e.Prev.Pt.Y - e.Pt.Y), e = e.Next;
    } while (e != t.Pts);

    return 0.5 * n;
  }, i.Clipper.SimplifyPolygon = function (t, e) {
    var n = new Array(),
        r = new i.Clipper(0);
    return r.StrictlySimple = !0, r.AddPath(t, i.PolyType.ptSubject, !0), r.Execute(i.ClipType.ctUnion, n, e, e), n;
  }, i.Clipper.SimplifyPolygons = function (t, e) {
    void 0 === e && (e = i.PolyFillType.pftEvenOdd);
    var n = new Array(),
        r = new i.Clipper(0);
    return r.StrictlySimple = !0, r.AddPaths(t, i.PolyType.ptSubject, !0), r.Execute(i.ClipType.ctUnion, n, e, e), n;
  }, i.Clipper.DistanceSqrd = function (t, e) {
    var n = t.X - e.X,
        r = t.Y - e.Y;
    return n * n + r * r;
  }, i.Clipper.DistanceFromLineSqrd = function (t, e, n) {
    var r = e.Y - n.Y,
        i = n.X - e.X,
        o = r * e.X + i * e.Y;
    return (o = r * t.X + i * t.Y - o) * o / (r * r + i * i);
  }, i.Clipper.SlopesNearCollinear = function (t, e, n, r) {
    return i.Clipper.DistanceFromLineSqrd(e, t, n) < r;
  }, i.Clipper.PointsAreClose = function (t, e, n) {
    var r = t.X - e.X,
        i = t.Y - e.Y;
    return r * r + i * i <= n;
  }, i.Clipper.ExcludeOp = function (t) {
    var e = t.Prev;
    return e.Next = t.Next, t.Next.Prev = e, e.Idx = 0, e;
  }, i.Clipper.CleanPolygon = function (t, e) {
    void 0 === e && (e = 1.415);
    var n = t.length;
    if (0 == n) return new Array();

    for (var r = new Array(n), o = 0; o < n; ++o) r[o] = new i.OutPt();

    for (o = 0; o < n; ++o) r[o].Pt = t[o], r[o].Next = r[(o + 1) % n], r[o].Next.Prev = r[o], r[o].Idx = 0;

    for (var s = e * e, u = r[0]; 0 == u.Idx && u.Next != u.Prev;) i.Clipper.PointsAreClose(u.Pt, u.Prev.Pt, s) ? (u = i.Clipper.ExcludeOp(u), n--) : i.Clipper.PointsAreClose(u.Prev.Pt, u.Next.Pt, s) ? (i.Clipper.ExcludeOp(u.Next), u = i.Clipper.ExcludeOp(u), n -= 2) : i.Clipper.SlopesNearCollinear(u.Prev.Pt, u.Pt, u.Next.Pt, s) ? (u = i.Clipper.ExcludeOp(u), n--) : (u.Idx = 1, u = u.Next);

    n < 3 && (n = 0);
    var a = new Array(n);

    for (o = 0; o < n; ++o) a[o] = new i.IntPoint(u.Pt), u = u.Next;

    return r = null, a;
  }, i.Clipper.CleanPolygons = function (t, e) {
    for (var n = new Array(t.length), r = 0, o = t.length; r < o; r++) n[r] = i.Clipper.CleanPolygon(t[r], e);

    return n;
  }, i.Clipper.Minkowski = function (t, e, n, r) {
    var o = r ? 1 : 0,
        s = t.length,
        u = e.length,
        a = new Array();
    if (n) for (var l = 0; l < u; l++) {
      for (var f = new Array(s), c = 0, h = t.length, p = t[c]; c < h; p = t[++c]) f[c] = new i.IntPoint(e[l].X + p.X, e[l].Y + p.Y);

      a.push(f);
    } else for (l = 0; l < u; l++) {
      for (f = new Array(s), c = 0, h = t.length, p = t[c]; c < h; p = t[++c]) f[c] = new i.IntPoint(e[l].X - p.X, e[l].Y - p.Y);

      a.push(f);
    }
    var d = new Array();

    for (l = 0; l < u - 1 + o; l++) for (c = 0; c < s; c++) {
      var v = new Array();
      v.push(a[l % u][c % s]), v.push(a[(l + 1) % u][c % s]), v.push(a[(l + 1) % u][(c + 1) % s]), v.push(a[l % u][(c + 1) % s]), i.Clipper.Orientation(v) || v.reverse(), d.push(v);
    }

    var y = new i.Clipper(0);
    return y.AddPaths(d, i.PolyType.ptSubject, !0), y.Execute(i.ClipType.ctUnion, a, i.PolyFillType.pftNonZero, i.PolyFillType.pftNonZero), a;
  }, i.Clipper.MinkowskiSum = function () {
    var t = arguments,
        e = t.length;

    if (3 == e) {
      var n = t[0],
          r = t[1],
          o = t[2];
      return i.Clipper.Minkowski(n, r, !0, o);
    }

    if (4 == e) {
      n = t[0];

      for (var s = t[1], u = t[2], a = (o = t[3], new i.Clipper()), l = 0, f = s.length; l < f; ++l) {
        var c = i.Clipper.Minkowski(n, s[l], !0, o);
        a.AddPaths(c, i.PolyType.ptSubject, !0);
      }

      o && a.AddPaths(s, i.PolyType.ptClip, !0);
      var h = new i.Paths();
      return a.Execute(i.ClipType.ctUnion, h, u, u), h;
    }
  }, i.Clipper.MinkowskiDiff = function (t, e, n) {
    return i.Clipper.Minkowski(t, e, !1, n);
  }, i.Clipper.PolyTreeToPaths = function (t) {
    var e = new Array();
    return i.Clipper.AddPolyNodeToPaths(t, i.Clipper.NodeType.ntAny, e), e;
  }, i.Clipper.AddPolyNodeToPaths = function (t, e, n) {
    var r = !0;

    switch (e) {
      case i.Clipper.NodeType.ntOpen:
        return;

      case i.Clipper.NodeType.ntClosed:
        r = !t.IsOpen;
    }

    t.m_polygon.length > 0 && r && n.push(t.m_polygon);

    for (var o = 0, s = t.Childs(), u = s.length, a = s[o]; o < u; a = s[++o]) i.Clipper.AddPolyNodeToPaths(a, e, n);
  }, i.Clipper.OpenPathsFromPolyTree = function (t) {
    for (var e = new i.Paths(), n = 0, r = t.ChildCount(); n < r; n++) t.Childs()[n].IsOpen && e.push(t.Childs()[n].m_polygon);

    return e;
  }, i.Clipper.ClosedPathsFromPolyTree = function (t) {
    var e = new i.Paths();
    return i.Clipper.AddPolyNodeToPaths(t, i.Clipper.NodeType.ntClosed, e), e;
  }, X(i.Clipper, i.ClipperBase), i.Clipper.NodeType = {
    ntAny: 0,
    ntOpen: 1,
    ntClosed: 2
  }, i.ClipperOffset = function (t, e) {
    void 0 === t && (t = 2), void 0 === e && (e = i.ClipperOffset.def_arc_tolerance), (this || _global).m_destPolys = new i.Paths(), (this || _global).m_srcPoly = new i.Path(), (this || _global).m_destPoly = new i.Path(), (this || _global).m_normals = new Array(), (this || _global).m_delta = 0, (this || _global).m_sinA = 0, (this || _global).m_sin = 0, (this || _global).m_cos = 0, (this || _global).m_miterLim = 0, (this || _global).m_StepsPerRad = 0, (this || _global).m_lowest = new i.IntPoint(), (this || _global).m_polyNodes = new i.PolyNode(), (this || _global).MiterLimit = t, (this || _global).ArcTolerance = e, (this || _global).m_lowest.X = -1;
  }, i.ClipperOffset.two_pi = 6.28318530717959, i.ClipperOffset.def_arc_tolerance = 0.25, i.ClipperOffset.prototype.Clear = function () {
    i.Clear((this || _global).m_polyNodes.Childs()), (this || _global).m_lowest.X = -1;
  }, i.ClipperOffset.Round = i.Clipper.Round, i.ClipperOffset.prototype.AddPath = function (t, e, n) {
    var r = t.length - 1;

    if (!(r < 0)) {
      var o = new i.PolyNode();
      if (o.m_jointype = e, o.m_endtype = n, n == i.EndType.etClosedLine || n == i.EndType.etClosedPolygon) for (; r > 0 && i.IntPoint.op_Equality(t[0], t[r]);) r--;
      o.m_polygon.push(t[0]);

      for (var s = 0, u = 0, a = 1; a <= r; a++) i.IntPoint.op_Inequality(o.m_polygon[s], t[a]) && (s++, o.m_polygon.push(t[a]), (t[a].Y > o.m_polygon[u].Y || t[a].Y == o.m_polygon[u].Y && t[a].X < o.m_polygon[u].X) && (u = s));

      if (!(n == i.EndType.etClosedPolygon && s < 2 || n != i.EndType.etClosedPolygon && s < 0) && ((this || _global).m_polyNodes.AddChild(o), n == i.EndType.etClosedPolygon)) if ((this || _global).m_lowest.X < 0) (this || _global).m_lowest = new i.IntPoint(0, u);else {
        var l = (this || _global).m_polyNodes.Childs()[(this || _global).m_lowest.X].m_polygon[(this || _global).m_lowest.Y];

        (o.m_polygon[u].Y > l.Y || o.m_polygon[u].Y == l.Y && o.m_polygon[u].X < l.X) && ((this || _global).m_lowest = new i.IntPoint((this || _global).m_polyNodes.ChildCount() - 1, u));
      }
    }
  }, i.ClipperOffset.prototype.AddPaths = function (t, e, n) {
    for (var r = 0, i = t.length; r < i; r++) this.AddPath(t[r], e, n);
  }, i.ClipperOffset.prototype.FixOrientations = function () {
    if ((this || _global).m_lowest.X >= 0 && !i.Clipper.Orientation((this || _global).m_polyNodes.Childs()[(this || _global).m_lowest.X].m_polygon)) for (var t = 0; t < (this || _global).m_polyNodes.ChildCount(); t++) {
      ((e = (this || _global).m_polyNodes.Childs()[t]).m_endtype == i.EndType.etClosedPolygon || e.m_endtype == i.EndType.etClosedLine && i.Clipper.Orientation(e.m_polygon)) && e.m_polygon.reverse();
    } else for (t = 0; t < (this || _global).m_polyNodes.ChildCount(); t++) {
      var e;
      (e = (this || _global).m_polyNodes.Childs()[t]).m_endtype != i.EndType.etClosedLine || i.Clipper.Orientation(e.m_polygon) || e.m_polygon.reverse();
    }
  }, i.ClipperOffset.GetUnitNormal = function (t, e) {
    var n = e.X - t.X,
        r = e.Y - t.Y;
    if (0 == n && 0 == r) return new i.DoublePoint(0, 0);
    var o = 1 / Math.sqrt(n * n + r * r);
    return n *= o, r *= o, new i.DoublePoint(r, -n);
  }, i.ClipperOffset.prototype.DoOffset = function (t) {
    if ((this || _global).m_destPolys = new Array(), (this || _global).m_delta = t, i.ClipperBase.near_zero(t)) for (var e = 0; e < (this || _global).m_polyNodes.ChildCount(); e++) {
      (o = (this || _global).m_polyNodes.Childs()[e]).m_endtype == i.EndType.etClosedPolygon && (this || _global).m_destPolys.push(o.m_polygon);
    } else {
      var n;
      (this || _global).MiterLimit > 2 ? (this || _global).m_miterLim = 2 / ((this || _global).MiterLimit * (this || _global).MiterLimit) : (this || _global).m_miterLim = 0.5, n = (this || _global).ArcTolerance <= 0 ? i.ClipperOffset.def_arc_tolerance : (this || _global).ArcTolerance > Math.abs(t) * i.ClipperOffset.def_arc_tolerance ? Math.abs(t) * i.ClipperOffset.def_arc_tolerance : (this || _global).ArcTolerance;
      var r = 3.14159265358979 / Math.acos(1 - n / Math.abs(t));
      (this || _global).m_sin = Math.sin(i.ClipperOffset.two_pi / r), (this || _global).m_cos = Math.cos(i.ClipperOffset.two_pi / r), (this || _global).m_StepsPerRad = r / i.ClipperOffset.two_pi, t < 0 && ((this || _global).m_sin = -(this || _global).m_sin);

      for (e = 0; e < (this || _global).m_polyNodes.ChildCount(); e++) {
        var o = (this || _global).m_polyNodes.Childs()[e];

        (this || _global).m_srcPoly = o.m_polygon;
        var s = (this || _global).m_srcPoly.length;
        if (!(0 == s || t <= 0 && (s < 3 || o.m_endtype != i.EndType.etClosedPolygon))) if ((this || _global).m_destPoly = new Array(), 1 != s) {
          (this || _global).m_normals.length = 0;

          for (h = 0; h < s - 1; h++) (this || _global).m_normals.push(i.ClipperOffset.GetUnitNormal((this || _global).m_srcPoly[h], (this || _global).m_srcPoly[h + 1]));

          if (o.m_endtype == i.EndType.etClosedLine || o.m_endtype == i.EndType.etClosedPolygon ? (this || _global).m_normals.push(i.ClipperOffset.GetUnitNormal((this || _global).m_srcPoly[s - 1], (this || _global).m_srcPoly[0])) : (this || _global).m_normals.push(new i.DoublePoint((this || _global).m_normals[s - 2])), o.m_endtype == i.EndType.etClosedPolygon) {
            var u = s - 1;

            for (h = 0; h < s; h++) u = this.OffsetPoint(h, u, o.m_jointype);

            (this || _global).m_destPolys.push((this || _global).m_destPoly);
          } else if (o.m_endtype == i.EndType.etClosedLine) {
            for (u = s - 1, h = 0; h < s; h++) u = this.OffsetPoint(h, u, o.m_jointype);

            (this || _global).m_destPolys.push((this || _global).m_destPoly), (this || _global).m_destPoly = new Array();
            var a = (this || _global).m_normals[s - 1];

            for (h = s - 1; h > 0; h--) (this || _global).m_normals[h] = new i.DoublePoint(-(this || _global).m_normals[h - 1].X, -(this || _global).m_normals[h - 1].Y);

            (this || _global).m_normals[0] = new i.DoublePoint(-a.X, -a.Y), u = 0;

            for (h = s - 1; h >= 0; h--) u = this.OffsetPoint(h, u, o.m_jointype);

            (this || _global).m_destPolys.push((this || _global).m_destPoly);
          } else {
            var l;

            for (u = 0, h = 1; h < s - 1; ++h) u = this.OffsetPoint(h, u, o.m_jointype);

            if (o.m_endtype == i.EndType.etOpenButt) {
              h = s - 1;
              l = new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[h].X + (this || _global).m_normals[h].X * t), i.ClipperOffset.Round((this || _global).m_srcPoly[h].Y + (this || _global).m_normals[h].Y * t)), (this || _global).m_destPoly.push(l), l = new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[h].X - (this || _global).m_normals[h].X * t), i.ClipperOffset.Round((this || _global).m_srcPoly[h].Y - (this || _global).m_normals[h].Y * t)), (this || _global).m_destPoly.push(l);
            } else {
              h = s - 1;
              u = s - 2, (this || _global).m_sinA = 0, (this || _global).m_normals[h] = new i.DoublePoint(-(this || _global).m_normals[h].X, -(this || _global).m_normals[h].Y), o.m_endtype == i.EndType.etOpenSquare ? this.DoSquare(h, u) : this.DoRound(h, u);
            }

            for (h = s - 1; h > 0; h--) (this || _global).m_normals[h] = new i.DoublePoint(-(this || _global).m_normals[h - 1].X, -(this || _global).m_normals[h - 1].Y);

            (this || _global).m_normals[0] = new i.DoublePoint(-(this || _global).m_normals[1].X, -(this || _global).m_normals[1].Y);

            for (h = (u = s - 1) - 1; h > 0; --h) u = this.OffsetPoint(h, u, o.m_jointype);

            o.m_endtype == i.EndType.etOpenButt ? (l = new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[0].X - (this || _global).m_normals[0].X * t), i.ClipperOffset.Round((this || _global).m_srcPoly[0].Y - (this || _global).m_normals[0].Y * t)), (this || _global).m_destPoly.push(l), l = new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[0].X + (this || _global).m_normals[0].X * t), i.ClipperOffset.Round((this || _global).m_srcPoly[0].Y + (this || _global).m_normals[0].Y * t)), (this || _global).m_destPoly.push(l)) : (u = 1, (this || _global).m_sinA = 0, o.m_endtype == i.EndType.etOpenSquare ? this.DoSquare(0, 1) : this.DoRound(0, 1)), (this || _global).m_destPolys.push((this || _global).m_destPoly);
          }
        } else {
          if (o.m_jointype == i.JoinType.jtRound) for (var f = 1, c = 0, h = 1; h <= r; h++) {
            (this || _global).m_destPoly.push(new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[0].X + f * t), i.ClipperOffset.Round((this || _global).m_srcPoly[0].Y + c * t)));

            var p = f;
            f = f * (this || _global).m_cos - (this || _global).m_sin * c, c = p * (this || _global).m_sin + c * (this || _global).m_cos;
          } else {
            f = -1, c = -1;

            for (var h = 0; h < 4; ++h) (this || _global).m_destPoly.push(new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[0].X + f * t), i.ClipperOffset.Round((this || _global).m_srcPoly[0].Y + c * t))), f < 0 ? f = 1 : c < 0 ? c = 1 : f = -1;
          }

          (this || _global).m_destPolys.push((this || _global).m_destPoly);
        }
      }
    }
  }, i.ClipperOffset.prototype.Execute = function () {
    var t = arguments;

    if (t[0] instanceof i.PolyTree) {
      o = t[0], s = t[1];
      if (o.Clear(), this.FixOrientations(), this.DoOffset(s), (r = new i.Clipper(0)).AddPaths((this || _global).m_destPolys, i.PolyType.ptSubject, !0), s > 0) r.Execute(i.ClipType.ctUnion, o, i.PolyFillType.pftPositive, i.PolyFillType.pftPositive);else {
        a = i.Clipper.GetBounds((this || _global).m_destPolys);

        if ((u = new i.Path()).push(new i.IntPoint(a.left - 10, a.bottom + 10)), u.push(new i.IntPoint(a.right + 10, a.bottom + 10)), u.push(new i.IntPoint(a.right + 10, a.top - 10)), u.push(new i.IntPoint(a.left - 10, a.top - 10)), r.AddPath(u, i.PolyType.ptSubject, !0), r.ReverseSolution = !0, r.Execute(i.ClipType.ctUnion, o, i.PolyFillType.pftNegative, i.PolyFillType.pftNegative), 1 == o.ChildCount() && o.Childs()[0].ChildCount() > 0) {
          var e = o.Childs()[0];
          o.Childs()[0] = e.Childs()[0];

          for (var n = 1; n < e.ChildCount(); n++) o.AddChild(e.Childs()[n]);
        } else o.Clear();
      }
    } else {
      var r,
          o = t[0],
          s = t[1];
      if (i.Clear(o), this.FixOrientations(), this.DoOffset(s), (r = new i.Clipper(0)).AddPaths((this || _global).m_destPolys, i.PolyType.ptSubject, !0), s > 0) r.Execute(i.ClipType.ctUnion, o, i.PolyFillType.pftPositive, i.PolyFillType.pftPositive);else {
        var u,
            a = i.Clipper.GetBounds((this || _global).m_destPolys);
        (u = new i.Path()).push(new i.IntPoint(a.left - 10, a.bottom + 10)), u.push(new i.IntPoint(a.right + 10, a.bottom + 10)), u.push(new i.IntPoint(a.right + 10, a.top - 10)), u.push(new i.IntPoint(a.left - 10, a.top - 10)), r.AddPath(u, i.PolyType.ptSubject, !0), r.ReverseSolution = !0, r.Execute(i.ClipType.ctUnion, o, i.PolyFillType.pftNegative, i.PolyFillType.pftNegative), o.length > 0 && o.splice(0, 1);
      }
    }
  }, i.ClipperOffset.prototype.OffsetPoint = function (t, e, n) {
    if ((this || _global).m_sinA = (this || _global).m_normals[e].X * (this || _global).m_normals[t].Y - (this || _global).m_normals[t].X * (this || _global).m_normals[e].Y, (this || _global).m_sinA < 0.00005 && (this || _global).m_sinA > -0.00005) return e;
    if ((this || _global).m_sinA > 1 ? (this || _global).m_sinA = 1 : (this || _global).m_sinA < -1 && ((this || _global).m_sinA = -1), (this || _global).m_sinA * (this || _global).m_delta < 0) (this || _global).m_destPoly.push(new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[t].X + (this || _global).m_normals[e].X * (this || _global).m_delta), i.ClipperOffset.Round((this || _global).m_srcPoly[t].Y + (this || _global).m_normals[e].Y * (this || _global).m_delta))), (this || _global).m_destPoly.push(new i.IntPoint((this || _global).m_srcPoly[t])), (this || _global).m_destPoly.push(new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[t].X + (this || _global).m_normals[t].X * (this || _global).m_delta), i.ClipperOffset.Round((this || _global).m_srcPoly[t].Y + (this || _global).m_normals[t].Y * (this || _global).m_delta)));else switch (n) {
      case i.JoinType.jtMiter:
        var r = (this || _global).m_normals[t].X * (this || _global).m_normals[e].X + (this || _global).m_normals[t].Y * (this || _global).m_normals[e].Y + 1;
        r >= (this || _global).m_miterLim ? this.DoMiter(t, e, r) : this.DoSquare(t, e);
        break;

      case i.JoinType.jtSquare:
        this.DoSquare(t, e);
        break;

      case i.JoinType.jtRound:
        this.DoRound(t, e);
    }
    return e = t;
  }, i.ClipperOffset.prototype.DoSquare = function (t, e) {
    var n = Math.tan(Math.atan2((this || _global).m_sinA, (this || _global).m_normals[e].X * (this || _global).m_normals[t].X + (this || _global).m_normals[e].Y * (this || _global).m_normals[t].Y) / 4);
    (this || _global).m_destPoly.push(new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[t].X + (this || _global).m_delta * ((this || _global).m_normals[e].X - (this || _global).m_normals[e].Y * n)), i.ClipperOffset.Round((this || _global).m_srcPoly[t].Y + (this || _global).m_delta * ((this || _global).m_normals[e].Y + (this || _global).m_normals[e].X * n)))), (this || _global).m_destPoly.push(new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[t].X + (this || _global).m_delta * ((this || _global).m_normals[t].X + (this || _global).m_normals[t].Y * n)), i.ClipperOffset.Round((this || _global).m_srcPoly[t].Y + (this || _global).m_delta * ((this || _global).m_normals[t].Y - (this || _global).m_normals[t].X * n))));
  }, i.ClipperOffset.prototype.DoMiter = function (t, e, n) {
    var r = (this || _global).m_delta / n;

    (this || _global).m_destPoly.push(new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[t].X + ((this || _global).m_normals[e].X + (this || _global).m_normals[t].X) * r), i.ClipperOffset.Round((this || _global).m_srcPoly[t].Y + ((this || _global).m_normals[e].Y + (this || _global).m_normals[t].Y) * r)));
  }, i.ClipperOffset.prototype.DoRound = function (t, e) {
    for (var n, r = Math.atan2((this || _global).m_sinA, (this || _global).m_normals[e].X * (this || _global).m_normals[t].X + (this || _global).m_normals[e].Y * (this || _global).m_normals[t].Y), o = i.Cast_Int32(i.ClipperOffset.Round((this || _global).m_StepsPerRad * Math.abs(r))), s = (this || _global).m_normals[e].X, u = (this || _global).m_normals[e].Y, a = 0; a < o; ++a) (this || _global).m_destPoly.push(new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[t].X + s * (this || _global).m_delta), i.ClipperOffset.Round((this || _global).m_srcPoly[t].Y + u * (this || _global).m_delta))), n = s, s = s * (this || _global).m_cos - (this || _global).m_sin * u, u = n * (this || _global).m_sin + u * (this || _global).m_cos;

    (this || _global).m_destPoly.push(new i.IntPoint(i.ClipperOffset.Round((this || _global).m_srcPoly[t].X + (this || _global).m_normals[t].X * (this || _global).m_delta), i.ClipperOffset.Round((this || _global).m_srcPoly[t].Y + (this || _global).m_normals[t].Y * (this || _global).m_delta)));
  }, i.Error = function (t) {
    try {
      throw new Error(t);
    } catch (t) {
      alert(t.message);
    }
  }, i.JS = {}, i.JS.AreaOfPolygon = function (t, e) {
    return e || (e = 1), i.Clipper.Area(t) / (e * e);
  }, i.JS.AreaOfPolygons = function (t, e) {
    e || (e = 1);

    for (var n = 0, r = 0; r < t.length; r++) n += i.Clipper.Area(t[r]);

    return n / (e * e);
  }, i.JS.BoundsOfPath = function (t, e) {
    return i.JS.BoundsOfPaths([t], e);
  }, i.JS.BoundsOfPaths = function (t, e) {
    e || (e = 1);
    var n = i.Clipper.GetBounds(t);
    return n.left /= e, n.bottom /= e, n.right /= e, n.top /= e, n;
  }, i.JS.Clean = function (t, e) {
    if (!(t instanceof Array)) return [];
    var n = t[0] instanceof Array;
    t = i.JS.Clone(t);
    if ("number" != typeof e || null === e) return i.Error("Delta is not a number in Clean()."), t;
    if (0 === t.length || 1 == t.length && 0 === t[0].length || e < 0) return t;
    n || (t = [t]);

    for (var r, o, s, u, a, l, f, c = t.length, h = [], p = 0; p < c; p++) if (0 !== (r = (o = t[p]).length)) if (r < 3) s = o, h.push(s);else {
      for (s = o, u = e * e, a = o[0], l = 1, f = 1; f < r; f++) (o[f].X - a.X) * (o[f].X - a.X) + (o[f].Y - a.Y) * (o[f].Y - a.Y) <= u || (s[l] = o[f], a = o[f], l++);

      a = o[l - 1], (o[0].X - a.X) * (o[0].X - a.X) + (o[0].Y - a.Y) * (o[0].Y - a.Y) <= u && l--, l < r && s.splice(l, r - l), s.length && h.push(s);
    }

    return !n && h.length ? h = h[0] : n || 0 !== h.length ? n && 0 === h.length && (h = [[]]) : h = [], h;
  }, i.JS.Clone = function (t) {
    if (!(t instanceof Array)) return [];
    if (0 === t.length) return [];
    if (1 == t.length && 0 === t[0].length) return [[]];
    var e = t[0] instanceof Array;
    e || (t = [t]);
    var n,
        r,
        i,
        o,
        s = t.length,
        u = new Array(s);

    for (r = 0; r < s; r++) {
      for (n = t[r].length, o = new Array(n), i = 0; i < n; i++) o[i] = {
        X: t[r][i].X,
        Y: t[r][i].Y
      };

      u[r] = o;
    }

    return e || (u = u[0]), u;
  }, i.JS.Lighten = function (t, e) {
    if (!(t instanceof Array)) return [];
    if ("number" != typeof e || null === e) return i.Error("Tolerance is not a number in Lighten()."), i.JS.Clone(t);
    if (0 === t.length || 1 == t.length && 0 === t[0].length || e < 0) return i.JS.Clone(t);
    var n, r, o, s, u, a, l, f, c, h, p, d, v, y, m, P;
    t[0] instanceof Array || (t = [t]);

    var g = t.length,
        _ = e * e,
        x = [];

    for (n = 0; n < g; n++) if (0 != (a = (o = t[n]).length)) {
      for (s = 0; s < 1000000; s++) {
        for (u = [], o[(a = o.length) - 1].X != o[0].X || o[a - 1].Y != o[0].Y ? (p = 1, o.push({
          X: o[0].X,
          Y: o[0].Y
        }), a = o.length) : p = 0, h = [], r = 0; r < a - 2; r++) l = o[r], c = o[r + 1], f = o[r + 2], m = l.X, P = l.Y, d = f.X - m, v = f.Y - P, 0 === d && 0 === v || ((y = ((c.X - m) * d + (c.Y - P) * v) / (d * d + v * v)) > 1 ? (m = f.X, P = f.Y) : y > 0 && (m += d * y, P += v * y)), (d = c.X - m) * d + (v = c.Y - P) * v <= _ && (h[r + 1] = 1, r++);

        for (u.push({
          X: o[0].X,
          Y: o[0].Y
        }), r = 1; r < a - 1; r++) h[r] || u.push({
          X: o[r].X,
          Y: o[r].Y
        });

        if (u.push({
          X: o[a - 1].X,
          Y: o[a - 1].Y
        }), p && o.pop(), !h.length) break;
        o = u;
      }

      u[(a = u.length) - 1].X == u[0].X && u[a - 1].Y == u[0].Y && u.pop(), u.length > 2 && x.push(u);
    }

    return !t[0] instanceof Array && (x = x[0]), void 0 === x && (x = [[]]), x;
  }, i.JS.PerimeterOfPath = function (t, e, n) {
    if (void 0 === t) return 0;
    var r,
        i,
        o = Math.sqrt,
        s = 0,
        u = 0,
        a = 0,
        l = 0,
        f = 0,
        c = t.length;
    if (c < 2) return 0;

    for (e && (t[c] = t[0], c++); --c;) u = (r = t[c]).X, a = r.Y, s += o((u - (l = (i = t[c - 1]).X)) * (u - l) + (a - (f = i.Y)) * (a - f));

    return e && t.pop(), s / n;
  }, i.JS.PerimeterOfPaths = function (t, e, n) {
    n || (n = 1);

    for (var r = 0, o = 0; o < t.length; o++) r += i.JS.PerimeterOfPath(t[o], e, n);

    return r;
  }, i.JS.ScaleDownPath = function (t, e) {
    var n, r;

    for (e || (e = 1), n = t.length; n--;) (r = t[n]).X = r.X / e, r.Y = r.Y / e;
  }, i.JS.ScaleDownPaths = function (t, e) {
    var n, r, i;
    Math.round;

    for (e || (e = 1), n = t.length; n--;) for (r = t[n].length; r--;) (i = t[n][r]).X = i.X / e, i.Y = i.Y / e;
  }, i.JS.ScaleUpPath = function (t, e) {
    var n,
        r,
        i = Math.round;

    for (e || (e = 1), n = t.length; n--;) (r = t[n]).X = i(r.X * e), r.Y = i(r.Y * e);
  }, i.JS.ScaleUpPaths = function (t, e) {
    var n,
        r,
        i,
        o = Math.round;

    for (e || (e = 1), n = t.length; n--;) for (r = t[n].length; r--;) (i = t[n][r]).X = o(i.X * e), i.Y = o(i.Y * e);
  }, i.ExPolygons = function () {
    return [];
  }, i.ExPolygon = function () {
    (this || _global).outer = null, (this || _global).holes = null;
  }, i.JS.AddOuterPolyNodeToExPolygons = function (t, e) {
    var n = new i.ExPolygon();
    n.outer = t.Contour();
    var r,
        o,
        s,
        u,
        a,
        l,
        f = t.Childs(),
        c = f.length;

    for (n.holes = new Array(c), s = 0; s < c; s++) for (r = f[s], n.holes[s] = r.Contour(), u = 0, l = (a = r.Childs()).length; u < l; u++) o = a[u], i.JS.AddOuterPolyNodeToExPolygons(o, e);

    e.push(n);
  }, i.JS.ExPolygonsToPaths = function (t) {
    var e,
        n,
        r,
        o,
        s = new i.Paths();

    for (e = 0, r = t.length; e < r; e++) for (s.push(t[e].outer), n = 0, o = t[e].holes.length; n < o; n++) s.push(t[e].holes[n]);

    return s;
  }, i.JS.PolyTreeToExPolygons = function (t) {
    var e,
        n,
        r,
        o,
        s = new i.ExPolygons();

    for (n = 0, o = (r = t.Childs()).length; n < o; n++) e = r[n], i.JS.AddOuterPolyNodeToExPolygons(e, s);

    return s;
  }, t.exports = i;
}, function (t, e, n) {
  "use strict";

  Object.defineProperty(e, "__esModule", {
    value: !0
  }), n.d(e, "openDB", function () {
    return i;
  }), n.d(e, "deleteDB", function () {
    return o;
  });
  var r = n(125);

  function i(t, e, {
    blocked: n,
    upgrade: i,
    blocking: o
  } = {}) {
    const s = indexedDB.open(t, e),
          u = Object(r.a)(s);
    return i && s.addEventListener("upgradeneeded", t => {
      i(Object(r.a)(s.result), t.oldVersion, t.newVersion, Object(r.a)(s.transaction));
    }), n && s.addEventListener("blocked", () => n()), o && u.then(t => t.addEventListener("versionchange", o)).catch(() => {}), u;
  }

  function o(t, {
    blocked: e
  } = {}) {
    const n = indexedDB.deleteDatabase(t);
    return e && n.addEventListener("blocked", () => e()), Object(r.a)(n).then(() => void 0);
  }

  n.d(e, "unwrap", function () {
    return r.c;
  }), n.d(e, "wrap", function () {
    return r.a;
  });
  const s = ["get", "getKey", "getAll", "getAllKeys", "count"],
        u = ["put", "add", "delete", "clear"],
        a = new Map();

  function l(t, e) {
    if (!(t instanceof IDBDatabase) || e in t || "string" != typeof e) return;
    if (a.get(e)) return a.get(e);
    const n = e.replace(/FromIndex$/, ""),
          r = e !== n,
          i = u.includes(n);
    if (!(n in (r ? IDBIndex : IDBObjectStore).prototype) || !i && !s.includes(n)) return;

    const o = async function (t, ...e) {
      const o = this.transaction(t, i ? "readwrite" : "readonly");
      let s = o.store;
      r && (s = s.index(e.shift()));
      const u = s[n](...e);
      return i && (await o.done), u;
    };

    return a.set(e, o), o;
  }

  Object(r.b)(t => ({
    get: (e, n, r) => l(e, n) || t.get(e, n, r),
    has: (e, n) => !!l(e, n) || t.has(e, n)
  }));
}, function (t, e) {}, function (t, e, n) {
  "use strict";

  Object.defineProperty(e, "__esModule", {
    value: !0
  });
  var r,
      i = n(124),
      o = (r = i) && r.__esModule ? r : {
    default: r
  };
  var s = o.default.Control.extend({
    options: {},
    _layers: {},
    initialize: function (t) {
      o.default.Util.setOptions(this || _global, t), (this || _global)._layers = {};
    },
    _addLayer: function (t) {
      var e = 15;
      t.options.minZoom && (e = t.options.minZoom), (this || _global)._layers[t._leaflet_id] = e, this._updateBox(null);
    },
    _removeLayer: function (t) {
      (this || _global)._layers[t._leaflet_id] = null, this._updateBox(null);
    },
    _getMinZoomLevel: function () {
      var t = -1;

      for (var e in (this || _global)._layers) null !== (this || _global)._layers[e] && (this || _global)._layers[e] > t && (t = (this || _global)._layers[e]);

      return t;
    },
    _updateBox: function (t) {
      var e = this._getMinZoomLevel();

      null !== t && o.default.DomEvent.preventDefault(t), (this || _global)._container.innerHTML = -1 == e ? (this || _global).options.minZoomMessageNoLayer : (this || _global).options.minZoomMessage.replace(/CURRENTZOOM/, (this || _global)._map.getZoom()).replace(/MINZOOMLEVEL/, e), (this || _global)._map.getZoom() >= e ? (this || _global)._container.style.display = "none" : (this || _global)._container.style.display = "block";
    },
    onAdd: function (t) {
      return (this || _global)._map = t, (this || _global)._map.zoomIndicator = this || _global, (this || _global)._container = o.default.DomUtil.create("div", "leaflet-control-minZoomIndicator"), (this || _global)._map.on("moveend", (this || _global)._updateBox, this || _global), this._updateBox(null), (this || _global)._container;
    },
    onRemove: function (t) {
      t.off({
        moveend: (this || _global)._updateBox
      }, this || _global);
    }
  });
  o.default.Control.MinZoomIndicator = s, e.default = s;
}]);
export default {};